import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext, { UpdateToken } from '../../context/AppContext';
import useCookies from '../../hooks/UseCookies';
import usePersistentState from '../../hooks/UsePersistentState';
import { Exception } from '../../models/ExceptionTypes';
import { Tip } from '../../models/Tip';
import ApiClient from '../../services/ApiClient';
import EmailPassword from '../EmailPassword/EmailPassword';
import './AdminConsole.css';

const AdminConsole: FC = () => {
    const [appContext, dispatch] = useContext(AppContext);
    const [admin, setAdmin] = usePersistentState<{ id: string, emailAddress: string } | null>('TipSplitAdmin', null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const navigate = useNavigate();

    const onSubmit = async (email: string, password: string): Promise<void> => {
        setLoading(true);
        const res: Response = await ApiClient.PostAsync('/api/admin/login', { emailAddress: email, password }, appContext.auth.token);

        if (res.ok) {
            const { admin, token, expiration } = await res.json();
            setAdmin(admin);
            dispatch(UpdateToken(token, expiration));
        } else {
            setError(true)
        }

        setLoading(false);
    }

    return (
        <>
            {
                !loading ? (
                    <>
                        {
                            !admin ? (
                                <EmailPassword buttonText='Login' onSubmit={onSubmit} />
                            ) : (
                                <AdminDashboard admin={admin} />
                            )
                        }
                    </>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )
            }

            {
                error && (
                    <Dialog
                        open={error}
                        onClose={() => setError(false)}
                        aria-labelledby="subscription-alert-title"
                        aria-describedby="subscription-alert-description"
                    >
                        <DialogTitle id="subscription-alert-title">
                            Login failed
                        </DialogTitle>
                        <DialogContent style={{ paddingBottom: '10px' }}>
                            <DialogContentText id="subscription-alert-description" variant='body2'>
                                Incorrect email or password. Please try again or go to login.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
                            <Button variant='contained' onClick={() => navigate('/app')}>Go to login</Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        </>
    );
}

const AdminDashboard: FC<{ admin: { id: string, emailAddress: string } }> = ({ admin }) => {
    const { cookies } = useCookies();
    const [selectedTimeFrame, setSelectedTimeFrame] = useState<number>(1);
    const [timeFrame, setTimeFrame] = useState<[Date, Date] | null>(null);

    const getTipInfo: QueryFunction<{ tips: Tip[], tipsTotal: string, revenue: string }> = async () => {
        const res = await ApiClient.GetAsync(`/api/Tips/Query?startDate=${timeFrame![0].toJSON()}`, cookies.token);

        if (!res.ok) {
            throw new Exception('Error GETing tips and revenue.');
        }

        return res.json();
    }

    const { data, isLoading, isError } = useQuery<{ tips: Tip[], tipsTotal: string, revenue: string }>({
        queryKey: ['tip-info'],
        queryFn: getTipInfo
    })

    useEffect(() => {
        let timeFrameStart: Date = new Date();
        let today: Date = new Date();
        timeFrameStart.setDate(timeFrameStart.getDate() - selectedTimeFrame);

        setTimeFrame([timeFrameStart, today]);
    }, [selectedTimeFrame]);

    const timeFrameTabProps = (numberOfDays: number) => {
        return {
            id: `time-frame-tab-${numberOfDays}`,
            'aria-controls': `time-frame-tabpanel-${numberOfDays}`
        };
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTimeFrame(newValue);
    }

    if (isLoading) return <></>;
    if (isError) return <></>;

    return (
        <div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={selectedTimeFrame}
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons='auto'
                    aria-label="Time frame tabs"
                >
                    <Tab label="1D" value={1} {...timeFrameTabProps(1)} />
                    <Tab label="5D" value={5} {...timeFrameTabProps(5)} />
                    <Tab label="1M" value={31} {...timeFrameTabProps(31)} />
                    <Tab label="3M" value={92} {...timeFrameTabProps(92)} />
                    <Tab label="1Y" value={365} {...timeFrameTabProps(365)} />
                </Tabs>
            </Box>
            <div className='tip-info'>
                <div><b>Number of tips received:</b> {data?.tips.length}</div>
                <div><b>Tips total:</b> {data?.tipsTotal}</div>
                <div><b>TipSplit revenue:</b> {data?.revenue}</div>
            </div>
        </div>
    )
}

export default AdminConsole;