import Typography from "@mui/material/Typography";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import { FC, useContext, useEffect } from "react";
import AppContext from "../../context/AppContext";
import usePersistentState from "../../hooks/UsePersistentState";
import { BusinessLocation } from "../../models/Business";
import { Employee } from "../../models/Employee";
import { Exception } from "../../models/ExceptionTypes";
import { TSEvent } from "../../models/TSEvent";
import { Tip } from "../../models/Tip";
import ApiClient from "../../services/ApiClient";
import ScheduleService from "../../services/ScheduleService";
import SignalRService from "../../services/SignalRService";
import EmployeeEvent from "./EmployeeEvent";

export interface MonthSchedule {
    monthYear: {
        month: number;
        year: number;
    };
    events: {
        eventDetails: TSEvent,
        location: BusinessLocation,
        tips: Tip[],
        scheduledEmployeesCount: number,
        activeEvent: boolean
    }[];
}

// TO-DO: Make sticky header list
const EmployeeSchedule: FC = () => {
    const [appContext] = useContext(AppContext);
    const [scheduledEvents, setScheduledEvents] = usePersistentState<MonthSchedule[]>("employeeSchedule", []);

    useEffect(() => {
        const signalRService = new SignalRService('/hubs/EventHub', appContext.auth.token);

        signalRService.start();

        signalRService.subscribeToEvent('scheduleUpdate', (employeeSchedule: MonthSchedule[]) => {
            setScheduledEvents(employeeSchedule);
        });

        return () => {
            signalRService.stop();
        }
    }, []);

    const getScheduledEvents: QueryFunction<MonthSchedule[] | null> = async () => {
        const res = await ApiClient.GetAsync('/api/Employees/Schedule', appContext.auth.token);

        if (!res.ok) {
            throw new Exception('Error GETing scheduled events.');
        }

        return res.json();
    }

    const { data, isLoading, isError } = useQuery({
        queryKey: ['scheduledEvents'],
        queryFn: getScheduledEvents
    });

    useEffect(() => {
        if (data) {
            setScheduledEvents(data);
        }
    }, [data]);

    if (isLoading) return <></>;
    if (isError) return <></>;

    return (
        <div className="employee-schedule" style={{ marginBottom: "75px" }}>
            {
                scheduledEvents && scheduledEvents?.length <= 0 ? (
                    <div style={{ width: '95vw', margin: 'auto' }}>
                        <Typography variant="h6">
                            {(appContext.user as Employee).firstName} {(appContext.user as Employee).lastName}, you currently do not have any scheduled events. Please check back later.
                        </Typography>
                    </div>
                ) :
                    scheduledEvents?.map(month => (
                        <div style={{ marginBottom: "25px" }} key={`month-${month.monthYear.month}${month.monthYear.year}`}>
                            <Typography
                                variant="h6"
                                component="div"
                                color="text.secondary"
                                textAlign="left"
                                width="95vw"
                                margin="auto"
                                marginBottom="10px"
                            >
                                {ScheduleService.MONTHS[month?.monthYear?.month - 1]} {month?.monthYear?.year}
                            </Typography>
                            {month?.events?.map(event =>
                                <EmployeeEvent
                                    event={event.eventDetails}
                                    location={event.location}
                                    tips={event.tips}
                                    activeEvent={event.activeEvent}
                                    key={`employee-event-${event.eventDetails.id}`}
                                />
                            )}
                        </div>
                    ))
            }
        </div>
    )
}

export default EmployeeSchedule;