import { Employee } from "../../models/Employee";
import { InputError } from "../../models/InputError";
import { TSEvent } from "../../models/TSEvent";

export interface ScheduleDay {
    id: number;
    dayOfMonth: number;
    day: string;
    selected: boolean;
    date: Date;
    events: TSEvent[];
}

export interface ScheduleState {
    businessEvents: TSEvent[];
    calendarDays: ScheduleDay[];
    selectedDay: Date;
    selectedMonth: Date;
    newEventOpen: boolean;
    newEventTitle: string;
    newEventAddress: string;
    newEventNotes: string;
    newEventEmployees: string[];
    newEventStartDate: Date | null;
    newEventEndDate: Date | null;
    newEventLocation: string | null;
    newEventFormState: { [key: string]: InputError }
    newEventEstimatedTotalGuests: string;
    editingEvent: boolean;
    selectedEventEdit: TSEvent;
    editEventId?: string;
    touchStart: number,
    touchEnd: number,
}

export interface ScheduleReducerPayload {
    type: string;
    [key: string]: string | number | Date | boolean | TSEvent | string[] | ScheduleDay[] | TSEvent[] | { [key: string]: InputError } | null;
}

export const ScheduleReducer = (state: ScheduleState, action: ScheduleReducerPayload): ScheduleState => {
    let eventStart: Date;
    let eventEnd: Date;

    switch (action.type) {
        case "CALENDAR_DAYS":
            return {
                ...state,
                calendarDays: [...(action.calendarDays as ScheduleDay[])]
            }

        case "SELECTED_DAY":
            eventStart = new Date(+(action.selectedDay as Date));
            eventEnd = new Date(+(action.selectedDay as Date));
            eventStart.setHours(state.newEventStartDate!.getHours(), state.newEventStartDate!.getMinutes());
            eventEnd.setHours(state.newEventEndDate!.getHours(), state.newEventEndDate!.getMinutes());

            return {
                ...state,
                selectedDay: action.selectedDay as Date,
                newEventStartDate: eventStart,
                newEventEndDate: eventEnd
            }

        case "SELECTED_MONTH":
            return {
                ...state,
                selectedMonth: action.selectedMonth as Date
            }

        case "GO_TO_TODAY":
            eventStart = new Date(+(action.selectedDay as Date));
            eventEnd = new Date(+(action.selectedDay as Date));
            eventStart.setHours(state.newEventStartDate!.getHours(), state.newEventStartDate!.getMinutes());
            eventEnd.setHours(state.newEventEndDate!.getHours(), state.newEventEndDate!.getMinutes());

            return {
                ...state,
                selectedMonth: action.selectedMonth as Date,
                selectedDay: action.selectedDay as Date,
                newEventStartDate: eventStart,
                newEventEndDate: eventEnd
            }

        case "NEW_EVENT_OPEN":
            return {
                ...state,
                newEventOpen: true
            }

        case "NEW_EVENT_CLOSE":
            eventStart = new Date(+(state.selectedDay as Date));
            eventEnd = new Date(+(state.selectedDay as Date));
            eventStart.setHours(state.newEventStartDate!.getHours(), state.newEventStartDate!.getMinutes());
            eventEnd.setHours(state.newEventEndDate!.getHours(), state.newEventEndDate!.getMinutes());

            return {
                ...state,
                newEventOpen: false,
                editingEvent: false,
                newEventTitle: "",
                newEventAddress: "",
                newEventNotes: "",
                newEventEmployees: [],
                newEventEstimatedTotalGuests: "",
                newEventStartDate: eventStart,
                newEventEndDate: eventEnd
            }

        case "NEW_EVENT_CREATE":
            state.businessEvents.push(action.newEvent as TSEvent)

            return {
                ...state,
                newEventOpen: false,
                selectedDay: new Date(+(state.newEventStartDate as Date)),
                selectedMonth: new Date(+(state.newEventStartDate as Date)),
                newEventTitle: "",
                newEventAddress: "",
                newEventNotes: "",
                newEventEmployees: [] as string[],
                newEventLocation: "",
                newEventEstimatedTotalGuests: "",
                businessEvents: [...state.businessEvents]
            }

        case "UPDATE_EVENT":
            let eventIndex = state.businessEvents.findIndex(e => e.id === (action.updatedEvent as TSEvent).id);
            (state.businessEvents as TSEvent[])[eventIndex] = action.updatedEvent as TSEvent;
            state.newEventOpen = false;
            state.editingEvent = false;

            return {
                ...state,
                selectedDay: new Date(+(state.newEventStartDate as Date)),
                selectedMonth: new Date(+(state.newEventStartDate as Date)),
                newEventTitle: "",
                newEventAddress: "",
                newEventNotes: "",
                newEventEmployees: [] as string[],
                newEventLocation: "",
                newEventEstimatedTotalGuests: "",
                businessEvents: [...state.businessEvents]
            }

        case "DELETE_EVENT":
            state.businessEvents.splice(state.businessEvents.findIndex(e => e.id === action.eventId), 1);

            return {
                ...state,
                newEventOpen: false,
                editingEvent: false,
                newEventTitle: "",
                newEventAddress: "",
                newEventNotes: "",
                newEventEmployees: [] as string[],
                newEventLocation: "",
                newEventEstimatedTotalGuests: "",
                businessEvents: [...state.businessEvents]
            };

        case "NEW_EVENT_EMPLOYEES":
            return {
                ...state,
                newEventEmployees: [...action.newEventEmployees as string[]]
            }

        case "NEW_EVENT_START_DATE":
            let endDate = new Date(+(action.newEventStartDate as Date));
            endDate.setHours(state.newEventEndDate!.getHours(), state.newEventEndDate!.getMinutes());

            return {
                ...state,
                newEventStartDate: action.newEventStartDate as Date,
                newEventEndDate: endDate
            }

        case "NEW_EVENT_END_DATE":
            return {
                ...state,
                newEventEndDate: action.newEventEndDate as Date
            }

        case "NEW_EVENT_LOCATION":
            return {
                ...state,
                newEventLocation: action.newEventLocation as string
            }

        case "FORM_STATE":
            return {
                ...state,
                newEventFormState: action.newEventFormState as { [key: string]: InputError }
            }

        case "BUSINESS_EVENTS":
            return {
                ...state,
                businessEvents: [...action.businessEvents as TSEvent[]]
            }

        case "EDIT_EVENT":
            let editingEvent = action.editingEvent as TSEvent;

            return {
                ...state,
                newEventOpen: true,
                editingEvent: true,
                editEventId: editingEvent.id,
                newEventTitle: editingEvent.eventTitle,
                newEventAddress: editingEvent.eventAddress,
                newEventEstimatedTotalGuests: editingEvent.estimatedTotalGuests?.toString() ?? '',
                newEventStartDate: new Date(editingEvent.startDate),
                newEventEndDate: new Date(editingEvent.endDate),
                newEventEmployees: (editingEvent.scheduledEmployees as Employee[]).map(e => `${e.firstName} ${e.lastName}`),
                newEventLocation: editingEvent.locationId,
                newEventNotes: editingEvent.eventNotes
            }

        case "EVENT_TITLE":
            return {
                ...state,
                newEventTitle: action.eventTitle as string
            }

        case "EVENT_ADDRESS":
            return {
                ...state,
                newEventAddress: action.eventAddress as string
            }

        case "EVENT_NOTES":
            return {
                ...state,
                newEventNotes: action.eventNotes as string
            }

        case "ESTIMATED_TOTAL_GUESTS":
            return {
                ...state,
                newEventEstimatedTotalGuests: action.estimatedTotalGuests as string
            }

        case "TOUCH_START":
            return {
                ...state,
                touchStart: action.touchStart as number
            }

        case "TOUCH_MOVE":
        case "TOUCH_END":
            return {
                ...state,
                touchEnd: action.touchEnd as number
            }

        default:
            return { ...state };
    }
}