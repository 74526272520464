import { Alert, Box, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../hooks/UseQuery';

const ChangePassword: FC = () => {
    const query = useQuery();
    const [error, setError] = useState<string>('');
    const [updating, setUpdating] = useState<boolean>(false);
    const navigate = useNavigate();

    const onSubmit = async () => {
        setUpdating(true);

        const formData = new FormData(document.querySelector('#change-password-form') as HTMLFormElement);
        const password = formData.get('newPassword')?.toString();
        const passwordConfirmation = formData.get('confirmPassword')?.toString();

        if (password === passwordConfirmation) {
            const res = await fetch('/api/auth/changePassword', {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${query.get('token')}`
                },
                body: formData
            });

            if (res.ok) {
                setUpdating(false);
                navigate('/app', { state: { passwordChanged: true } });
            } else {
                console.error('There was an error resetting your password', await res.json());
                setError('There was an error resetting your password');
                setUpdating(false);
            }
        } else {
            setError('Please enter matching passwords in both fields');
            setUpdating(false);
        }
    }

    return (
        <>
            {
                !updating ? (
                    <>
                        <Typography variant='h6' style={{ color: '#064887', marginBottom: '15px' }}>
                            Change Password
                        </Typography>
                        {!!error && <Alert severity='error' style={{ marginBottom: '15px' }}>{error}</Alert>}
                        <form id='change-password-form' onSubmit={onSubmit}>
                            <Stack
                                sx={{
                                    width: "95vw",
                                    margin: "auto"
                                }}
                                spacing={2}
                            >
                                <TextField
                                    label="New Password"
                                    type="password"
                                    name="newPassword"
                                    variant="outlined"
                                    size="small"
                                    required={true}
                                />
                                <TextField
                                    label="Confirm Password"
                                    type="password"
                                    name="confirmPassword"
                                    variant="outlined"
                                    size="small"
                                    required={true}
                                />
                                <Button
                                    variant="contained"
                                    style={{ cursor: "pointer" }}
                                    onClick={onSubmit}
                                >
                                    SUBMIT
                                </Button>
                            </Stack>
                        </form>
                    </>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )
            }

        </>
    );
}

export default ChangePassword; 