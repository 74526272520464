import { BusinessLocation } from '../../models/Business';
import Event from '../Event/Event';
import './Schedule.css';
import { ScheduleReducerPayload, ScheduleState } from './ScheduleReducer';

interface IDaysEventsProps {
    scheduleState: ScheduleState;
    dispatch: React.Dispatch<ScheduleReducerPayload>;
    locations: BusinessLocation[] | undefined;
}

const DaysEvents: React.FC<IDaysEventsProps> = ({ scheduleState, dispatch, locations }) => {

    return (
        <div className='events'>
            {
                scheduleState?.businessEvents?.filter(e => {
                    let eventDate = new Date(e?.startDate);
                    eventDate.setHours(12, 0, 0, 0);

                    return +eventDate === +scheduleState.selectedDay;
                }).map(e => (
                    <Event key={e.id} event={e} handleEditClick={dispatch} locations={locations} />
                ))
            }
        </div>
    )
}

export default DaysEvents;