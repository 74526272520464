import { Stripe } from 'stripe';
import { Business } from '../models/Business';
import { Employee } from '../models/Employee';
import { AccountLockedException, Exception, IncorrectPasswordException, UserNotFoundException } from '../models/ExceptionTypes';
import SessionService from './SessionService';


export default class AuthService {
    public static LoginUser =
        async (loginRequest: { email?: string, password?: string, accountType: string } | null, guestToken: string):
            Promise<{ user: Employee | Business, stripeAccount?: Stripe.Account, token: string, expiration: string }> => {
            const response = await fetch(`/api/Auth/Login/${loginRequest?.accountType}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${guestToken}`,
                    'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
                },
                body: JSON.stringify({
                    emailAddress: loginRequest?.email,
                    password: loginRequest?.password
                })
            });

            if (!response.ok) {
                if (response.status === 404)
                    throw new UserNotFoundException(`User ${loginRequest?.email} was not found`);
                else if (response.status === 401)
                    throw new IncorrectPasswordException(await response.json());
                else if (response.status === 423)
                    throw new AccountLockedException(await response.json());
                else
                    throw new Exception(`Error logging in user: ${loginRequest?.email}`);
            }

            return await response.json();
        }

    public static Logout = (): void => {
        SessionService.Clear();
    }

    public static GetGuestToken = async (): Promise<{ token: string, expiration: string, showAdmin: boolean }> => {
        const response = await fetch('/api/Auth/GuestToken', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok)
            console.error('Error retrieving application necessary guest token');

        return await response.json();
    }

    public static CheckTokenType = async (token: string): Promise<{ user: Business | Employee, imageUrl: string }> => {
        const response = await fetch('/api/auth/tokenType', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok)
            console.error('Error retrieving token type and user');

        return await response.json();
    }
}