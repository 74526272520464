import { Alert, Button, Snackbar, Tab, Tabs } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent/CardContent';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useQuery } from "@tanstack/react-query";
import { FC, useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { Employee } from "../../models/Employee";
import { Exception } from "../../models/ExceptionTypes";
import { TSEvent } from '../../models/TSEvent';
import { Tip, Tipout } from '../../models/Tip';
import ApiClient from '../../services/ApiClient';
import SignalRService from "../../services/SignalRService";
import TipService from '../../services/TipService';
import "./EmployeeProfile.css";
import TransferDrawer from './TransferDrawer';

export interface EmployeeTipouts {
    tipouts: {
        [key: string]: {
            event: TSEvent,
            tipout: Tipout,
            tips: Tip[],
            employeeCount: number
        }
    },
    tipoutTotal: number
}

export interface TransferDetails {
    balance: number;
    balanceString: string;
    instantTransferFee: number;
    instantTransferFeeString: string;
}

export enum TransferType {
    SameDayTransfer,
    StandardTransfer
}

const Tipouts: FC = () => {
    const [appContext] = useContext(AppContext);
    const [tipouts, setTipouts] = useState<EmployeeTipouts | null>(null);
    const [numOfEvents, setNumOfEvents] = useState<number>(0);
    const [transferDetails, setTransferDetails] = useState<TransferDetails>({ balance: 0.00, balanceString: '$0.00', instantTransferFee: 0.00, instantTransferFeeString: "$0.00" });
    const [openTransfer, setOpenTransfer] = useState<boolean>(false);
    const [transferType, setTransferType] = useState<TransferType>(TransferType.SameDayTransfer);
    const [successfulTransfer, setSuccessfulTransfer] = useState<{ wasSuccess: boolean, amount: number }>({ wasSuccess: false, amount: 0.00 });
    const employee = appContext.user as Employee;

    useEffect(() => {
        const signalRService = new SignalRService('/hubs/Tiphub', appContext.auth.token);

        signalRService.start();

        signalRService.subscribeToEvent('tipoutPaid', (t: EmployeeTipouts) => {
            setTipouts(t);
        });

        return () => {
            signalRService.stop();
        }
    }, []);

    const tipoutsQuery = useQuery<EmployeeTipouts>({
        queryKey: ['employeeTipouts'],
        queryFn: async () => {
            const res = await ApiClient.GetAsync("/api/tipouts/employeeTipouts", appContext.auth.token);

            if (!res.ok) {
                throw new Exception('Error getting Tipouts.');
            }

            return res.json();
        }
    });

    const balanceQuery = useQuery<TransferDetails>({
        queryKey: ['employeeBalance'],
        queryFn: async () => {
            const res = await ApiClient.GetAsync('/api/employees/balance', appContext.auth.token);

            if (!res.ok) {
                throw new Exception(`Unable to retrieve balance for employee ${appContext.user.id}`)
            }

            return res.json();
        }
    });

    useEffect(() => {
        if (tipoutsQuery.data) {
            setTipouts(tipoutsQuery.data);
            setNumOfEvents(Object.entries(tipoutsQuery.data).length);
        }
    }, [tipoutsQuery.data]);

    useEffect(() => {
        if (balanceQuery.data) {
            setTransferDetails(balanceQuery.data);
        }
    }, [balanceQuery.data]);

    const handleTipoutClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.currentTarget.style.height = e.currentTarget.offsetHeight === 120 ? "325.25px" : "120px";
        (e.currentTarget.querySelector(".tip-list") as HTMLDivElement).classList.toggle("visible");
        (e.currentTarget.querySelector(".divider") as HTMLDivElement).classList.toggle("visible");
    }

    if (tipoutsQuery.isLoading || balanceQuery.isLoading) return <></>;
    if (tipoutsQuery.isError || balanceQuery.isError) return <></>;

    return (
        <div className="employee-tipouts">
            {
                tipouts !== null && (
                    <>
                        <Typography className="header" variant="h4" style={{ fontSize: '22px' }}>
                            {employee.firstName}, you've worked {numOfEvents} TipSplit event{numOfEvents > 1 && "s"} and made {tipoutsQuery.data?.tipoutTotal}!
                        </Typography>


                        {
                            transferDetails.balance > 0.00 && (
                                <div style={{ textAlign: 'left', width: '95vw', margin: '0 auto 15px' }}>
                                    <Typography variant="h5" style={{ fontSize: '18px', marginBottom: '7px' }}>TipSplit balance</Typography>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography variant="h6" style={{ fontSize: '24px' }}>{transferDetails.balanceString}</Typography>
                                        <Button variant="contained" size="small" style={{ marginLeft: 'auto' }} onClick={() => setOpenTransfer(true)}>TRANSFER</Button>
                                    </div>
                                </div>
                            )
                        }

                        <Tabs>
                            <Tab label="Tips" />
                            <Tab label="Withdraws" />
                        </Tabs>

                        {
                            Object.entries(tipouts?.tipouts).map(t => (
                                <Card
                                    key={t[1].tipout.id}
                                    className="tip-breakdown"
                                    onClick={e => handleTipoutClick(e)}
                                    variant="outlined"
                                    sx={{
                                        height: "120px",
                                        maxWidth: "95vw",
                                        margin: "auto",
                                        textAlign: "left",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <CardContent>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography variant="h6">
                                                {t[0]}
                                            </Typography>
                                            <Typography variant="caption" style={{ marginLeft: 'auto' }}>{new Date(t[1].event.startDate).toLocaleDateString('en-US')}</Typography>
                                        </div>
                                        <Typography variant="body1">
                                            Total of all tips: {TipService.FormatTip(t[1].tips.reduce((total, tip) => total += tip.amount - tip.applicationFee, 0.00))}
                                        </Typography>
                                        <Paper className="tip-list" elevation={4} sx={{ marginTop: '10px', minHeight: "175px" }}>
                                            <List
                                                sx={{
                                                    width: '99%',
                                                    margin: 'auto',
                                                    bgcolor: 'background.paper',
                                                    overflow: 'auto',
                                                    maxHeight: 175,
                                                    '& ul': { padding: 0 }
                                                }}

                                            >
                                                {
                                                    t[1]?.tips?.map(tip => (
                                                        <ListItem key={tip.id} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                            <ListItemText primary={TipService.FormatTip(tip.amount - tip.applicationFee)} />
                                                        </ListItem>
                                                    ))
                                                }
                                            </List>
                                        </Paper>
                                        <Divider
                                            className="divider"
                                            sx={{
                                                marginTop: '10px',
                                                marginBottom: '10px'
                                            }}
                                        />
                                        <Typography variant="body1">
                                            Your split: {TipService.FormatTip(t[1].tipout.amount)}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            ))
                        }

                        <TransferDrawer
                            employee={employee}
                            openTransfer={openTransfer}
                            setOpenTransfer={setOpenTransfer}
                            transferType={transferType}
                            setTransferType={setTransferType}
                            transferDetails={transferDetails}
                            setTransferDetails={setTransferDetails}
                            setSuccessfulTransfer={setSuccessfulTransfer}
                        />

                        <Snackbar
                            open={successfulTransfer.wasSuccess}
                            autoHideDuration={5000}
                            onClose={() => setSuccessfulTransfer({ ...successfulTransfer, wasSuccess: false })}
                        >
                            <Alert severity="success" variant="filled" style={{ width: '100%' }}>
                                Transfer of {TipService.FormatTip(successfulTransfer.amount)} initiated successfully
                            </Alert>
                        </Snackbar>
                    </>
                )
            }
        </div>
    );
}

export { Tipouts };

