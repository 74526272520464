import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import jwt_decode from "jwt-decode";
import { FC, useContext, useEffect, useState } from 'react';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { PaymentStatus, Signup, TipPage } from './components';
import AdminConsole from './components/AdminConsole/AdminConsole';
import BusinessProfile from './components/BusinessProfile/BusinessProfile';
import ChangePassword from './components/ChangePassword/ChangePassword';
import ForgotPassword from './components/ChangePassword/ForgotPassword';
import ContactUs from './components/ContactUs/ContactUs';
import EmployeeProfile from './components/EmployeeProfile/EmployeeProfile';
import SubscriptionErrorBoundary from './components/ErrorBoundaries/SubscriptionErrorBoundary';
import HomePage from './components/HomePage/HomePage';
import Login from './components/Login/Login';
import PrivacyPage from './components/PrivacyPage/PrivacyPage';
import { TermsAndConditions } from './components/PrivacyPage/TermsOfService';
import BusinessSubscription from './components/Signup/BusinessSubscription';
import SiteMaintenance from './components/SiteMaintenance/SiteMaintenance';
import ConfirmAccount from './components/Stripe/ConfirmAccount';
import SubscriptionSuccess from './components/Stripe/SubscriptionSuccess';
import AppContext, { AccountType, AppContextReducer, UpdateAccountDetails, UpdateToken, UpdateUser } from './context/AppContext';
import useTipSplitState from './hooks/UseTipSplitState';
import { Employee } from './models/Employee';
import AuthService from './services/AuthService';
import SessionService from './services/SessionService';
import { IsEmployee } from './utils/Helpers';

export const App: FC = () => {
  const [appContext, dispatch] = useContext(AppContext);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (!appContext.auth.token) {
      AuthService.GetGuestToken().then(({ token, expiration }) => {
        dispatch(UpdateToken(token, expiration));
      });
    } else if (appContext.auth.token && !isGuestOrAdminToken((jwt_decode(appContext.auth.token) as any).role) && !appContext.user.id) {
      AuthService.CheckTokenType(appContext.auth.token).then(({ user }) => {
        dispatch(UpdateUser(user));
        setLoggedIn(true);
      });
    } else if (appContext.auth.token && !isGuestOrAdminToken((jwt_decode(appContext.auth.token) as any).role) && appContext.user.id) {
      setLoggedIn(true);
    } else if (appContext.auth.token && (jwt_decode(appContext.auth.token) as any).role === "Guest") {
      setLoggedIn(false);

      dispatch(UpdateAccountDetails({
        accountType: AccountType.GUEST,
        user: {} as Employee,
        token: appContext.auth.token,
        expiration: appContext.auth.expiration
      }));
    }
  }, [appContext.auth.expiration, appContext.auth.token, appContext.user.id, dispatch]);

  const isGuestOrAdminToken = (role: string | string[]): boolean => {
    const adminGuestRegex: RegExp = /^(?=.*\badministrator\b|\bguest\b).*$/i;

    return typeof (role) === 'string' ? !!role.match(adminGuestRegex) : role.some(r => !!r.match(adminGuestRegex));
  }

  const handleLogoutClick = async () => {
    setLoggedIn(false);

    const { token, expiration } = await AuthService.GetGuestToken();
    SessionService.Clear();

    dispatch(UpdateAccountDetails({
      accountType: AccountType.GUEST,
      user: {} as Employee,
      token,
      expiration
    }));
  }

  return (
    <>
      {appContext.auth.token && (
        <div className='App'>
          <Router>
            <SiteMaintenance>
              <div className='header-container'>
                {loggedIn && <AppMenu menuItems={[{ label: 'Logout', onClick: handleLogoutClick }]} />}
                <Routes>
                  <Route path="/app" element={
                    <Link to='/app' className='app-header-link'>
                      <h1 className='app-header'>TipSplit</h1>
                    </Link>
                  } />
                </Routes>
              </div>

              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path='/contact-us' element={<ContactUs />} />
                <Route path='/privacy' element={<PrivacyPage />} />

                <Route path='/app' element={
                  <>
                    {!loggedIn ? <Login /> : (
                      <div>
                        {
                          IsEmployee(appContext.user) ?
                            <EmployeeProfile /> : (
                              <SubscriptionErrorBoundary>
                                <BusinessProfile />
                              </SubscriptionErrorBoundary>
                            )
                        }
                      </div>
                    )}
                  </>
                } />

                <Route path='/app/tip/event/:id' element={<TipPage />} />
                <Route path='/app/tip-status/:id' element={<PaymentStatus />} />
                <Route path='/app/signup' element={<Signup />} />
                <Route path='/app/forgotPassword' element={<ForgotPassword />} />
                <Route path='/app/changePassword' element={<ChangePassword />} />
                <Route path='/app/subscription' element={<BusinessSubscription />} />
                <Route path='/app/subscription/success' element={<SubscriptionSuccess />} />
                <Route path="/app/account/confirm" element={<ConfirmAccount />} />
                <Route path="/app/admin" element={<AdminConsole />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              </Routes>
            </SiteMaintenance >
          </Router>
        </div>
      )}
    </>
  );
}

const queryClient = new QueryClient();

const AppContainer: FC = () => {
  return (
    <AppContext.Provider value={useTipSplitState(AppContextReducer)}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </AppContext.Provider>
  )
}

const AppMenu: FC<{ menuItems: { label: string, onClick: () => any }[] }> = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);
  const open: boolean = !!anchorEl;

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <div className="logout">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          menuItems.map(menuItem => (
            <MenuItem key={menuItem.label} onClick={menuItem.onClick}>
              {menuItem.label}
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}

export default AppContainer;
