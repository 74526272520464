import { Dispatch, SetStateAction } from "react";
import { InputError } from "../../models/InputError";
import { getInputValueByName } from "../../utils/Helpers";
import { getAddressData } from "../AddressForm/AddressForm";

export const validateLocationDetails = (): [boolean, { [key: string]: InputError }] => {
    const locationName = getInputValueByName("locationName");
    const { street, city, state, zip } = getAddressData("locationAddress");

    let locationFormErrors: { [key: string]: InputError } = {
        locationName: {
            helperText: !locationName ? "Location name is a required field" : "",
            error: !(!!locationName)
        },
        address: {
            helperText: !street ? "Street address is a required field" : "",
            error: !(!!street)
        },
        city: {
            helperText: !city ? "City is a required field" : "",
            error: !(!!city)
        },
        state: {
            helperText: !state ? "State is a required field" : "",
            error: !(!!state)
        },
        zipCode: {
            helperText: !zip ? "Zip Code is a required field" : "",
            error: !(!!zip)
        }
    }

    return [!Object.values(locationFormErrors).some(inputError => inputError.error === true), locationFormErrors];
}

export const resetLocationFormError = (
    inputName: string,
    locationFormState: { [key: string]: InputError },
    setLocationFormState: Dispatch<SetStateAction<{ [key: string]: InputError }>>
): void => {
    locationFormState[inputName] = {
        helperText: "",
        error: false
    }

    setLocationFormState({ ...locationFormState });
}