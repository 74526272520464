
export default class ApiClient {
    public static GetAsync = async (url: string, token: string): Promise<Response> => {
        return await fetch(url, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
    }

    public static PostAsync = async (url: string, body: any, token: string): Promise<Response> => {
        return await fetch(url, {
            method: "POST",
            headers: ApiClient.BuildBasicHeaders(token),
            body: JSON.stringify(body)
        });
    }

    public static PutAsync = async (url: string, body: any, token: string): Promise<Response> => {
        return await fetch(url, {
            method: "PUT",
            headers: ApiClient.BuildBasicHeaders(token),
            body: JSON.stringify(body)
        });
    }

    public static DeleteAsync = async (url: string, token: string): Promise<Response> => {
        return await fetch(url, {
            method: "DELETE",
            headers: ApiClient.BuildBasicHeaders(token)
        });
    }

    public static GetQRCode = async (eventId: string, token: string): Promise<Response> => {
        return await fetch(`/api/events/qrcode/${eventId}`, {
            method: "GET",
            headers: {
                "Accept": "image/jpeg",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
    }

    private static BuildBasicHeaders = (token: string): HeadersInit => {
        return {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        };
    }
}