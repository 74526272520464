import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../context/AppContext';
import ApiClient from '../../services/ApiClient';
import { Footer } from '../HomePage/HomePage';
import "./ContactUs.css";


const ContactUs: FC = () => {
    const [appContext] = useContext(AppContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [emailsSent, setEmailsSent] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<{ [key: string]: { error: boolean, helperText: string } }>({
        email: {
            error: false,
            helperText: 'Email is a required field'
        },
        subject: {
            error: false,
            helperText: 'Subject is a required field'
        },
        message: {
            error: false,
            helperText: 'Message is a required field'
        }
    });

    const sendContactUsEmail = async (): Promise<void> => {
        clearErrors();
        validateForm();

        try {

            if (!Object.values(formErrors).some(e => e.error === true)) {
                let res = await ApiClient.PostAsync('/api/Email/ContactUs', { email, subject, message }, appContext.auth.token);

                if (res.ok)
                    setEmailsSent(true);
            }
        } catch (e: any) {
            // Show errors
            console.error(e)
        }
    }

    const validateForm = (): void => {
        setFormErrors(formState => {
            if (!email) {
                formState['email'].error = true;
            }

            if (!subject) {
                formState['subject'].error = true;
            }

            if (!message) {
                formState['message'].error = true;
            }

            return { ...formState };
        });
    }

    const clearErrors = (): void => {
        setFormErrors(formState => (
            Object.entries(formState).reduce((state, field) => {
                state[field[0]].error = false;
                return state;
            }, formState)
        ))
    }

    return (
        <>
            <div className='contact-us' style={{ position: 'relative', marginTop: '15px' }}>
                <ArrowBackIcon onClick={() => navigate(-1)} color='primary' style={{ position: 'absolute', left: '2%', cursor: 'pointer' }} />

                {
                    !emailsSent ? (
                        <>
                            <Typography variant='h5'>Contact Us</Typography>
                            <TextField
                                label='Email address'
                                name='email'
                                type='email'
                                variant='outlined'
                                fullWidth={true}
                                size='small'
                                required={true}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                error={formErrors['email'].error}
                                helperText={formErrors['email'].error ? formErrors['email'].helperText : ''}
                            />
                            <TextField
                                label='Subject'
                                name='subject'
                                type='text'
                                variant='outlined'
                                fullWidth={true}
                                size='small'
                                required={true}
                                value={subject}
                                onChange={e => setSubject(e.target.value)}
                                error={formErrors['subject'].error}
                                helperText={formErrors['subject'].error ? formErrors['subject'].helperText : ''}
                            />
                            <TextField
                                label='Message'
                                name='message'
                                type='text'
                                variant='outlined'
                                size='small'
                                fullWidth={true}
                                multiline={true}
                                minRows={10}
                                maxRows={20}
                                required={true}
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                error={formErrors['message'].error}
                                helperText={formErrors['message'].error ? formErrors['message'].helperText : ''}
                            />
                            <Button variant='contained' onClick={sendContactUsEmail}>SUBMIT</Button>
                        </>
                    ) : (
                        <>
                            <Typography variant='h4'>
                                TipSplit
                            </Typography>
                            <Typography variant='h6'>
                                Your message has been received! We'll get back to you shortly.
                            </Typography>
                        </>
                    )
                }
            </div>
            <Footer />
        </>
    );
}

export default ContactUs;