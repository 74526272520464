import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Alert from "@mui/material/Alert";
import Avatar from '@mui/material/Avatar/Avatar';
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import Paper from '@mui/material/Paper';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../context/AppContext";
import { Business } from "../../models/Business";
import { HttpPostException } from "../../models/ExceptionTypes";
import { InputError } from "../../models/InputError";
import ApiClient from "../../services/ApiClient";
import { getInputValueByName, getInputValuesByName } from "../../utils/Helpers";
import { getAddressData } from "../AddressForm/AddressForm";
import Disclaimer from '../Disclaimer/Disclaimer';
import StateSelect from "../StateSelect/StateSelect";

const StripePriceLookupKey: { [key: string]: string } = {
    smallCompany: 'SmallCompany',
    mediumCompany: 'MediumCompany',
    largeCompany: 'LargeCompany'
}

const BusinessDetails: FC<{ emailPassword: { email: string, password: string }, setInEffect: Dispatch<SetStateAction<boolean>> }> =
    ({ emailPassword, setInEffect }) => {
        const [appContext] = useContext(AppContext);
        const [step, setStep] = useState(1);
        const [newBusiness, setNewBusiness] = useState<{ business: Business; token: string; } | null>(null);
        const [businessFormErrorMessage, setBusinessFormErrorMessage] = useState<string>("");
        const [locationFormErrorMessage, setLocationFormErrorMessage] = useState<string>("");
        const [businessFormState, setBusinessFormState] = useState<{ [key: string]: InputError }>({});
        const [locationFormState, setLocationFormState] = useState<{ [key: string]: InputError }>({});
        const [logo, setLogo] = useState<string>("");
        const logoRef = useRef<HTMLInputElement | null>(null);
        const [subscriptionLevel, setSubscriptionLevel] = useState<string>(StripePriceLookupKey.smallCompany);

        useEffect(() => {
            if (!Object.values(businessFormState).some(inputError => inputError.error === true))
                setBusinessFormErrorMessage('');
        }, [businessFormState]);

        useEffect(() => {
            if (!Object.values(locationFormState).some(inputError => inputError.error === true))
                setLocationFormErrorMessage('');
        }, [locationFormState]);

        //#region ACTION HANDLERS

        const handleContinueClick = () => {
            const [validForm, businessFormErrors] = validateBusinessDetails();

            if (validForm)
                setStep(step + 1);
            else {
                setBusinessFormErrorMessage("Please fill out the required fields");
                setBusinessFormState(businessFormErrors);
            }
        }

        const handleSaveAndContinueClick = async () => {
            // setCreatingUser(true);
            const [validForm, locationFormErrors] = validateLocationDetails();

            if (validForm)
                try {
                    setNewBusiness(await createBusiness());

                    // setCreatingUser(false);
                    setStep(step + 1);
                } catch (e) {
                    setLocationFormErrorMessage((e as HttpPostException).message);
                }
            else {
                setLocationFormErrorMessage("Please fill out the required fields");
                setLocationFormState(locationFormErrors);
            }
        }

        const onImageUpload = (): void => {
            let image: File | null | undefined = logoRef.current?.files?.item(0);

            if (image) setLogo(URL.createObjectURL(image));
        }

        const handleSubscriptionLevelSelection = (e: React.MouseEvent<HTMLDivElement>, lookupKey: string) => {
            setSubscriptionLevel(lookupKey);

            document.querySelector('.company-sizes .selected')?.classList.remove('selected');

            e.currentTarget.classList.add('selected');
        }

        const handleContinueToSubscriptionClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setInEffect(true);

            let formData = new FormData(document.querySelector('#subscription-level-form') as HTMLFormElement);

            let res = await fetch('/api/stripe/createCheckoutSession', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${newBusiness?.token}`
                },
                body: formData
            });

            if (res.ok) {
                let sessionUrl = res.headers.get('Location');

                if (sessionUrl) {
                    window.location.href = sessionUrl
                } else {
                    console.error('No location header set in response object');
                }
            } else {
                console.error(`Unable to create new subscription session for new business -- ${await res.json()}`);
            }
        }

        //#endregion ACTION HANDLERS

        //#region FORM VALIDATORS

        const validateBusinessDetails = (): [boolean, { [key: string]: InputError }] => {
            const { businessName, phoneNumber } = getInputValuesByName(["businessName", "phoneNumber"]);
            const { street, city, state, zip } = getAddressData("businessAddress");

            // if value === "" set error to true. 
            // !!value will return false when value is ""
            // need to use opposite to get wanted result hence !(!!value)
            let businessFormErrors: { [key: string]: InputError } = {
                businessName: {
                    helperText: !businessName ? "Business Name is a required field." : "",
                    error: !(!!businessName)
                },
                phoneNumber: {
                    helperText: !businessName ? "Phone number is a required field." : "",
                    error: !(!!phoneNumber)
                },
                address: {
                    helperText: !street ? "Street address is a required field." : "",
                    error: !(!!street)
                },
                city: {
                    helperText: !city ? "City is a required field." : "",
                    error: !(!!city)
                },
                state: {
                    helperText: !state ? "State is a required field." : "",
                    error: !(!!state)
                },
                zipCode: {
                    helperText: !zip ? "Zip Code is a required field." : "",
                    error: !(!!zip)
                }
            }

            return [!Object.values(businessFormErrors).some(inputError => inputError.error === true), businessFormErrors];
        }

        const validateLocationDetails = (): [boolean, { [key: string]: InputError }] => {
            const locationName = getInputValueByName("locationName");
            const { street, city, state, zip } = getAddressData("locationAddress");

            let locationFormErrors: { [key: string]: InputError } = {
                locationName: {
                    helperText: !locationName ? "Location name is a required field" : "",
                    error: !(!!locationName)
                },
                address: {
                    helperText: !street ? "Street address is a required field" : "",
                    error: !(!!street)
                },
                city: {
                    helperText: !city ? "City is a required field" : "",
                    error: !(!!city)
                },
                state: {
                    helperText: !state ? "State is a required field" : "",
                    error: !(!!state)
                },
                zipCode: {
                    helperText: !zip ? "Zip Code is a required field" : "",
                    error: !(!!zip)
                }
            }

            return [!Object.values(locationFormErrors).some(inputError => inputError.error === true), locationFormErrors];
        }

        //#endregion FORM VALIDATORS

        const createBusiness = async (): Promise<{ business: Business, token: string }> => {
            const { businessName, phoneNumber, locationName } = getInputValuesByName(["businessName", "phoneNumber", "locationName"]);
            let logo = logoRef.current && logoRef.current.files && logoRef.current.files[0] ? logoRef.current.files[0] : null;

            const businessData = {
                businessName: businessName,
                businessDetails: {
                    emailAddress: emailPassword?.email,
                    password: emailPassword?.password,
                    phoneNumber: phoneNumber
                },
                address: getAddressData("businessAddress"),
                location: {
                    locationName,
                    address: getAddressData("locationAddress")
                }
            };

            setInEffect(true);

            let res = await ApiClient.PostAsync("/api/Business", businessData, appContext.auth.token);

            if (!res.ok) {
                if (res.status === 500)
                    throw new HttpPostException("There was an error creating a new business. Please validate all details");
                else if (res.status === 400)
                    throw new HttpPostException("Could not validate all business details. Please validate all details are correct and try again");
            }

            let savedBusiness: { business: Business, token: string } = await res.json();

            savedBusiness.business.businessLogo = await uploadLogo(savedBusiness.business.id, logo);

            setInEffect(false);

            return savedBusiness;
        }

        const uploadLogo = async (businessId: string, logo: File | null): Promise<string> => {
            if (logo) {
                let formData = new FormData();
                formData.append('logo', logo);

                let res = await fetch(`/api/business/uploadLogo/${businessId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${appContext.auth.token}`,
                        'Accept': 'application/json'
                    },
                    body: formData
                });

                if (!res.ok) {
                    if (res.status === 400)
                        throw new HttpPostException(`Model state invalid`);
                    else if (res.status === 401)
                        throw new HttpPostException(`Cannot upload logo. Unauthorized.`);
                    else if (res.status === 500)
                        throw new HttpPostException(`Internal server error: ${await res.json()}`);
                }

                return await res.json();
            } else {
                return '';
            }
        }

        //#region HELPERS

        const resetBusinessFormError = (inputName: string): void => {
            businessFormState[inputName] = {
                helperText: "",
                error: false
            };

            setBusinessFormState({ ...businessFormState });
        }

        const resetLocationFormError = (inputName: string): void => {
            locationFormState[inputName] = {
                helperText: "",
                error: false
            }

            setLocationFormState({ ...locationFormState });
        }

        //#endregion HELPERS

        return (
            <>
                <Stack spacing={2} style={{ display: `${step === 1 ? "flex" : "none"}` }}>
                    <h3 className="details-header">BUSINESS INFORMATION</h3>
                    {businessFormErrorMessage && (
                        <div style={{ width: "95vw", margin: "auto", marginTop: "15px" }}>
                            <Alert severity="error" variant="outlined">{businessFormErrorMessage}</Alert>
                        </div>
                    )}
                    <TextField
                        label="Business Name"
                        name="businessName"
                        type="text"
                        variant="outlined"
                        size="medium"
                        required={true}
                        error={businessFormState?.businessName?.error}
                        helperText={businessFormState?.businessName?.helperText}
                        onChange={e => e.currentTarget.value && businessFormState?.businessName?.error && resetBusinessFormError(e.currentTarget.name)}
                    />
                    <TextField
                        label="Phone Number"
                        name="phoneNumber"
                        type="text"
                        variant="outlined"
                        size="medium"
                        required={true}
                        inputProps={{
                            inputMode: "numeric"
                        }}
                        error={businessFormState?.phoneNumber?.error}
                        helperText={businessFormState?.phoneNumber?.helperText}
                        onChange={e => e.currentTarget.value && businessFormState?.phoneNumber?.error && resetBusinessFormError(e.currentTarget.name)}
                    />
                    <Stack id="businessAddress" spacing={2}>
                        <TextField
                            label="Address"
                            name="address"
                            type="text"
                            variant="outlined"
                            size="medium"
                            required={true}
                            error={businessFormState?.address?.error}
                            helperText={businessFormState?.address?.helperText}
                            onChange={e => e.currentTarget.value && businessFormState?.address?.error && resetBusinessFormError(e.currentTarget.name)}
                        />
                        <TextField
                            label="City"
                            name="city"
                            type="text"
                            variant="outlined"
                            size="medium"
                            required={true}
                            error={businessFormState?.city?.error}
                            helperText={businessFormState?.city?.helperText}
                            onChange={e => e.currentTarget.value && businessFormState?.city?.error && resetBusinessFormError(e.currentTarget.name)}
                        />
                        <div>
                            <StateSelect errorInfo={businessFormState} updateError={setBusinessFormState} />
                            <TextField
                                label="Zip Code"
                                name="zipCode"
                                type="text"
                                variant="outlined"
                                size="medium"
                                required={true}
                                style={{ width: "58vw" }}
                                inputProps={{
                                    inputMode: "numeric"
                                }}
                                error={businessFormState?.zipCode?.error}
                                helperText={businessFormState?.zipCode?.helperText}
                                onChange={e => e.currentTarget.value && businessFormState?.zipCode?.error && resetBusinessFormError(e.currentTarget.name)}
                            />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <input
                                ref={logoRef}
                                type='file'
                                id='business-logo'
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={onImageUpload}
                            />
                            <label htmlFor='business-logo' style={{ width: '65%' }}>
                                <Fab variant="extended" sx={{ width: '100%' }} onClick={() => logoRef.current?.click()}>
                                    <CloudUploadIcon sx={{ mr: 1 }} />
                                    UPLOAD LOGO
                                </Fab>
                            </label>
                            {
                                logo && <Avatar
                                    alt="Business logo"
                                    src={logo}
                                    sx={{ width: 48, height: 48, marginLeft: '5%' }}
                                />
                            }
                        </div>
                    </Stack>
                    <Button variant="contained" onClick={handleContinueClick}>
                        CONTINUE
                    </Button>
                </Stack>
                <Stack spacing={2} style={{ display: `${step === 2 ? "flex" : "none"}` }}>
                    <h3 className="details-header">BUSINESS LOCATION</h3>
                    {locationFormErrorMessage && (
                        <div style={{ width: "95vw", margin: "auto", marginTop: "15px" }}>
                            <Alert severity="error" variant="outlined">{locationFormErrorMessage}</Alert>
                        </div>
                    )}
                    <TextField
                        label="Location Name"
                        name="locationName"
                        type="text"
                        variant="outlined"
                        size="medium"
                        required={true}
                        error={locationFormState?.locationName?.error}
                        helperText={locationFormState?.locationName?.helperText}
                        onChange={e => e.currentTarget.value && locationFormState?.locationName?.error && resetLocationFormError(e.currentTarget.name)}
                    />
                    <Stack id="locationAddress" spacing={2}>
                        <TextField
                            label="Address"
                            name="address"
                            type="text"
                            variant="outlined"
                            size="medium"
                            required={true}
                            error={locationFormState?.address?.error}
                            helperText={locationFormState?.address?.helperText}
                            onChange={e => e.currentTarget.value && locationFormState?.address?.error && resetLocationFormError(e.currentTarget.name)}
                        />
                        <TextField
                            label="City"
                            name="city"
                            type="text"
                            variant="outlined"
                            size="medium"
                            required={true}
                            error={locationFormState?.city?.error}
                            helperText={locationFormState?.city?.helperText}
                            onChange={e => e.currentTarget.value && locationFormState?.city?.error && resetLocationFormError(e.currentTarget.name)}
                        />
                        <div>
                            <StateSelect errorInfo={locationFormState} updateError={setLocationFormState} />
                            <TextField
                                label="Zip Code"
                                name="zipCode"
                                type="text"
                                variant="outlined"
                                size="medium"
                                required={true}
                                style={{ width: "58vw" }}
                                inputProps={{
                                    inputMode: "numeric"
                                }}
                                error={locationFormState?.zipCode?.error}
                                helperText={locationFormState?.zipCode?.helperText}
                                onChange={e => e.currentTarget.value && locationFormState?.zipCode?.error && resetLocationFormError(e.currentTarget.name)}
                            />
                        </div>
                    </Stack>
                    <Button variant="contained" onClick={() => setStep(step - 1)}>
                        BACK
                    </Button>
                    <Button
                        className="save-continue-button"
                        variant="contained"
                        onClick={handleSaveAndContinueClick}
                    >
                        SAVE AND CONTINUE
                    </Button>
                </Stack>
                {
                    newBusiness && (
                        <Stack spacing={2} style={{ display: `${step === 3 ? "flex" : "none"}` }}>
                            <h3 className="details-header">COMPANY SIZE</h3>
                            <div className='company-sizes'>
                                <Paper
                                    elevation={6}
                                    className='company-size selected'
                                    onClick={e => handleSubscriptionLevelSelection(e, StripePriceLookupKey.smallCompany)}
                                >
                                    <Typography variant='body1'>Small</Typography>
                                    <Typography variant='caption'>1 to 10 Employees</Typography>
                                </Paper>
                                <Paper
                                    elevation={6}
                                    className='company-size'
                                    onClick={e => handleSubscriptionLevelSelection(e, StripePriceLookupKey.mediumCompany)}
                                >
                                    <Typography variant='body1'>Medium</Typography>
                                    <Typography variant='caption'>11 to 25 Employees</Typography>
                                </Paper>
                                <Paper
                                    elevation={6}
                                    className='company-size'
                                    onClick={e => handleSubscriptionLevelSelection(e, StripePriceLookupKey.largeCompany)}
                                >
                                    <Typography variant='body1'>Large</Typography>
                                    <Typography variant='caption'>26 to 50 Employees</Typography>
                                </Paper>
                            </div>
                            <Disclaimer>
                                <p>
                                    We use Stripe to manage you subscription, collect payments from your guests, and make sure your employees get paid on time. By using Stripe we ensure that all of your information remains safe and doesn't fall in to the right hands. Please select a subscription level above to get started.
                                </p>
                            </Disclaimer>
                            <Button variant='contained' onClick={() => setStep(step - 1)}>
                                BACK
                            </Button>
                            <form id='subscription-level-form'>
                                <input type='hidden' name='business_email' value={emailPassword.email} />
                                <input type='hidden' name='business_id' value={newBusiness.business.id} />
                                <input type='hidden' name='lookup_key' value={subscriptionLevel} />
                                <Button
                                    onClick={handleContinueToSubscriptionClick}
                                    variant='contained'
                                    style={{ marginTop: '0', width: '100%' }}
                                >
                                    CONTINUE TO SUBSCRIPTION SETUP
                                </Button>
                            </form>
                        </Stack>
                    )
                }
            </>
        )
    }

export default BusinessDetails;