import EditIcon from '@mui/icons-material/Edit';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { Box, DialogContent } from '@mui/material';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, MouseEvent, useContext, useEffect, useState } from "react";
import AppContext from '../../context/AppContext';
import useAsyncError from "../../hooks/UseAsyncError";
import usePersistentState from "../../hooks/UsePersistentState";
import { BusinessLocation } from '../../models/Business';
import { Employee } from "../../models/Employee";
import { TSEvent } from "../../models/TSEvent";
import { Tip } from '../../models/Tip';
import ApiClient from '../../services/ApiClient';
import SignalRService from "../../services/SignalRService";
import TipService from '../../services/TipService';
import { ScheduleReducerPayload } from '../Schedule/ScheduleReducer';
import './Event.css';

export interface EventProps {
    event: TSEvent;
    handleEditClick: (value: ScheduleReducerPayload) => void;
    locations: BusinessLocation[] | undefined;
}

const Event: FC<EventProps> = ({ event, handleEditClick, locations }) => {
    const [appContext] = useContext(AppContext);
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const [qrOpen, setQrOpen] = useState<boolean>(false);
    const [eventQRCode, setEventQRCode] = useState<string>("");
    const activeEvent: boolean = isActiveEvent();
    const throwSubscriptionError = useAsyncError();
    const [liveTips, setLiveTips] = usePersistentState<{ tips: Tip[], total: number }>(
        `event-${event.id}-live-tips`,
        {
            tips: event.tips ?? [],
            total: event.tips?.reduce((total, tip) => total += tip.amount - tip.applicationFee, 0.00) ?? 0.00
        }
    );

    useEffect(() => {
        const signalRService = new SignalRService('/hubs/TipHub', appContext.auth.token);

        signalRService.start(async hub => {
            await hub.invoke('addToTipGroup', event.id);
        });

        signalRService.subscribeToEvent('groupUpdate', (message: string) => {
            console.log(message);
        });

        signalRService.subscribeToEvent('tipReceived', (tip: Tip) => {
            setLiveTips(prevState => ({
                tips: [...prevState.tips, tip],
                total: [...prevState.tips, tip].reduce((total, t) => total += (t.amount - t.applicationFee), 0.00)
            }));
        });

        return () => {
            signalRService.stop();
        }
    }, []);

    function isActiveEvent(): boolean {
        const start: Date = new Date(event.startDate);
        start.setHours(start.getHours() - 2)
        const end: Date = new Date(event.endDate);
        end.setHours(end.getHours() + 4);
        const now: number = Date.now();

        return +start <= now && +end >= now;
    }

    const handleEventClick = (e: React.MouseEvent<HTMLDivElement>) => {
        let eventNotes = e.currentTarget.querySelector(".event-notes") as HTMLDivElement;
        let eventNoteEditIcon = e.currentTarget.querySelector(".edit-icon") as HTMLDivElement;
        let eventNoteQrIcon = e.currentTarget.querySelector(".qr-icon") as HTMLDivElement;
        let activeIcon = e.currentTarget.querySelector('.active-icon') as HTMLDivElement;

        if (activeIcon) {
            activeIcon.classList.toggle('active-icon-close');
            activeIcon.classList.toggle('active-icon-open');
        }

        eventNotes.classList.toggle("event-notes-hidden");
        eventNotes.classList.toggle("event-notes-visible");

        eventNoteEditIcon?.classList.toggle("edit-icon-hidden");
        eventNoteEditIcon?.classList.toggle("edit-icon-visible");

        eventNoteQrIcon?.classList.toggle("qr-icon-hidden");
        eventNoteQrIcon?.classList.toggle("qr-icon-visible");
    }

    const handleEditIconClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleEditClick({ type: "EDIT_EVENT", editingEvent: event });
    }

    const handleQRIconClick = async (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        let res: Response = await ApiClient.GetQRCode(event.id!, appContext.auth.token);

        if (res.ok) {
            const qrCodeUrl: string = URL.createObjectURL(await res.blob());
            setQrOpen(true);
            setEventQRCode(qrCodeUrl);
        } else if (res.status === 402) {
            throwSubscriptionError(await res.json());
        } else {
            console.error(await res.json());
        }
    }

    const formatDate = (date: Date | string): string => {
        if (typeof (date) === "string") {
            date = new Date(date);
        }

        return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
    }

    return (
        <div className="event">
            <Card
                onClick={e => handleEventClick(e)}
                variant="outlined"
                sx={{
                    maxWidth: "95vw",
                    margin: "auto",
                    textAlign: "left",
                    marginBottom: "10px"
                }}
            >
                <CardContent style={{ position: "relative" }}>
                    {
                        activeEvent && (
                            <>
                                <div className='active-icon pulsing active-icon-close' />

                            </>
                        )
                    }
                    <div onClick={e => handleQRIconClick(e)} className="qr-icon qr-icon-hidden">
                        <QrCode2Icon />
                    </div>
                    <div onClick={(e) => handleEditIconClick(e)} className="edit-icon edit-icon-hidden">
                        <EditIcon />
                    </div>
                    <Typography variant="h6" component="div" color="text.secondary">
                        {event.eventTitle}
                    </Typography>
                    <Typography variant="body2">
                        {formatDate(event.startDate)} - {formatDate(event.endDate)}
                    </Typography>
                    <Typography variant="body2">
                        {event.eventAddress}
                    </Typography>
                    <div className="event-notes event-notes-hidden">
                        <Typography variant="body1" color="text.secondary" className="title">
                            Location:
                        </Typography>
                        <Typography variant="body2">
                            {locations?.find(l => l.id === event.locationId)?.locationName}
                        </Typography>
                        <Typography variant="body1" color="text.secondary" className="title">
                            Estimated Total Guests:
                        </Typography>
                        <Typography variant="body2">
                            {!event.estimatedTotalGuests ? 'Unknown' : event.estimatedTotalGuests}
                        </Typography>
                        <Typography variant="body1" color="text.secondary" className="title">
                            Scheduled employees:
                        </Typography>
                        <Box className='scheduled-employees'>
                            {
                                event.scheduledEmployees?.map((employee) => {
                                    employee = employee as Employee;
                                    return (
                                        <Chip
                                            className='name-chip'
                                            label={`${employee.firstName} ${employee.lastName}`}
                                            key={employee.id}
                                            variant="outlined"
                                        />
                                    );
                                })
                            }
                        </Box>
                        {event.eventNotes && (
                            <>
                                <Typography variant="body1" color="text.secondary" className="title">
                                    Event notes:
                                </Typography>
                                <Typography variant="body2">
                                    {event.eventNotes}
                                </Typography>
                            </>
                        )}
                    </div>
                    <Dialog onClick={() => setQrOpen(false)} open={qrOpen} fullScreen={fullScreen}>
                        <DialogContent>
                            <Typography variant="h3" color="text.secondary" textAlign="center">
                                {event.eventTitle}
                            </Typography>
                            <div style={{
                                background: `center / contain no-repeat url("${eventQRCode}") `,
                                height: "80%",
                                width: "100%"
                            }}
                            />
                        </DialogContent>
                    </Dialog>
                    {
                        liveTips.tips.length > 0 &&
                        <div className="event-tip-total">{TipService.FormatTip(liveTips.total!)}</div>
                    }
                </CardContent>
            </Card>
        </div>
    );
}

export default Event;