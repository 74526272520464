import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from '@mui/material/Avatar';
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useQuery } from '@tanstack/react-query';
import { Dispatch, FC, SetStateAction, useContext, useRef, useState } from "react";
import AppContext, { AccountType, UpdateAccountDetails } from "../../context/AppContext";
import { Employee } from "../../models/Employee";
import { Exception, HttpPostException } from "../../models/ExceptionTypes";
import { InputError } from "../../models/InputError";
import ApiClient from "../../services/ApiClient";
import { getInputValuesByName } from "../../utils/Helpers";
import { getAddressData } from "../AddressForm/AddressForm";
import Disclaimer from "../Disclaimer/Disclaimer";
import PlaidVerification from "../PlaidVerification/PlaidVerification";
import StateSelect from "../StateSelect/StateSelect";


const EmployeeDetails: FC<{ emailPassword: { email: string, password: string }, setInEffect: Dispatch<SetStateAction<boolean>> }> =
    ({ emailPassword, setInEffect }) => {
        const [appContext, dispatch] = useContext(AppContext);
        const [formStep, setFormStep] = useState<number>(0);
        const [errorMessage, setErrorMessage] = useState<string>("");
        const [formState, setFormState] = useState<{ [key: string]: InputError }>({});
        const profilePicRef = useRef<HTMLInputElement | null>(null);
        const [profilePic, setProfilePic] = useState<string>('');

        const { data, isLoading, isError } = useQuery({
            queryKey: ['business-names'],
            initialData: { options: [] as string[] },
            queryFn: async () => {
                const res = await ApiClient.GetAsync("/api/business/all/names", appContext?.auth?.token);

                if (!res.ok) {
                    throw new Exception('Error GETing business names');
                }

                return Promise.resolve({ options: await res.json() });
            }
        });

        const handleSaveAndContinueClick = async () => {
            setInEffect(true);

            const [validForm, formErrors] = validateEmployeeForm();

            if (validForm) {
                const { employee, token, expiration } = await createEmployee();
                const uploadedPic = profilePicRef.current && profilePicRef.current.files && profilePicRef.current.files[0] ? profilePicRef.current.files[0] : null;

                employee.profilePic = await uploadProfilePic(employee.id, uploadedPic, token);

                dispatch(UpdateAccountDetails({
                    accountType: AccountType.EMPLOYEE,
                    user: employee,
                    token,
                    expiration
                }));

                setFormStep(1);
            } else {
                setErrorMessage("Please fill out the required fields");
                setFormState(formErrors);
            }

            setInEffect(false);
        }

        const uploadProfilePic = async (employeeId: string, pic: File | null, token: string): Promise<string> => {
            if (pic) {
                let formData = new FormData();
                formData.append('profilePicture', pic);

                const res = await fetch(`/api/employees/profilePicture`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    },
                    body: formData
                });

                if (!res.ok) {
                    if (res.status === 400)
                        throw new HttpPostException(`Model state invalid`);
                    else if (res.status === 401)
                        throw new HttpPostException(`Cannot upload logo. Unauthorized.`);
                    else if (res.status === 500)
                        throw new HttpPostException(`Internal server error: ${await res.json()}`);
                }

                return await res.json();
            } else {
                return '';
            }
        }

        // TO-DO: handle employee registration errors
        // and show error messages appropriately 
        const createEmployee = async (): Promise<{ employee: Employee, token: string, expiration: string, plaidLinkToken: string }> => {
            const { firstName, lastName, companyName, phoneNumber } = getInputValuesByName(["firstName", "lastName", "companyName", "phoneNumber"]);

            const employeeData = {
                firstName,
                lastName,
                companyName,
                employeeDetails: {
                    emailAddress: emailPassword?.email,
                    password: emailPassword?.password,
                    phoneNumber
                },
                address: getAddressData()
            };

            let res = await ApiClient.PostAsync("/api/Employees", employeeData, appContext.auth.token);

            if (!res.ok) {
                if (res.status === 500)
                    throw new HttpPostException("There was an error creating a new employee. Please validate all details");
                else if (res.status === 400)
                    throw new HttpPostException("Could not validate all employee details. Please validate all details are correct and try again");
            }

            return await res.json();
        }

        const validateEmployeeForm = (): [boolean, { [key: string]: InputError }] => {
            const { firstName, lastName, companyName, phoneNumber } = getInputValuesByName(["firstName", "lastName", "companyName", "phoneNumber"]);
            const { street, city, state, zip } = getAddressData("employeeAddress");

            let employeeFormState: { [key: string]: InputError } = {
                firstName: {
                    helperText: !firstName ? "First Name is a required field" : "",
                    error: !(!!firstName)
                },
                lastName: {
                    helperText: !lastName ? "Last Name is a required field" : "",
                    error: !(!!lastName)
                },
                companyName: {
                    helperText: !companyName ? "Company Name is a required field" : "",
                    error: !(!!companyName)
                },
                phoneNumber: {
                    helperText: !phoneNumber ? "Phone Number is a required field" : "",
                    error: !(!!phoneNumber)
                },
                address: {
                    helperText: !street ? "Street address is a required field" : "",
                    error: !(!!street)
                },
                city: {
                    helperText: !city ? "City is a required field" : "",
                    error: !(!!city)
                },
                state: {
                    helperText: !state ? "State is a required field" : "",
                    error: !(!!state)
                },
                zipCode: {
                    helperText: !zip ? "Zip Code is a required field" : "",
                    error: !(!!zip)
                }
            }

            return [!Object.values(employeeFormState).some(inputError => inputError.error === true), employeeFormState];
        }

        const resetFormError = (inputName: string): void => {
            formState[inputName] = {
                helperText: "",
                error: false
            }

            setFormState({ ...formState });
        }

        const onImageUpload = (): void => {
            const image: File | null | undefined = profilePicRef.current?.files?.item(0);

            if (image) setProfilePic(URL.createObjectURL(image));
        }

        if (isLoading || isError) return <></>;

        return (
            <>
                {formStep === 0 ? (
                    <>
                        <h3 className="details-header">PERSONAL INFORMATION</h3>
                        {errorMessage && (
                            <div style={{ width: "95vw", margin: "auto", marginTop: "15px" }}>
                                <Alert severity="error" variant="outlined">{errorMessage}</Alert>
                            </div>
                        )}
                        <TextField
                            label="First Name"
                            name="firstName"
                            type="text"
                            variant="outlined"
                            size="medium"
                            required={true}
                            error={formState?.firstName?.error}
                            helperText={formState?.firstName?.helperText}
                            onChange={e => e.currentTarget.value && formState?.firstName?.error && resetFormError(e.currentTarget.name)}
                        />
                        <TextField
                            label="Last Name"
                            name="lastName"
                            type="text"
                            variant="outlined"
                            size="medium"
                            required={true}
                            error={formState?.lastName?.error}
                            helperText={formState?.lastName?.helperText}
                            onChange={e => e.currentTarget.value && formState?.lastName?.error && resetFormError(e.currentTarget.name)}
                        />
                        <Autocomplete
                            {...data}
                            autoComplete={true}
                            includeInputInList={true}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Company Name'
                                    name='companyName'
                                    type='text'
                                    variant='outlined'
                                    size='medium'
                                    required={true}
                                    error={formState?.companyName?.error}
                                    helperText={formState?.companyName?.helperText}
                                    onChange={e => e.currentTarget.value && formState?.companyName?.error && resetFormError(e.currentTarget.name)}
                                />
                            )}
                        />
                        <TextField
                            label="Phone Number"
                            name="phoneNumber"
                            type="text"
                            variant="outlined"
                            size="medium"
                            required={true}
                            inputProps={{
                                inputMode: "numeric"
                            }}
                            error={formState?.phoneNumber?.error}
                            helperText={formState?.phoneNumber?.helperText}
                            onChange={e => e.currentTarget.value && formState?.phoneNumber?.error && resetFormError(e.currentTarget.name)}
                        />
                        <Stack id="employeeAddress" spacing={2}>
                            <TextField
                                label="Address"
                                name="address"
                                type="text"
                                variant="outlined"
                                size="medium"
                                required={true}
                                error={formState?.address?.error}
                                helperText={formState?.address?.helperText}
                                onChange={e => e.currentTarget.value && formState?.address?.error && resetFormError(e.currentTarget.name)}
                            />
                            <TextField
                                label="City"
                                name="city"
                                type="text"
                                variant="outlined"
                                size="medium"
                                required={true}
                                error={formState?.city?.error}
                                helperText={formState?.city?.helperText}
                                onChange={e => e.currentTarget.value && formState?.city?.error && resetFormError(e.currentTarget.name)}
                            />
                            <div>
                                <StateSelect errorInfo={formState} updateError={setFormState} />
                                <TextField
                                    label="Zip Code"
                                    name="zipCode"
                                    type="text"
                                    variant="outlined"
                                    size="medium"
                                    required={true}
                                    style={{ width: "58vw" }}
                                    inputProps={{
                                        inputMode: "numeric"
                                    }}
                                    error={formState?.zipCode?.error}
                                    helperText={formState?.zipCode?.helperText}
                                    onChange={e => e.currentTarget.value && formState?.zipCode?.error && resetFormError(e.currentTarget.name)}
                                />
                            </div>
                        </Stack>
                        <div style={{ display: 'flex' }}>
                            <input
                                ref={profilePicRef}
                                type='file'
                                id='employee-pic'
                                accept='image/*'
                                style={{ display: 'none' }}
                                onChange={onImageUpload}
                            />
                            <label htmlFor='employee-pic' style={{ width: '65%' }}>
                                <Fab variant="extended" sx={{ width: '100%' }} onClick={() => profilePicRef.current?.click()}>
                                    <CloudUploadIcon sx={{ mr: 1 }} />
                                    UPLOAD PROFILE PIC
                                </Fab>
                            </label>
                            {
                                profilePic && <Avatar
                                    alt="Business logo"
                                    src={profilePic}
                                    sx={{ width: 48, height: 48, marginLeft: '5%' }}
                                />
                            }
                        </div>
                        <Disclaimer>
                            <p>
                                TipSplit uses Plaid to connect to your bank account. Click the button below to securely connect to your financial institution.
                            </p>
                        </Disclaimer>
                        <Button
                            className="save-continue-button"
                            variant="contained"
                            onClick={handleSaveAndContinueClick}
                        >
                            CONNECT BANK ACCOUNT
                        </Button>
                    </>
                ) : formStep === 1 ? (
                    <PlaidVerification />
                ) : (<div>Whoops, you shouldn't be here!</div>)}
            </>
        );
    }

export default EmployeeDetails;