import EventIcon from '@mui/icons-material/Event';
import GroupIcon from '@mui/icons-material/Group';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import { Box, CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { QueryFunction, QueryKey, useQuery } from '@tanstack/react-query';
import { FC, SyntheticEvent, useContext, useState } from "react";
import { Link } from 'react-router-dom';
import AppContext from "../../context/AppContext";
import { UserRoles } from '../../models/BasicUserDetails';
import { Business, BusinessLocation } from "../../models/Business";
import { EmployeeDetails } from '../../models/Employee';
import { Exception } from '../../models/ExceptionTypes';
import ApiClient from '../../services/ApiClient';
import { formatPhoneNumber } from '../../utils/Helpers';
import Employees from '../Employees/Employees';
import Locations from '../Locations/Locations';
import ProfileSection, { SectionDetail } from '../ProfileSection/ProfileSection';
import Schedule from '../Schedule/Schedule';
import IOSPrompt from '../iOSPrompt/iOSPrompt';
import "./BusinessProfile.css";
import CancelSubscription from './CancelSubscription';
import UpdateSubscription from './UpdateSubscription';

const BusinessProfile: FC = () => {
    const [appContext] = useContext(AppContext);
    const business = appContext.user as Business;
    const [selectedTab, setSelectedTab] = useState(0);

    const { data: employees, isLoading: isEmployeesLoading, isError: isEmployeesError } = useQuery<EmployeeDetails[]>({
        queryKey: ['business-employees'],
        queryFn: async () => {
            const res = await ApiClient.GetAsync('/api/business/employees', appContext.auth.token);

            if (!res.ok) {
                throw new Exception('There was an error retrieving business employees.')
            }

            return res.json();
        }
    });

    const { data: locations, isLoading: isLocationsLoading, isError: isLocationsError } = useQuery<BusinessLocation[]>({
        queryKey: ['business-locations'],
        queryFn: async () => {
            const res = await ApiClient.GetAsync('/api/business/locations', appContext.auth.token);

            if (!res.ok) {
                throw new Exception('There was an error retrieving business locations');
            }

            return res.json();
        }
    });

    if (isEmployeesLoading || isLocationsLoading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
    );

    if (isEmployeesError || isLocationsError) return <></>;

    return (
        <>
            <div className="business-profile">
                <div className="business-profile-header">
                    <h4 className="business-name">{business.businessName}</h4>
                </div>
                <Avatar className='business-logo' src={business?.businessLogo} />
                <div style={{ marginTop: "15px" }}>
                    {selectedTab === 0 ? <Schedule employees={employees} locations={locations} /> :
                        selectedTab === 1 ? <Locations locations={locations} /> :
                            selectedTab === 2 ? <Employees employees={employees} /> :
                                selectedTab === 3 && <BusinessDetails />
                    }
                </div>
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                    <BottomNavigation
                        showLabels={true}
                        value={selectedTab}
                        onChange={(event, newValue) => {
                            setSelectedTab(newValue);
                        }}
                    >
                        <BottomNavigationAction label="Schedule" icon={<EventIcon />} />
                        <BottomNavigationAction label="Locations" icon={<StoreIcon />} />
                        <BottomNavigationAction label="Employees" icon={<GroupIcon />} />
                        <BottomNavigationAction label="Profile" icon={<PersonIcon />} />
                    </BottomNavigation>
                </Paper>
            </div>
            <IOSPrompt promptName='add-to-home-screen' visibleDuration={4000} />
        </>
    )
}

const BusinessDetails: FC = () => {
    const [appContext] = useContext(AppContext);
    const business = appContext.user as Business;
    const [portalError, setPortalError] = useState<boolean>(false);
    const [editingContactInfo, setEditingContactInfo] = useState<boolean>(false);

    const getSubscription: QueryFunction<{ [key: string]: any }, QueryKey> = async ({ queryKey }) => {
        const [, stripeSubscriptionId] = queryKey;
        let res = await ApiClient.GetAsync(`/api/stripe/subscription/${stripeSubscriptionId}`, appContext.auth.token);

        if (!res.ok) {
            throw new Exception(`Error retrieving subscription ${stripeSubscriptionId}`);
        }

        return res.json();
    }

    const { data: stripeSubscription, isLoading, isError } = useQuery<{ [key: string]: any }>({
        queryKey: ['subscriptions', (appContext.user as Business).stripeSubscriptionId],
        queryFn: getSubscription
    });

    const handleOpenStripeClick = async () => {
        let res = await ApiClient.GetAsync(`/api/stripe/createPortalSession/${business.stripeAccountId}`, appContext.auth.token);

        if (res.ok) {
            let portalUrl: string | null = res.headers.get('Location');

            if (portalUrl)
                window.location.href = portalUrl
            else
                console.error('No location header set in response object');
        } else {
            setPortalError(true);
        }
    }

    const handleSnackbarClose = (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        setPortalError(false);
    }

    const handleContactOpen = () => {
        setEditingContactInfo(true);
    }

    const handleContactClose = () => {
        setEditingContactInfo(false);
    }

    const updateContactInfo = async (): Promise<void> => {
        const updatedEmailAddress: string = (document.querySelector("#emailAddress") as HTMLInputElement).value;
        const updatedPhoneNumber: string = (document.querySelector("#phoneNumber") as HTMLInputElement).value;

        let res = await ApiClient.PostAsync('/api/business/contact', {
            id: business.id,
            emailAddress: updatedEmailAddress,
            phoneNumber: updatedPhoneNumber
        }, appContext.auth.token);

        if (!res.ok) {
            // show an error
        } else {
            // update email address and phone number in session storage
            setEditingContactInfo(false);
        }
    }

    const getMaximumAllowedEmployees = (subscriptionSize: string): string => {
        const employeeSizeMap: { [key: string]: string } = {
            "SMALL_PRICE_LOOKUP_KEY": "10",
            "MEDIUM_PRICE_LOOKUP_KEY": "25",
            "LARGE_PRICE_LOOKUP_KEY": "50"
        }

        return employeeSizeMap[subscriptionSize];
    }

    return (
        <div style={{ marginBottom: '75px' }}>
            {
                business && (
                    <>
                        {
                            !isLoading && !stripeSubscription && (
                                <Link to='/app/subscription' style={{ textDecoration: 'none' }}>
                                    <Alert severity="error" sx={{ width: '95vw', margin: 'auto', marginBottom: '15px' }}>
                                        You have not completed your Stripe subscription setup. Please click here to complete subscription setup.
                                    </Alert>
                                </Link>
                            )
                        }

                        <ProfileSection sectionTitle='Basic details'>
                            <SectionDetail label='TipSplit ID' value={business.id} />
                            <SectionDetail label='Business name' value={business.businessName} />
                            <SectionDetail label='Role' value={UserRoles[business.businessDetails.role]} />
                        </ProfileSection>

                        <div style={{ position: 'relative' }}>
                            <ProfileSection sectionTitle='Contact info'>
                                {/* <EditIcon
                                    onClick={handleContactOpen}
                                    sx={{
                                        fontSize: 'medium',
                                        position: 'absolute',
                                        right: '5%',
                                        top: '3%'
                                    }}
                                /> */}
                                <SectionDetail label='Email address' value={business.businessDetails.emailAddress} />
                                <SectionDetail label='Phone number' value={formatPhoneNumber(business.businessDetails.phoneNumber)} />
                            </ProfileSection>
                        </div>

                        <Dialog open={editingContactInfo} onClose={handleContactClose}>
                            <DialogTitle>Update Contact Info</DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="emailAddress"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                    defaultValue={business.businessDetails.emailAddress}
                                />
                                <TextField
                                    margin="dense"
                                    id="phoneNumber"
                                    label="Phone number"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    inputMode='numeric'
                                    defaultValue={business.businessDetails.phoneNumber}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleContactClose}>Cancel</Button>
                                <Button onClick={handleContactClose}>Update</Button>
                            </DialogActions>
                        </Dialog>

                        {
                            isLoading || isError ? (<></>) : stripeSubscription && (
                                <div style={{ position: 'relative' }}>
                                    <ProfileSection sectionTitle='Stripe subscription info'>
                                        <OpenInNewIcon
                                            onClick={handleOpenStripeClick}
                                            sx={{
                                                fontSize: 'small',
                                                position: 'absolute',
                                                right: '5%',
                                                top: '2%'
                                            }}
                                        />
                                        <SectionDetail label='Customer ID' value={business.stripeAccountId} />
                                        <SectionDetail label='Subscription ID' value={business.stripeSubscriptionId} />
                                        <SectionDetail label='Maximum number of allowed employees' value={getMaximumAllowedEmployees(stripeSubscription?.items[0]?.price?.lookupKey!)} />
                                        <SectionDetail label='Subscription status' value={business.paymentStatus ? 'Active' : 'Inactive'} />
                                        <SectionDetail label='Last payment date' value={business.lastPaymentReceived ? new Date(stripeSubscription.currentPeriodStart).toLocaleDateString() : 'N/A'} />
                                        <SectionDetail label='Next payment date' value={stripeSubscription?.currentPeriodEnd ? new Date(stripeSubscription.currentPeriodEnd).toLocaleDateString() : "N/A"} />
                                    </ProfileSection>

                                    <UpdateSubscription subscription={stripeSubscription} />
                                    <CancelSubscription />
                                </div>
                            )
                        }
                    </>
                )
            }

            {
                portalError && (
                    <Snackbar
                        open={portalError}
                        autoHideDuration={2500}
                        onClose={handleSnackbarClose}
                    >
                        <Alert elevation={6} severity='error' variant='filled' sx={{ width: '100%' }}>
                            Whoops, there was a problem! Please try again.
                        </Alert>
                    </Snackbar>
                )
            }
        </div>
    )
}

export default BusinessProfile;