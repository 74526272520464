import { SelectChangeEvent } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ChangeEvent, Dispatch, FC, SetStateAction, useContext, useReducer, useState } from "react";
import AppContext, { EditLocation } from "../../context/AppContext";
import { BusinessLocation, LocationReducerPayload } from "../../models/Business";
import { InputError } from '../../models/InputError';
import ApiClient from '../../services/ApiClient';
import { getInputValueByName } from '../../utils/Helpers';
import { getAddressData } from '../AddressForm/AddressForm';
import { StateSelectV2 } from '../StateSelect/StateSelect';
import { resetLocationFormError, validateLocationDetails } from './LocationHelpers';

const EditLocationForm: FC<{ location: BusinessLocation, editLocationOpen: boolean, setEditLocationOpen: Dispatch<SetStateAction<boolean>> }>
    = ({ location, editLocationOpen, setEditLocationOpen }) => {
        const [appContext, dispatch] = useContext(AppContext);
        const [locationFormErrorState, setLocationFormErrorState] = useState<{ [key: string]: InputError }>({});
        const [locationFormErrorMessage, setLocationFormErrorMessage] = useState<string>("");
        const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

        const [locationFormState, formDispatch] = useReducer((state: BusinessLocation, action: LocationReducerPayload) => {
            switch (action.type) {
                case "LOCATION_NAME":
                    return {
                        ...state,
                        locationName: action.locationName as string
                    };

                case "ADDRESS_STREET":
                    return {
                        ...state,
                        address: {
                            ...state.address,
                            street: action.address as string
                        }
                    }

                case "ADDRESS_CITY":
                    return {
                        ...state,
                        address: {
                            ...state.address,
                            city: action.city as string
                        }
                    }

                case "ADDRESS_STATE":
                    return {
                        ...state,
                        address: {
                            ...state.address,
                            state: action.state as string
                        }
                    }

                case "ADDRESS_ZIP":
                    return {
                        ...state,
                        address: {
                            ...state.address,
                            zip: action.zip as string
                        }
                    }

                default:
                    return state;
            }
        }, location);

        const updateLocation = async () => {
            const [validForm, locationFormErrors] = validateLocationDetails();

            if (validForm) {
                const locationName = getInputValueByName("locationName");

                let updatedLocationDetails: BusinessLocation = {
                    id: location.id,
                    businessId: location.businessId,
                    locationName,
                    address: getAddressData("locationAddress"),
                    events: location.events,
                    payedTipouts: location.payedTipouts,
                    receivedTips: location.receivedTips
                };

                let res = await ApiClient.PutAsync(`/api/locations/${location.id}`, updatedLocationDetails, appContext.auth.token);

                if (!res.ok) {
                    setLocationFormErrorMessage(`There was an error updating location ${location.locationName}. Please try again later.`);
                } else {
                    dispatch(EditLocation(updatedLocationDetails));
                    setEditLocationOpen(false);
                }
            } else {
                setLocationFormErrorMessage('Please fill out the required fields');
                setLocationFormErrorState(locationFormErrors);
            }
        }

        const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, updateType: string) => {
            if (e.target.value && locationFormErrorState[e.target.name]?.error)
                resetLocationFormError(e.target.name, locationFormErrorState, setLocationFormErrorState);

            formDispatch({ type: updateType, [e.target.name]: e.target.value });
        }

        const handleStateChange = (e: SelectChangeEvent<string>) => {
            if (e.target.value && locationFormErrorState[e.target.name]?.error)
                resetLocationFormError(e.target.name, locationFormErrorState, setLocationFormErrorState);

            formDispatch({ type: "ADDRESS_STATE", state: e.target.value });
        }

        return (
            <Dialog
                onClose={() => setEditLocationOpen(false)}
                open={editLocationOpen}
                fullScreen={fullScreen}
            >
                <DialogContent>
                    <Stack spacing={2} style={{ display: 'flex' }}>
                        <h3 className="details-header">UPDATE LOCATION</h3>
                        {locationFormErrorMessage && (
                            <div style={{ width: "95vw", margin: "auto", marginTop: "15px" }}>
                                <Alert severity="error" variant="outlined">{locationFormErrorMessage}</Alert>
                            </div>
                        )}
                        <TextField
                            label="Location Name"
                            name="locationName"
                            type="text"
                            variant="outlined"
                            size="medium"
                            value={locationFormState.locationName}
                            required={true}
                            error={locationFormErrorState?.locationName?.error}
                            helperText={locationFormErrorState?.locationName?.helperText}
                            onChange={e => handleChange(e, "LOCATION_NAME")}
                        />
                        <Stack id="locationAddress" spacing={2}>
                            <TextField
                                label="Address"
                                name="address"
                                type="text"
                                variant="outlined"
                                size="medium"
                                value={locationFormState.address.street}
                                required={true}
                                error={locationFormErrorState?.address?.error}
                                helperText={locationFormErrorState?.address?.helperText}
                                onChange={e => handleChange(e, "ADDRESS_STREET")}
                            />
                            <TextField
                                label="City"
                                name="city"
                                type="text"
                                variant="outlined"
                                size="medium"
                                value={locationFormState.address.city}
                                required={true}
                                error={locationFormErrorState?.city?.error}
                                helperText={locationFormErrorState?.city?.helperText}
                                onChange={e => handleChange(e, "ADDRESS_CITY")}
                            />
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <StateSelectV2
                                    value={locationFormState.address.state}
                                    error={locationFormErrorState.state?.error}
                                    onChange={handleStateChange}
                                />
                                <TextField
                                    label="Zip Code"
                                    name="zipCode"
                                    type="text"
                                    variant="outlined"
                                    size="medium"
                                    value={locationFormState.address.zip}
                                    required={true}
                                    style={{ width: "58vw" }}
                                    inputProps={{
                                        inputMode: "numeric"
                                    }}
                                    error={locationFormErrorState?.zipCode?.error}
                                    helperText={locationFormErrorState?.zipCode?.helperText}
                                    onChange={e => handleChange(e, "ADDRESS_ZIP")}
                                />
                            </div>
                        </Stack>
                    </Stack>
                    <DialogActions sx={{
                        padding: 0,
                        marginTop: 2
                    }}>
                        <Button onClick={() => setEditLocationOpen(false)} variant='contained'>Cancel</Button>
                        <Button onClick={updateLocation} variant='contained'>Update</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }

export default EditLocationForm;