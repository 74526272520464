import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import { TouchEvent } from 'react';
import './Schedule.css';
import { ScheduleDay, ScheduleReducerPayload, ScheduleState } from './ScheduleReducer';

interface ICalendarProps {
    scheduleState: ScheduleState;
    dispatch: React.Dispatch<ScheduleReducerPayload>;
    today: Date;
}

const Calendar: React.FC<ICalendarProps> = ({ scheduleState, dispatch, today }) => {
    const MONTHS: string[] = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];

    const handleMonthNavigation = (monthsToAdd: number): void => {
        let newSelectedMonth = new Date(+scheduleState.selectedMonth);
        newSelectedMonth.setMonth(newSelectedMonth.getMonth() + monthsToAdd);
        dispatch({ type: 'SELECTED_MONTH', selectedMonth: newSelectedMonth });
    }

    const handleGridTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
        const { touchStart } = scheduleState;
        const touchEnd = e.changedTouches[0].clientX;

        if (touchStart - touchEnd > 75)
            handleMonthNavigation(1);

        if (touchStart - touchEnd < -75)
            handleMonthNavigation(-1);
    }

    const handleDayClick = (day: ScheduleDay) => {
        let shownEventNotes = document.querySelectorAll<HTMLDivElement>('.event-notes');
        let editIcons = document.querySelectorAll<HTMLDivElement>('.edit-icon');
        let qrIcons = document.querySelectorAll<HTMLDivElement>('.qr-icon');

        shownEventNotes.forEach(eventNote => {
            eventNote.classList.value = 'event-notes event-notes-hidden';
        });

        editIcons.forEach(editIcon => {
            editIcon.classList.value = 'edit-icon edit-icon-hidden';
        });

        qrIcons.forEach(qrIcon => {
            qrIcon.classList.value = 'qr-icon qr-icon-hidden';
        });

        dispatch({ type: 'SELECTED_DAY', selectedDay: day.date });
    }

    return (
        <>
            <div className='decrease-month' onClick={() => handleMonthNavigation(-1)}>
                <RemoveIcon />
            </div>
            <h3 className='month'>
                {MONTHS[scheduleState.selectedMonth.getMonth()]} {scheduleState.selectedMonth.getFullYear()}
            </h3>
            <div className='increase-month' onClick={() => handleMonthNavigation(1)}>
                <AddIcon />
            </div>
            <div className='go-to-today'>
                <Button variant='contained' onClick={() => dispatch({ type: 'GO_TO_TODAY', selectedMonth: today, selectedDay: today })}>Go To Today</Button>
            </div>
            {/* Calendar grid */}
            <div
                className='calendar-grid'
                onTouchStart={e => dispatch({ type: 'TOUCH_START', touchStart: e.targetTouches[0].clientX })}
                onTouchEnd={e => handleGridTouchEnd(e)}
            >
                {
                    scheduleState.calendarDays.map((day, i) => {
                        let className: string = day.selected ? 'selected' : '';
                        className += day.events.length > 0 ? ' hasEvents' : '';
                        return (
                            <div className='day' onClick={() => handleDayClick(day)} key={`day-${i}`}>
                                <span className={className}>{day.dayOfMonth}</span>
                            </div>
                        );
                    })
                }
                <Fab
                    className='add-event'
                    size='large'
                    color='primary'
                    aria-label='Add event'
                    onClick={() => dispatch({ type: 'NEW_EVENT_OPEN' })}
                >
                    <AddIcon />
                </Fab>
            </div>
        </>
    );
}

export default Calendar;