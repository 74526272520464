import { Input, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import AppContext from "../../context/AppContext";
import ApiClient from "../../services/ApiClient";
import "./TipAmountForm.css";

export interface ITipInfo {
    tipAmount: string,
    processingFee: string,
    total: string,
    eventId: string
}

export interface ITipAmountForm {
    setTipInfo: React.Dispatch<React.SetStateAction<ITipInfo | null>>
}

const TipAmountForm: React.FC<ITipAmountForm> = ({ setTipInfo }) => {
    const [appContext] = useContext(AppContext);
    const params = useParams<{ id: string }>()
    const [tipAmount, setTipAmount] = useState("");
    const [tipError, setTipError] = useState<boolean>(false);
    const [chips, setChips] = useState([
        {
            amount: "$5.00",
            selected: false
        },
        {
            amount: "$10.00",
            selected: false
        },
        {
            amount: "$20.00",
            selected: false
        },
        {
            amount: "$30.00",
            selected: false
        }
    ]);

    const onChipClick = (index: number) => {
        const currentlySelectedTipChip = chips.find(c => c.selected);

        if (currentlySelectedTipChip)
            currentlySelectedTipChip.selected = false;

        chips[index].selected = true;
        setChips([...chips]);
        setTipAmount(chips[index].amount.substring(1));
    }

    const onEnterPaymentInfoClick = async () => {
        let res = await ApiClient.PostAsync("/api/Tips/GetTipAmount", { tipAmount: parseFloat(tipAmount) }, appContext.auth.token);

        if (!res.ok) {
            setTipError(true);
        } else {
            let tipDetails = await res.json();
            setTipInfo({ ...tipDetails, eventId: params.id });
        }
    }

    return (
        <div className="tip-amount-form">
            <Input
                id="tip"
                value={tipAmount}
                onChange={e => setTipAmount(e.target.value)}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                inputProps={{
                    inputMode: "decimal"
                }}
                error={tipError}
            />
            <Button
                variant="contained"
                onClick={onEnterPaymentInfoClick}
                className="button"
            >
                ENTER PAYMENT INFORMATION
            </Button>
            <Stack className="chips" direction="row" spacing={1}>
                {chips.map((chip, i) => (
                    <Chip
                        key={`tip-chip-${i}`}
                        label={chip.amount}
                        color="primary"
                        variant={chip.selected ? "filled" : "outlined"}
                        onClick={() => onChipClick(i)}
                    />
                ))}
            </Stack>
        </div>
    );
}

export default TipAmountForm;