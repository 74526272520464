import { Context, createContext, Dispatch } from "react";
import Stripe from "stripe";
import { Business, BusinessLocation } from "../models/Business";
import { Employee } from "../models/Employee";
import { TSEvent } from "../models/TSEvent";

export type DispatchPayload = {
    type: string;
    [key: string]: Employee | Business | { token: string } | AccountType | string | TSEvent | number | Stripe.Account | BusinessLocation | undefined;
}

export enum AccountType {
    GUEST,
    EMPLOYEE,
    BUSINESS
}

export type AppContextSchema = {
    accountType: AccountType,
    auth: {
        token: string,
        expiration: string,
    },
    user: Employee | Business,
    stripeAccount?: any,
}

export const UPDATE_USER: string = "UPDATE_USER";
export const UPDATE_TOKEN: string = "UPDATE_TOKEN";
export const UPDATE_ACCOUNT_TYPE = "UPDATE_ACCOUNT_TYPE";
export const UPDATE_ACCOUNT_DETAILS = "UPDATE_ACCOUNT_DETAILS";
export const ADD_EVENT = "ADD_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const ADD_LOCATION = "ADD_LOCATION";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";

export const UpdateUser = (user: Employee | Business): DispatchPayload => {
    return {
        type: UPDATE_USER,
        user
    }
}

export const UpdateToken = (token: string, expiration: string): DispatchPayload => {
    return {
        type: UPDATE_TOKEN,
        auth: { token, expiration }
    }
}

export const UpdateEmployeeType = (accountType: AccountType): DispatchPayload => {
    return {
        type: UPDATE_ACCOUNT_TYPE,
        accountType
    };
}

export const UpdateAccountDetails = (details: { accountType: AccountType, user: Employee | Business, stripeAccount?: Stripe.Account, token: string, expiration: string }): DispatchPayload => {
    return {
        type: UPDATE_ACCOUNT_DETAILS,
        user: details.user,
        accountType: details.accountType,
        token: details.token,
        stripeAccount: details.stripeAccount,
        expiration: details.expiration
    };
}

export const AddLocation = (location: BusinessLocation): DispatchPayload => {
    return {
        type: ADD_LOCATION,
        newLocation: location
    };
}

export const EditLocation = (location: BusinessLocation): DispatchPayload => {
    return {
        type: EDIT_LOCATION,
        location
    };
}

export const DeleteLocation = (locationId: string): DispatchPayload => {
    return {
        type: DELETE_LOCATION,
        locationId
    };
}

export const AppContextReducer = (state: AppContextSchema, payload: DispatchPayload): AppContextSchema => {
    switch (payload.type) {
        case UPDATE_USER:
            state.user = { ...(payload.user as Employee | Business) };
            return { ...state };

        case UPDATE_TOKEN:
            state.auth = {
                ...payload.auth as { token: string, expiration: string }
            }
            return { ...state };

        case UPDATE_ACCOUNT_TYPE:
            state.accountType = payload.accountType as AccountType
            return { ...state };

        case UPDATE_ACCOUNT_DETAILS:
            state = {
                user: payload.user as Employee,
                auth: {
                    token: payload.token as string,
                    expiration: payload.expiration as string
                },
                accountType: payload.accountType as AccountType,
                stripeAccount: payload?.stripeAccount as Stripe.Account
            }
            return { ...state };

        case ADD_EVENT:
            (state.user as Business).locations
                .find(l => l.id === (payload.newEvent as TSEvent).locationId)?.events
                .push(payload.newEvent as TSEvent);

            return { ...state };

        case UPDATE_EVENT:
            const updatedEvent = payload.updatedEvent as TSEvent;
            const locIndex = (state.user as Business).locations.findIndex(l => l.id === updatedEvent.locationId);
            const eventIndex = (state.user as Business).locations[locIndex]?.events.findIndex(e => e.id === updatedEvent.id);

            if (locIndex > -1 && eventIndex > -1)
                (state.user as Business).locations[locIndex].events[eventIndex] = updatedEvent;

            return { ...state }

        case DELETE_EVENT:
            let locationIndex = (state.user as Business).locations.findIndex(l => l.id === payload.locationId as string);

            (state.user as Business).locations[locationIndex].events
                .splice((state.user as Business).locations[locationIndex].events.findIndex(e => e.id === payload.eventId), 1);

            return { ...state };

        case ADD_LOCATION:
            (state.user as Business).locations.push(payload.newLocation as BusinessLocation);

            return { ...state };

        case EDIT_LOCATION:
            (state.user as Business).locations = (state.user as Business).locations
                .map(l => l.id === (payload.location as BusinessLocation).id ? payload.location as BusinessLocation : l);

            return { ...state };

        case DELETE_LOCATION:
            (state.user as Business).locations.splice((state.user as Business).locations.findIndex(l => l.id === payload.locationId), 1);

            return { ...state };

        default:
            return { ...state };
    }
}

const AppContext: Context<[AppContextSchema, Dispatch<DispatchPayload>]> = createContext([{} as AppContextSchema, {} as Dispatch<DispatchPayload>]);

export default AppContext;