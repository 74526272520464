export interface UseCookiesResponse {
    cookies: { [key: string]: string },
    setCookie: (key: string, value: string, expiration?: string) => void;
}

const useCookies = (): UseCookiesResponse => {
    return {
        setCookie: (key: string, value: string, expiration?: string) => {
            let cookieValue: string = `${key}=${value};`;

            if (expiration)
                cookieValue += ` Expires=${expiration};`;

            cookieValue += ` Path=/; SameSite=Strict; Secure;`

            document.cookie = cookieValue;
        },
        cookies: {
            ...document.cookie.split(';').reduce((res, cur) => {
                const cookieKeyValue: string[] = cur.split('=');
                res[cookieKeyValue[0]?.trim()] = cookieKeyValue[1]?.trim();
                return res;
            }, {} as { [key: string]: string })
        }
    };
}

export default useCookies;