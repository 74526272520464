import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FC, FormEvent, useState } from "react";
import { ITipInfo } from "../TipAmountForm/TipAmountForm";
import "./TipCheckoutForm.css";

const TipCheckoutForm: FC<{ tipInfo: ITipInfo }> = ({ tipInfo }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState<string | null | undefined>(null);
    const [submittingPayment, setSubmittingPayment] = useState(false);
    const [paymentElementReady, setPaymentElementReady] = useState(false);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage('');

        if (!stripe || !elements)
            return;

        setSubmittingPayment(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/app/tip-status/${tipInfo.eventId}`
            }
        });

        if (error) {
            if (error.type === "card_error")
                setErrorMessage(error.message);
            else if (error.type !== "validation_error")
                setErrorMessage("An unexpected error occurred.");
        }

        setSubmittingPayment(false);
    }

    return (
        <div className="tip-checkout-form">
            <div className="tip-details">
                <span className="detail"><strong>Tip amount: </strong></span>
                <span>{tipInfo.tipAmount}</span>
                <span className="detail"><strong>Processing fee: </strong></span>
                <span>{tipInfo.processingFee}</span>
            </div>
            <div className="total">
                <span className="detail"><strong>Total: </strong></span>
                <span>{tipInfo.total}</span>
            </div>
            <form id="tip-checkout-form" onSubmit={e => handleSubmit(e)}>
                {
                    errorMessage && (
                        <div id="payment-message" style={{ marginBottom: '10px' }}>
                            <Alert severity='error'>{errorMessage}</Alert>
                        </div>
                    )
                }

                <PaymentElement id="payment-element" onReady={() => setPaymentElementReady(true)} />
                {
                    paymentElementReady && (
                        <Button className="button" type="submit" disabled={submittingPayment || !stripe || !elements} id="submit" variant="contained">
                            <span id="button-text">
                                {submittingPayment ? <div className="spinner" id="spinner"></div> : "SEND TIP"}
                            </span>
                        </Button>
                    )
                }
            </form>
        </div>
    )
}

export default TipCheckoutForm;