import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Dispatch, FC, SetStateAction } from "react";
import { InputError } from "../../models/InputError";
import States from "../../utils/States";

const StateSelect: FC<{ errorInfo?: { [key: string]: InputError }, updateError?: Dispatch<SetStateAction<{ [key: string]: InputError }>> }> = ({ errorInfo, updateError }) => {
    const resetErrorMessage = () => {
        if (errorInfo && updateError !== undefined) {
            errorInfo!["state"] = {
                helperText: "",
                error: false
            }

            updateError({ ...errorInfo });
        }

    }

    return (
        <FormControl sx={{ marginRight: 1, minWidth: "34vw" }}>
            <InputLabel id="state-select-label">State*</InputLabel>
            <Select
                labelId="state-select-label"
                id="state-select"
                label="State"
                size="medium"
                name="state"
                autoComplete="address-level1"
                required={true}
                error={errorInfo?.state?.error}
                onChange={e => e.target.value && errorInfo?.state?.error && updateError !== undefined && resetErrorMessage()}
            >
                {States.map(state => <MenuItem value={state.abbreviation} key={state.abbreviation}>{state.name}</MenuItem>)}
            </Select>
        </FormControl >
    );
}

const StateSelectV2: FC<{ error?: boolean, value: string, onChange: (e: SelectChangeEvent<string>) => void }> = (props) => {
    return (
        <FormControl sx={{ marginRight: 1, minWidth: "34vw" }}>
            <InputLabel id="state-select-label">State*</InputLabel>
            <Select
                labelId="state-select-label"
                id="state-select"
                label="State"
                size="medium"
                name="state"
                autoComplete="address-level1"
                required={true}
                {...props}
            >
                {States.map(state => <MenuItem value={state.abbreviation} key={state.abbreviation}>{state.name}</MenuItem>)}
            </Select>
        </FormControl >
    );
}

export { StateSelectV2 };

export default StateSelect;