import { Dispatch, useEffect, useReducer } from "react";
import { AccountType, AppContextSchema } from "../context/AppContext";
import { Employee } from "../models/Employee";
import SessionService from "../services/SessionService";
import useCookies from "./UseCookies";

const initializeTipSplitState = (cookies: { [key: string]: string }): AppContextSchema => {
    const sessionState = SessionService.Get<AppContextSchema>('TipSplitState');

    return {
        user: sessionState?.user ?? {} as Employee,
        auth: {
            token: cookies.token ?? '',
            expiration: ''
        },
        accountType: sessionState?.accountType ?? AccountType.EMPLOYEE,
        stripeAccount: sessionState?.stripeAccount ?? undefined
    }
}

const useTipSplitState = <P extends { type: string;[key: string]: any }>(reducer: (s: AppContextSchema, p: P) => AppContextSchema): [AppContextSchema, Dispatch<P>] => {
    const { cookies, setCookie } = useCookies();
    const [state, dispatch] = useReducer(reducer, initializeTipSplitState(cookies));

    useEffect(() => {
        SessionService.Set('TipSplitState', {
            user: state.user,
            accountType: state.accountType,
            stripeAccount: state.stripeAccount
        });
    }, [state, state.accountType, state.stripeAccount]);

    useEffect(() => {
        if (cookies.token !== state.auth.token)
            setCookie("token", state.auth.token, state.auth.expiration);
    }, [cookies, setCookie, state.auth.expiration, state.auth.token]);

    return [state, dispatch];
}

export default useTipSplitState;