import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { FC } from 'react';

const ProfileSection: FC<{ sectionTitle: string, children: React.ReactNode }> = ({ sectionTitle, children }) => (
    <Card
        sx={{
            maxWidth: "95vw",
            margin: "auto",
            textAlign: "left",
            marginBottom: "10px"
        }}
    >
        <CardContent sx={{
            textAlign: "left"
        }}>
            <Typography variant='h6'>{sectionTitle}</Typography>
            {children}
        </CardContent>
    </Card>
);

const SectionDetail: FC<{ label: string; value?: string; children?: React.ReactNode }> = ({ label, value, children }) => (
    <>
        <Divider
            className="divider"
            sx={{
                marginTop: '10px',
                marginBottom: '10px'
            }}
        />
        <Typography variant='caption'>{label}</Typography>
        {
            !children ? <Typography variant='body1'>{value}</Typography> : children
        }
    </>
)

export { SectionDetail };
export default ProfileSection;