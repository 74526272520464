import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { FC, MouseEvent, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppContext from '../../context/AppContext';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import './HomePage.css';

const HomePage: FC = () => {
    return (
        <>
            <div className="home-page-wrapper">
                <Header />
                <Intro />
                <InfoSection />
                <StripePartnership />
            </div>
            <Footer />
        </>
    )
}

const Header: FC = () => {
    const [appContext] = useContext(AppContext);
    const navigate = useNavigate();

    const handleProfilePictureClick = () => {
        navigate('/app');
    }

    const getUserProfilePictureUrl = () => {
        let key: string | undefined = Object.keys(appContext.user).find((k: string) => k.endsWith('Logo'));

        return key ? appContext.user[key] as string : "";
    }

    return (
        <header className='header'>
            {
                appContext.user.id ? (
                    <ProfilePicture src={getUserProfilePictureUrl()} className="profile-picture" onClick={handleProfilePictureClick} />
                ) : (
                    <div className='action-links'>
                        <Link to='/app'>Login</Link>
                        <Link to='/app/signup'>Signup</Link>
                    </div>
                )
            }
        </header>
    );
}

const Intro: FC = () => {
    const navigate = useNavigate();

    const handleGetStartedClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        navigate("/app/signup");
    }

    const handleMoreInfoClick = () => {
        const moreInfo: HTMLDivElement = document.getElementById("more-info") as HTMLDivElement;

        window.scroll({
            top: moreInfo.offsetTop - 20,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <Card className='intro'>
            <Box className='tip-split-logo'>
                <CardMedia
                    component="img"
                    src='/tipjar.svg'
                    height='100'
                    width='70'
                    draggable={false}
                />
            </Box>
            <Box className='content' >
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant='h4'>Accept tips with TipSplit.</Typography>
                    <Typography variant='h5' color='text.secondary'>
                        Schedule events. Accept tips from your event guests. Never manually pay out tips again. Split tips evenly with TipSplit.
                    </Typography>
                </CardContent>
            </Box>
            <Box className='action-buttons'>
                <Button variant="contained" className='button' onClick={e => handleGetStartedClick(e)}>GET STARTED</Button>
                <Button variant="outlined" className='button' onClick={handleMoreInfoClick}>MORE INFO</Button>
            </Box>
        </Card>
    );
};

const InfoSection: FC = () => (
    <div id="more-info">
        <Card className='info-card'>
            <CardMedia
                component="img"
                src='/mobile-calendar-icon.svg'
                draggable={false}
            />
            <CardContent>
                <Typography variant='subtitle1' style={{ fontFamily: "'Poppins', sans-serif", fontWeight: 'bold' }}>Schedule events</Typography>
                <Typography variant='body1' color='text.secondary'>
                    Scheduling events and ensuring that you have all the necessary employees scheduled has never been easier. TipSplit gives you full control of your schedule right at your fingertips.
                </Typography>
            </CardContent>
        </Card>

        <Card className='info-card'>
            <CardMedia
                component="img"
                src='/qr-icon.svg'
                draggable={false}
            />
            <CardContent>
                <Typography variant='subtitle1' style={{ fontFamily: "'Poppins', sans-serif", fontWeight: 'bold' }}>Generate unique QR Codes</Typography>
                <Typography variant='body1' color='text.secondary'>TipSplit will generate a unique QR code for you for each event that you schedule. Display the QR code at events or quickly access it via our web application and your guests will instantly be taken to a seamless payment portal.</Typography>
            </CardContent>
        </Card>

        <Card className='info-card'>
            <CardMedia
                component="img"
                src='/manual-split.svg'
                draggable={false}
            />
            <CardContent>
                <Typography variant='subtitle1' style={{ fontFamily: "'Poppins', sans-serif", fontWeight: 'bold' }}>Never manually split tips again</Typography>
                <Typography variant='body1' color='text.secondary'>
                    Scheduling employees with TipSplit is easy. And now it's even easier to pay them. TipSplit will automatically payout an equal share of collected tips to each scheduled employee.
                </Typography>
            </CardContent>
        </Card>

        <Card className='info-card'>
            <CardMedia
                component="img"
                src='/multiple-locations.svg'
                draggable={false}
            />
            <CardContent>
                <Typography variant='subtitle1' style={{ fontFamily: "'Poppins', sans-serif", fontWeight: 'bold' }}>Multiple locations</Typography>
                <Typography variant='body1' color='text.secondary'>
                    Whether your company has one location or multiple, TipSplit can handle it. Schedule events for multiple locations simultaneously and TipSplit will seamlessly split your employees tips accurately.
                </Typography>
            </CardContent>
        </Card>

        <Card className='info-card'>
            <CardMedia
                component='video'
                src='/no-app.mp4'
                autoPlay={true}
                muted={true}

            />
            <CardContent>
                <Typography variant='subtitle1' style={{ fontFamily: "'Poppins', sans-serif", fontWeight: 'bold' }}>No app to download</Typography>
                <Typography variant='body1' color='text.secondary'>
                    Because TipSplit is a web-based application you, your employees, and your customers won't ever need to download an app to use TipSplit.
                </Typography>
            </CardContent>
        </Card>

        <Card className='info-card'>
            <CardMedia
                component="img"
                src='/invite.svg'
                draggable={false}
            />
            <CardContent>
                <Typography variant='subtitle1' style={{ fontFamily: "'Poppins', sans-serif", fontWeight: 'bold' }}>Quickly invite new employees</Typography>
                <Typography variant='body1' color='text.secondary'>
                    When you hire a new employee onboarding them to TipSplit couldn't be easier. Simply use the application to send them an email invite and they'll be ready to use the application in no time.
                </Typography>
            </CardContent>
        </Card>
    </div>
);

const StripePartnership: FC = () => (
    <Card className='stripe-partnership'>
        <CardMedia
            component='img'
            height='140'
            src='/stripe-blurple.svg'
            className='stripe-logo'
            draggable={false}
        />
        <CardContent>
            <Typography variant='subtitle1' textAlign='left'>
                TipSplit partners with Stripe to process payments and pay your employees quickly, accurately, and most of all, safely. When your employees sign up for Stripe they can rest easy knowing that their data is in good hands.
            </Typography>
        </CardContent>
    </Card>
);

export const Footer: FC = () => (
    <footer className='footer'>
        <Typography variant='h6'>
            Social
        </Typography>

        <div>
            <a className='social-icon' href='https://facebook.com/Business/ProfilePage/TipSplit/profile' target='_blank' rel='noreferrer'>
                <FacebookIcon fontSize='large' />
            </a>
            <a className='social-icon' href='https://instagram.com/business/TipSplt' target='_blank' rel='noreferrer'>
                <InstagramIcon fontSize='large' />
            </a>
            <a className='social-icon' href='https://twitter.com/profile?=123456789' target='_blank' rel='noreferrer'>
                <TwitterIcon fontSize='large' />
            </a>
        </div>

        <div className='links'>
            <Link to='/'>HOME</Link>
            <Link to='/privacy'>PRIVACY</Link>
            <Link to='/terms-and-conditions'>TERMS AND CONDITIONS</Link>
            <Link to='contact-us'>CONTACT US</Link>
        </div>

        <Typography variant='subtitle2' className='copyright'>
            &copy; {new Date().getFullYear()} TipSplit
        </Typography>
    </footer>
)

export default HomePage;