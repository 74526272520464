import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { FC, useState } from "react";
import { BusinessLocation } from "../../models/Business";
import Location from "./Location";
import './Locations.css';
import NewLocationForm from './NewLocationForm';

const Locations: FC<{ locations: BusinessLocation[] | undefined }> = ({ locations }) => {
    const [newLocationOpen, setNewLocationOpen] = useState<boolean>(false);

    return (
        <div className="locations-container">
            {
                locations?.map(location => (
                    <Location key={location.locationName} location={location} />
                ))
            }

            <Fab
                className='add-location'
                size='large'
                color='primary'
                aria-label='Add location'
                onClick={() => setNewLocationOpen(true)}
            >
                <AddIcon />
            </Fab>

            {newLocationOpen && <NewLocationForm newLocationOpen={newLocationOpen} setNewLocationOpen={setNewLocationOpen} />}
        </div>
    );
}

export default Locations;
