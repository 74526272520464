import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import AppContext from "../../context/AppContext";
import { Business } from "../../models/Business";
import { Exception } from "../../models/ExceptionTypes";
import ApiClient from "../../services/ApiClient";

const cancelSubscription = async (stripeSubscriptionId: string, token: string) => {
    const res = await ApiClient.DeleteAsync('/api/stripe/cancelSubscription', token);

    if (!res.ok) {
        console.error(`Error cancelling subscription -- Err: ${await res.json()}`);

        throw new Exception(`Error cancelling subscription ${stripeSubscriptionId}.`);
    }
}

const CancelSubscription: React.FC = () => {
    const [appContext] = useContext(AppContext);
    const { stripeSubscriptionId } = (appContext.user as Business);
    const [openModal, setOpenModal] = useState<boolean>(false);

    const cancelSubscriptionMutation = useMutation<void, Error, { stripeSubscriptionId: string }, unknown>({
        mutationFn: async ({ stripeSubscriptionId }) => {
            await cancelSubscription(stripeSubscriptionId, appContext.auth.token)
        }
    });

    const closeModalOnError = () => {
        setOpenModal(false);

        setTimeout(cancelSubscriptionMutation.reset, 500);
    }

    return (
        <>
            <Button
                variant='contained'
                color='error'
                style={{ width: '95vw' }}
                onClick={() => setOpenModal(true)}
            >
                CANCEL SUBSCRIPTION
            </Button>

            <Dialog
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby='update-subscription-title'
                aria-describedby='update-subscription-description'
            >
                {
                    cancelSubscriptionMutation.isPending ? (
                        <DialogContent style={{
                            paddingBottom: '10px',
                            minHeight: '149px',
                            minWidth: '320px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <CircularProgress />
                        </DialogContent>
                    ) : cancelSubscriptionMutation.isError ? (
                        <>
                            <DialogContent style={{ paddingBottom: '10px' }}>
                                <DialogContentText>
                                    <Typography variant='subtitle1'>There was an error cancelling your subscription. Please try again later.</Typography>
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
                                <Button
                                    variant='contained'
                                    color='error'
                                    onClick={closeModalOnError}
                                >
                                    CLOSE
                                </Button>
                            </DialogActions>
                        </>
                    ) : (
                        <>
                            <DialogTitle>Are you sure?</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Typography variant='subtitle1'>You will no longer be able to use TipSplit if subscription is cancelled.</Typography>
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
                                <>
                                    <Button
                                        variant='contained'
                                        onClick={() => cancelSubscriptionMutation.mutateAsync({ stripeSubscriptionId })}
                                    >
                                        CANCEL SUBSCRIPTION
                                    </Button>
                                    <Button
                                        variant='contained'
                                        color='error'
                                        onClick={() => setOpenModal(false)}
                                    >
                                        CANCEL
                                    </Button>
                                </>
                            </DialogActions>
                        </>
                    )
                }
            </Dialog>
        </>
    );
}

export default CancelSubscription;