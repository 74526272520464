import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from '@mui/material/Paper';
import { FC, useContext, useState } from "react";
import AppContext from "../../context/AppContext";
import { Employee } from "../../models/Employee";
import PlaidVerification from '../PlaidVerification/PlaidVerification';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import IOSPrompt from '../iOSPrompt/iOSPrompt';
import EmployeeDetails from './EmployeeDetails';
import "./EmployeeProfile.css";
import EmployeeSchedule from './EmployeeSchedule';
import { Tipouts } from './EmployeeTipouts';



const EmployeeProfile: FC = () => {
    const [appContext] = useContext(AppContext);
    const user = appContext.user as Employee;
    const [selectedTab, setSelectedTab] = useState(0);
    // const [notificationSettings, setNotificationSettings] = useState<EmployeeNotifications>({} as EmployeeNotifications);
    // const os: string | undefined = useOperatingSystem();

    const bankAccountVerified = (): boolean => {
        return !!user.dwollaFundingSourceId;
    }

    // useEffect(() => {
    //     const getNotificationSettings = async () => {
    //         const res: Response = await ApiClient.GetAsync('/api/employees/notificationSettings', appContext.auth.token);

    //         if (res.ok) {
    //             setNotificationSettings(await res.json());
    //         } else {
    //             console.error('Could not retrieve notification settings');
    //             setNotificationSettings({
    //                 receiveEmails: true,
    //                 receivePushNotifications: false
    //             });
    //         }
    //     }

    //     getNotificationSettings();
    // }, [appContext.auth.token]);

    return (
        <>
            {
                bankAccountVerified() ? (
                    <>
                        <div className="employee-profile">
                            <div className="employee-profile-header">
                                <h4 className="employee-name">{user.firstName} {user.lastName}</h4>
                            </div>
                            <ProfilePicture className="employee-logo" src={user.profilePic} />
                            <div style={{ marginTop: "15px" }}>
                                {selectedTab === 0 ? <EmployeeSchedule /> :
                                    selectedTab === 1 ? <Tipouts /> :
                                        selectedTab === 2 && <EmployeeDetails />}
                            </div>
                            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                                <BottomNavigation
                                    showLabels={true}
                                    value={selectedTab}
                                    onChange={(event, newValue) => {
                                        setSelectedTab(newValue);
                                    }}
                                >
                                    <BottomNavigationAction label="Schedule" icon={<EventIcon />} />
                                    <BottomNavigationAction label="Tips" icon={<AttachMoneyIcon />} />
                                    <BottomNavigationAction label="Profile" icon={<PersonIcon />} />
                                </BottomNavigation>
                            </Paper>
                        </div>
                        <IOSPrompt promptName='add-to-home-screen' visibleDuration={4000} />
                    </>
                ) : (
                    <PlaidVerification />
                )
            }
        </>
    );
}

export default EmployeeProfile;