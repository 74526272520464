import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useScrollToTop } from "../../hooks/UseScrollToTop";
import { Footer } from "../HomePage/HomePage";

export const TermsAndConditions: FC = () => {
    useScrollToTop();

    const navigate = useNavigate();

    return (
        <>
            <div style={{ textAlign: 'left', width: '95vw', margin: 'auto', position: 'relative' }}>
                <ArrowBackIcon onClick={() => navigate(-1)} color='primary' style={{ position: 'absolute' }} />
                <div style={{ textAlign: 'center' }}>
                    <h1 style={{ marginBottom: '0' }}>TipSplit</h1>
                    <h2 style={{ marginTop: '0' }}>Terms of Service</h2>
                </div>
                <p>Welcome to TipSplit! By accessing or using our services, you agree to be bound by these terms of service. If you do not agree with these terms, you should not use our services.</p>

                <h3>1. Use of Service</h3>
                <p>Our platform is intended to be used by businesses in the service industry to manage employee payments and tips. By using our service, you agree to use it in compliance with all applicable laws and regulations. You also agree not to use our service for any illegal or unauthorized purpose.</p>

                <h3>2. Services</h3>
                <p>TipSplit provides a platform that allows businesses to create accounts, add multiple locations, and schedule employees for events. Employees can also create accounts associated with their businesses and receive tips from guests at events. TipSplit uses third-party companies, such as Plaid, Stripe, and Dwolla, to process payments and withdrawals.</p>

                <h3>3. Account Security</h3>
                <p>You are responsible for maintaining the security of your TipSplit account. You must keep your login credentials and any other information related to your account confidential. If you suspect that your account has been compromised, please contact us immediately.</p>

                <h3>4. Payments and withdrawals</h3>
                <p>TipSplit processes all payments and withdrawals through third-party companies, such as Stripe and Dwolla. We do not store any bank account details in our system, only tokens that represent bank accounts. Withdrawals can be done in two ways: standard withdraw or same-day withdraw with a fee.</p>

                <h3>5. Intellectual Property</h3>
                <p>All content on our platform, including text, graphics, logos, and images, is the property of TipSplit or its licensors and is protected by United States and international copyright laws. You agree not to use any content from our platform without our prior written consent.</p>

                <h3>6. Disclaimer of Warranties</h3>
                <p>Our platform is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of our platform or the information, content, materials, or products included on our platform. You use our platform at your own risk.</p>

                <h3>7. Limitation of Liability</h3>
                <p>In no event shall TipSplit be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use our platform or the information, content, materials, or products included on our platform.</p>

                <h3>8. Indemnification</h3>
                <p>You agree to indemnify and hold TipSplit harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of our platform, your violation of these terms and conditions, or your violation of any rights of another.</p>

                <h3>9. Termination</h3>
                <p>We reserve the right to terminate your access to our services at any time, for any reason, without notice.</p>

                <h3>10. Changes to Terms and Conditions</h3>
                <p>We may update these terms and conditions from time to time. You should check this page periodically for changes. Your continued use of our platform after any changes to these terms and conditions constitutes your acceptance of the updated terms and conditions.</p>

                <h3>11. Governing Law</h3>
                <p>These terms and conditions shall be governed by and construed in accordance with the laws of the State of [insert state], without giving effect to any principles of conflicts of law.</p>

                <h3>12. Contact Us</h3>
                <p>If you have any questions or concerns about these terms and conditions, please <Link to='/contact-us'>contact us</Link>.</p>
            </div>
            <Footer />
        </>
    )
}
