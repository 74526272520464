import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SpeedIcon from '@mui/icons-material/Speed';
import { Backdrop, Button, CircularProgress, Drawer } from "@mui/material";
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import AppContext from "../../context/AppContext";
import { Employee } from "../../models/Employee";
import ApiClient from '../../services/ApiClient';
import TipService from '../../services/TipService';
import "./EmployeeProfile.css";
import { TransferDetails, TransferType } from './EmployeeTipouts';

interface TransferDrawerProps {
    employee: Employee;
    openTransfer: boolean;
    setOpenTransfer: Dispatch<SetStateAction<boolean>>;
    transferType: TransferType;
    setTransferType: Dispatch<SetStateAction<TransferType>>;
    transferDetails: TransferDetails;
    setTransferDetails: Dispatch<SetStateAction<TransferDetails>>;
    setSuccessfulTransfer: Dispatch<SetStateAction<{ wasSuccess: boolean, amount: number }>>;
}

const TransferDrawer: FC<TransferDrawerProps> = ({ employee, openTransfer, setOpenTransfer, transferType, setTransferType, transferDetails, setTransferDetails, setSuccessfulTransfer }) => {
    const [appContext] = useContext(AppContext);
    const [transferring, setTransferring] = useState<boolean>(false);

    const initiateTransfer = async () => {
        setTransferring(true);

        const response: Response = await ApiClient.PostAsync('/api/dwolla/initiateTransfer', {
            transferType
        }, appContext.auth.token);

        if (!response.ok) {
            console.error(`Error creating transfer -- Error: ${await response.json()}`);
            setTransferring(false);
        } else {
            setOpenTransfer(false);
            setTransferDetails({
                balance: 0.00,
                balanceString: '$0.00',
                instantTransferFee: 0.00,
                instantTransferFeeString: '$0.00'
            });
            setSuccessfulTransfer({
                wasSuccess: true,
                amount: transferType === TransferType.SameDayTransfer ? transferDetails.balance - transferDetails.instantTransferFee : transferDetails.balance
            });
            setTransferring(false);
        }
    }

    return (
        <Drawer anchor="bottom" open={openTransfer} onClose={() => setOpenTransfer(false)}>
            <div className='transfer-drawer'>
                <div className='transfer-types'>
                    <Card
                        className={`transfer-type${transferType === TransferType.SameDayTransfer ? ' selected' : ''}`}
                        onClick={() => setTransferType(TransferType.SameDayTransfer)}
                        elevation={0}
                    >
                        <SpeedIcon style={{ height: '65px', width: '65px' }} color={transferType === TransferType.SameDayTransfer ? 'primary' : 'inherit'} />
                        <Typography variant="h6" fontWeight={600} fontSize='1rem' style={{ marginTop: '-10px' }}>Instant</Typography>
                    </Card>

                    <Card
                        className={`transfer-type${transferType === TransferType.StandardTransfer ? ' selected' : ''}`}
                        onClick={() => setTransferType(TransferType.StandardTransfer)}
                        elevation={0}
                    >
                        <AccountBalanceIcon style={{ height: '65px', width: '55px' }} color={transferType === TransferType.StandardTransfer ? 'primary' : 'inherit'} />
                        <Typography variant="h6" fontWeight={600} fontSize='1rem' style={{ marginTop: '-5px' }}>1-3 biz days</Typography>
                    </Card>
                </div>
                {
                    transferType === TransferType.SameDayTransfer && (
                        <>
                            <Divider />
                            <div className='transfer-info'>
                                <Typography variant="body1">Fee</Typography>
                                <Typography className='value' variant="body1">{transferDetails.instantTransferFeeString}</Typography>
                            </div>
                        </>
                    )
                }
                <Divider />
                <div className='transfer-info'>
                    <Typography variant="body2">Transfer to</Typography>
                    <Typography className='value' variant="body2">{employee.bankingInstitution} {employee.bankAccountLastFourDigits}</Typography>
                </div>
                <Divider />
                <Typography className='caption' variant="caption">
                    Transfer speeds vary and could take up to 3 {transferType === TransferType.SameDayTransfer ? 'hours' : 'days'} to complete depending on your banking institution. Please allow this time to elapse before reaching out for assistance.
                </Typography>
                <Button className='transfer-button' variant='contained' onClick={initiateTransfer} disabled={transferDetails.balance <= 0 || transferring}>
                    Transfer {transferType === TransferType.SameDayTransfer ? TipService.FormatTip(transferDetails.balance - transferDetails.instantTransferFee) : transferDetails.balanceString}
                </Button>

                {
                    transferring && (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={true}
                        >
                            <CircularProgress />
                        </Backdrop>
                    )
                }
            </div>
        </Drawer>
    );
}

export default TransferDrawer;