export class Exception extends Error {
    public formExceptionMessage: string;

    public constructor(message: string, _formExceptionManager?: string) {
        super(message);

        this.formExceptionMessage = _formExceptionManager || message;
    }
}

export class UserNotFoundException extends Exception {
    public constructor(message: string, formExceptionMessage?: string) {
        super(message, formExceptionMessage);
    }
}

export class IncorrectPasswordException extends Exception {
    public constructor(message: string, formExceptionMessage?: string) {
        super(message, formExceptionMessage);
    }
}

export class UnauthorizedAccessException extends Exception {
    public constructor(message: string, formExceptionMessage?: string) {
        super(message, formExceptionMessage);
    }
}

export class DuplicateUserException extends Exception {
    public constructor(message: string, formExceptionMessage?: string) {
        super(message, formExceptionMessage);
    }
}

export class HttpPostException extends Exception {
    public constructor(message: string, formExceptionMessage?: string) {
        super(message, formExceptionMessage);
    }
}

export class AccountLockedException extends Exception {
    public constructor(message: string, formExceptionMessage?: string) {
        super(message, formExceptionMessage);
    }
}