import { FC } from "react";

const Disclaimer: FC<{ children: React.ReactNode }> = ({ children }) => (
    <div
        className="stripe-information"
        style={{
            background: "url('/plaid-icon.svg') right 15px center no-repeat",
            backgroundSize: '100px'
        }}
    >
        {children}
    </div>
);

export default Disclaimer;