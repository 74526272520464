import { Box, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { PlaidLinkError, PlaidLinkOnExit, PlaidLinkOnExitMetadata, PlaidLinkOnSuccess, PlaidLinkOnSuccessMetadata, PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import { useNavigate } from "react-router-dom";
import AppContext, { UpdateUser } from "../../context/AppContext";
import ApiClient from "../../services/ApiClient";
import Disclaimer from "../Disclaimer/Disclaimer";

const PlaidVerification: FC = () => {
    const [appContext, dispatch] = useContext(AppContext);
    const [linkToken, setLinkToken] = useState<string>('');
    const [verified, setVerified] = useState<boolean>(false);
    const [plaidError, setError] = useState<boolean>(false);
    const navigate = useNavigate();

    const createLinkToken = useCallback(async () => {
        const res = await ApiClient.GetAsync(`/api/plaid/linkToken/${appContext.user.id}`, appContext.auth.token);

        if (!res.ok) {
            console.error(await res.json());
        } else {
            const { plaidLinkToken } = await res.json();
            setLinkToken(plaidLinkToken);
        }
    }, [appContext.auth.token, appContext.user.id]);

    useEffect(() => {
        createLinkToken();
    }, [createLinkToken]);

    const onSuccessfulAccountVerification = useCallback<PlaidLinkOnSuccess>((public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
        setVerified(true);
        ApiClient.PostAsync(`/api/plaid/exchangePublicToken/${appContext.user.id}`, {
            publicToken: public_token,
            bankingInformation: {
                institutionName: metadata.institution?.name,
                institutionId: metadata.institution?.institution_id,
                account: metadata.accounts[0]
            }
        }, appContext.auth.token)
            .then(async () => {
                const res = await ApiClient.GetAsync(`/api/employees/${appContext.user.id}/true`, appContext.auth.token);

                dispatch(UpdateUser(await res.json()));
            })
            .then(() => {
                setVerified(false);
                navigate('/app');
            })
    }, [appContext.auth.token, appContext.user.id, dispatch, navigate, setVerified]);

    const onAccountVerificationError = useCallback<PlaidLinkOnExit>((error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
        if (error && error.error_code === 'INVALID_LINK_TOKEN') {
            // Generate new link token
            createLinkToken();
        } else {
            setError(true);
        }
    }, [createLinkToken, setError]);

    const plaidConfig: PlaidLinkOptions = {
        onSuccess: onSuccessfulAccountVerification,
        onExit: onAccountVerificationError,
        token: linkToken,
    }

    const { open, exit, ready } = usePlaidLink(plaidConfig);

    useEffect(() => {
        if (ready) open();
    }, [open, ready]);

    const openPlaid = (from: string) => {
        console.log(`open being called from ${from}`);
        open();
    }

    return (
        <>
            {
                !verified && plaidError ? (
                    <div>
                        <Disclaimer>
                            <p>
                                In order to continue you must connect to your bank account. Please click the button below to be taken back to Plaid.
                            </p>
                        </Disclaimer>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setError(false);
                                openPlaid('error handler');
                            }}
                        >
                            CONNECT BANK ACCOUNT
                        </Button>
                    </div>
                ) : (
                    <>
                        {
                            verified && (
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress />
                                </Box>
                            )
                        }
                    </>
                )
            }
        </>
    );
}

export default PlaidVerification;