import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import { FC, useContext, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../context/AppContext";
import ApiClient from "../../services/ApiClient";
import { EmailPassword } from "../index";
import BusinessDetails from "./BusinessDetails";
import EmployeeDetails from "./EmployeeDetails";
import "./Signup.css";

const Signup: FC = () => {
    const [appContext] = useContext(AppContext);
    const [emailPassword, setEmailPassword] = useState<{ email: string, password: string } | null>(null);
    const [selectedForm, setSelectedForm] = useState<string>("employee");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [inEffect, setInEffect] = useState<boolean>(false);

    //#region ACTION HANDLERS

    const handleEmailPasswordFormSubmit = async (email: string, password: string): Promise<void> => {
        setErrorMessage('');
        setInEffect(true);

        if (selectedForm === "employee" && await employeeExists(email)) {
            setErrorMessage(`An employee already exists with email address: ${email}`);
        } else if (selectedForm === "business" && await businessExists(email)) {
            setErrorMessage(`A business is already registered with the email address: ${email}`);
        } else {
            setEmailPassword({ email, password });
        }

        setInEffect(false);
    }

    //#endregion ACTION HANDLERS

    //#region HELPERS

    const employeeExists = async (email: string): Promise<boolean | undefined> => {
        let res = await ApiClient.GetAsync(`/api/Employees/Exists/${email}`, appContext.auth.token);

        if (!res.ok)
            console.error(`Error checking if ${email} is already a registered employee`);
        else
            return await res.json();
    }

    const businessExists = async (email: string): Promise<boolean | undefined> => {
        let res = await ApiClient.GetAsync(`/api/Business/Exists/${email}`, appContext.auth.token);

        if (!res.ok)
            console.error(`Error checking if ${email} has already registered as an existing business`);
        else
            return await res.json();
    }

    // END REGION -- HELPERS

    return (
        <>
            <div style={{ display: `${!inEffect ? 'block' : 'none'}` }}>
                {!emailPassword ? (
                    <>
                        <div style={{ textAlign: "left", width: "95vw", margin: "auto", marginBottom: "15px" }}>
                            <h3 className="details-header">SELECT ACCOUNT TYPE</h3>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="Account type"
                                    defaultValue="employee"
                                    name="account-type-group"
                                    onChange={e => setSelectedForm(e.target.value)}
                                >
                                    <FormControlLabel
                                        value="employee"
                                        control={<Radio />}
                                        label="Employee"
                                    />
                                    <FormControlLabel
                                        value="business"
                                        control={<Radio />}
                                        label="Business"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {errorMessage && (
                            <div style={{ width: "95vw", margin: "auto", marginBottom: "15px" }}>
                                <Alert severity="error" variant="outlined">{errorMessage}</Alert>
                            </div>
                        )}
                        <EmailPassword buttonText="CONTINUE SIGNUP" onSubmit={handleEmailPasswordFormSubmit} enforcePasswordRequirements={true} >
                            <p>Already have an account? <Link to="/app">Login</Link></p>
                        </EmailPassword>
                    </>
                ) : (
                    <>
                        <Stack
                            sx={{
                                width: "95vw",
                                margin: "auto"
                            }}
                            spacing={2}
                        >
                            {selectedForm === "employee" ?
                                <EmployeeDetails emailPassword={emailPassword} setInEffect={setInEffect} /> :
                                <BusinessDetails emailPassword={emailPassword} setInEffect={setInEffect} />
                            }
                        </Stack>
                    </>
                )}
            </div>
            <Box sx={{ display: `${inEffect ? 'flex' : 'none'}`, justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        </>
    );
}

export default Signup;