import { Chip, FormControl, InputLabel, MenuItem, OutlinedInput, SelectChangeEvent, Stack, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/system/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ChangeEvent, useContext } from 'react';
import AppContext from '../../context/AppContext';
import useAsyncError from '../../hooks/UseAsyncError';
import { BusinessLocation } from '../../models/Business';
import { EmployeeDetails } from '../../models/Employee';
import { InputError } from '../../models/InputError';
import { TSEvent } from '../../models/TSEvent';
import ApiClient from '../../services/ApiClient';
import { getInputValuesByName } from '../../utils/Helpers';
import './Schedule.css';
import { ScheduleReducerPayload, ScheduleState } from './ScheduleReducer';


interface INewEventFormProps {
    scheduleState: ScheduleState;
    dispatch: React.Dispatch<ScheduleReducerPayload>;
    employees: EmployeeDetails[] | undefined;
    locations: BusinessLocation[] | undefined;
}

const NewEventForm: React.FC<INewEventFormProps> = ({ scheduleState, dispatch, employees, locations }) => {
    const [appContext] = useContext(AppContext);
    const throwSubscriptionError = useAsyncError();
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    /**
     * Event Handlers
     */

    const handleEventTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value && scheduleState?.newEventFormState?.eventTitle?.error)
            resetNewEventForm(e.currentTarget.name);

        dispatch({ type: 'EVENT_TITLE', eventTitle: e.currentTarget.value });
    }

    const handleEventAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value && scheduleState?.newEventFormState?.eventAddress?.error)
            resetNewEventForm(e.currentTarget.name);

        dispatch({ type: 'EVENT_ADDRESS', eventAddress: e.currentTarget.value });
    }

    const handleEstimatedTotalGuestsChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value && scheduleState?.newEventFormState?.estimatedTotalGuests?.error)
            resetNewEventForm(e.currentTarget.name);

        dispatch({ type: 'ESTIMATED_TOTAL_GUESTS', estimatedTotalGuests: e.currentTarget.value })
    }

    const handleEventNotesChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        dispatch({ type: 'EVENT_NOTES', eventNotes: e.currentTarget.value });
    }

    const handleScheduledEmployeesChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;

        dispatch({ type: 'NEW_EVENT_EMPLOYEES', newEventEmployees: typeof value === 'string' ? value.split(',') : value });

        if (value && scheduleState?.newEventFormState?.scheduledEmployees?.error)
            resetNewEventForm('scheduledEmployees');
    };

    const handleLocationChange = (event: SelectChangeEvent<number>) => {
        const {
            target: { value },
        } = event;

        dispatch({ type: 'NEW_EVENT_LOCATION', newEventLocation: value })

        if (value && scheduleState?.newEventFormState?.location?.error)
            resetNewEventForm('location');
    }

    const handleStartDateChange = (value: Date | null) => {
        if (value) dispatch({ type: 'NEW_EVENT_START_DATE', newEventStartDate: value });
    }

    const handleEndDateChange = (value: Date | null) => {
        if (value) dispatch({ type: 'NEW_EVENT_END_DATE', newEventEndDate: value });
    }

    const handleNewEventCreate = async () => {
        let newEventData: TSEvent = {
            eventTitle: scheduleState.newEventTitle,
            eventAddress: scheduleState.newEventAddress,
            startDate: scheduleState.newEventStartDate!,
            endDate: scheduleState.newEventEndDate!,
            scheduledEmployees: getScheduledEmployees(),
            estimatedTotalGuests: parseInt(scheduleState.newEventEstimatedTotalGuests),
            eventNotes: scheduleState.newEventNotes,
            locationId: scheduleState.newEventLocation as string
        };

        const [validFormState, formState] = validateFormState();

        if (validFormState) {
            let res = await ApiClient.PostAsync('/api/Events', newEventData, appContext.auth.token);

            if (res.ok) {
                let newlyCreatedEvent: TSEvent = await res.json();

                dispatch({ type: 'NEW_EVENT_CREATE', newEvent: newlyCreatedEvent });
                // appDispatch({ type: ADD_EVENT, newEvent: newlyCreatedEvent });
            } else if (res.status === 402) {
                throwSubscriptionError(new Error(await res.json()));
            }
        } else
            dispatch({ type: 'FORM_STATE', newEventFormState: formState });
    }

    const handleEventUpdate = async () => {
        let updatedEventData: TSEvent = {
            id: scheduleState.editEventId,
            eventTitle: scheduleState.newEventTitle,
            eventAddress: scheduleState.newEventAddress,
            startDate: scheduleState.newEventStartDate!,
            endDate: scheduleState.newEventEndDate!,
            estimatedTotalGuests: parseInt(scheduleState.newEventEstimatedTotalGuests),
            scheduledEmployees: getScheduledEmployees(),
            eventNotes: scheduleState.newEventNotes,
            locationId: scheduleState.newEventLocation as string
        };

        const [validFormState, formState] = validateFormState();

        if (validFormState) {
            let res = await ApiClient.PutAsync(`/api/Events/${updatedEventData.id}`, updatedEventData, appContext.auth.token);

            if (res.ok) {
                let updatedEvent: TSEvent = await res.json();

                dispatch({ type: 'UPDATE_EVENT', updatedEvent });
                // appDispatch({ type: 'UPDATE_EVENT', updatedEvent });
            } else if (res.status === 402) {
                throwSubscriptionError(new Error(await res.json()));
            }

        } else
            dispatch({ type: 'FORM_STATE', newEventFormState: formState });
    }

    const handleEventDelete = async () => {
        let res = await ApiClient.DeleteAsync(`/api/Events/${scheduleState.editEventId}`, appContext.auth.token);

        if (res.ok) {
            // appDispatch({
            //     type: 'DELETE_EVENT',
            //     eventId: scheduleState.editEventId!,
            //     locationId: scheduleState.businessEvents.find(e => e.id === scheduleState.editEventId)!.locationId!
            // });
            dispatch({ type: 'DELETE_EVENT', eventId: scheduleState.editEventId! });
        } else if (res.status === 402) {
            throwSubscriptionError(await res.json());
        } else {
            console.error(`Error deleting event: ${scheduleState.editEventId}`);
        }
    }

    /**
     * End Event Handlers
     */

    /**
     * Helpers
     */

    const resetNewEventForm = (inputName: string): void => {
        scheduleState.newEventFormState[inputName] = {
            helperText: '',
            error: false
        }

        dispatch({ type: 'FORM_STATE', newEventFormState: { ...scheduleState.newEventFormState } });
    }

    const getScheduledEmployees = (): string[] => {
        return scheduleState.newEventEmployees.reduce((result, employeeName) => {
            result.push(employees?.find(e => `${e.firstName} ${e.lastName}` === employeeName)!.id!);
            return result
        }, [] as string[])
    }

    const validateFormState = (): [boolean, { [key: string]: InputError }] => {
        const [eventTitle, eventAddress] = getNewEventFormDetails();

        let formState: { [key: string]: InputError } = {
            eventTitle: {
                helperText: !eventTitle ? 'Event Title is a required field' : '',
                error: !(!!eventTitle)
            },
            eventAddress: {
                helperText: !eventAddress ? 'Event Address is a required field' : '',
                error: !(!!eventAddress)
            },
            startDate: {
                helperText: !scheduleState?.newEventStartDate ? 'Start Time is a required field' : '',
                error: !(!!scheduleState?.newEventStartDate)
            },
            endDate: {
                helperText: !scheduleState?.newEventEndDate ? 'End Time is a required field' : '',
                error: !(!!scheduleState?.newEventEndDate)
            },
            scheduledEmployees: {
                helperText: scheduleState?.newEventEmployees.length <= 0 ? 'At least one employee must be scheduled' : '',
                error: scheduleState?.newEventEmployees.length <= 0
            },
            location: {
                helperText: scheduleState?.newEventLocation === undefined ? 'Location is a required field' : '',
                error: scheduleState?.newEventLocation === undefined
            },
            estimatedTotalGuests: {
                helperText: !scheduleState?.newEventEstimatedTotalGuests ? 'Estimated Total Guests is a required field' : '',
                error: !(!!scheduleState?.newEventEstimatedTotalGuests)
            }
        }

        return [!Object.values(formState).some(inputError => inputError.error === true), formState];
    }

    const getNewEventFormDetails = (): [string, string, string] => {
        const { eventTitle, eventAddress } = getInputValuesByName(['eventTitle', 'eventAddress']);
        const eventNotes = (document.querySelector('textarea[name="eventNotes"]') as HTMLTextAreaElement).value;

        return [eventTitle, eventAddress, eventNotes];
    }

    /**
     * End Helpers
     */

    return (
        <Dialog
            onClose={() => dispatch({ type: 'NEW_EVENT_OPEN', newEventOpen: false })}
            open={scheduleState.newEventOpen}
            fullScreen={fullScreen}
        >
            <DialogTitle sx={{ color: '#064887' }} className='create-event-header'>Create new event</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Event Title'
                        name='eventTitle'
                        type='text'
                        fullWidth
                        variant='outlined'
                        value={scheduleState?.newEventTitle}
                        required={true}
                        error={scheduleState?.newEventFormState?.eventTitle?.error}
                        helperText={scheduleState?.newEventFormState?.eventTitle?.helperText}
                        onChange={e => handleEventTitleChange(e as ChangeEvent<HTMLInputElement>)}
                    />
                    <TextField
                        margin='dense'
                        label='Event Address'
                        name='eventAddress'
                        type='text'
                        fullWidth
                        variant='outlined'
                        value={scheduleState?.newEventAddress}
                        required={true}
                        error={scheduleState?.newEventFormState?.eventAddress?.error}
                        helperText={scheduleState?.newEventFormState?.eventAddress?.helperText}
                        onChange={e => handleEventAddressChange(e as ChangeEvent<HTMLInputElement>)}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            label='Start Time'
                            value={scheduleState.newEventStartDate}
                            onChange={(value: Date | null) => handleStartDateChange(value)}
                        />
                        <DateTimePicker
                            label='End Time'
                            value={scheduleState.newEventEndDate}
                            onChange={(value: Date | null) => handleEndDateChange(value)}
                        />
                    </LocalizationProvider>
                    <TextField
                        margin='dense'
                        label='Estimated Total Guests'
                        name='estimatedTotalGuests'
                        type='number'
                        fullWidth
                        variant='outlined'
                        value={scheduleState?.newEventEstimatedTotalGuests}
                        required={true}
                        error={scheduleState?.newEventFormState?.estimatedTotalGuests?.error}
                        helperText={scheduleState?.newEventFormState?.estimatedTotalGuests?.helperText}
                        onChange={e => handleEstimatedTotalGuestsChange(e as ChangeEvent<HTMLInputElement>)}
                    />
                    <FormControl>
                        <InputLabel id='employees-select-label'>Scheduled Employees*</InputLabel>
                        <Select
                            labelId='employees-select-label'
                            multiple={true}
                            required={true}
                            value={scheduleState.newEventEmployees}
                            onChange={handleScheduledEmployeesChange}
                            input={<OutlinedInput label='Scheduled Employees' />}
                            error={scheduleState?.newEventFormState?.scheduledEmployees?.error}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {
                                        selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))
                                    }
                                </Box>
                            )}
                        >
                            {
                                employees?.map(employee => (
                                    <MenuItem
                                        key={`${employee.firstName}-${employee.lastName}`}
                                        value={`${employee.firstName} ${employee.lastName}`}
                                    >
                                        {employee.firstName} {employee.lastName}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant='outlined'>
                        <InputLabel id='location-select-label'>Location*</InputLabel>
                        <Select
                            labelId='location-select-label'
                            name='location'
                            required={true}
                            input={<OutlinedInput label='Location*' />}
                            error={scheduleState?.newEventFormState?.location?.error}
                            value={scheduleState?.newEventLocation as any}
                            onChange={(e: SelectChangeEvent<number>) => handleLocationChange(e)}
                        >
                            {locations?.map(bL => <MenuItem key={`location-${bL.id}`} value={bL.id}>{bL.locationName}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <TextField
                        margin='dense'
                        label='Event Notes'
                        name='eventNotes'
                        fullWidth={true}
                        variant='outlined'
                        multiline={true}
                        minRows={5}
                        maxRows={10}
                        value={scheduleState?.newEventNotes}
                        onChange={e => handleEventNotesChange(e as ChangeEvent<HTMLTextAreaElement>)}
                    />
                </Stack>
                <DialogActions sx={{
                    padding: 0,
                    marginTop: 2
                }}>
                    <Button onClick={() => dispatch({ type: 'NEW_EVENT_CLOSE' })} variant='contained'>Cancel</Button>
                    {!scheduleState.editingEvent ? (
                        <>
                            <Button onClick={handleNewEventCreate} variant='contained'>Create</Button>
                        </>
                    ) : (
                        <>
                            <Button variant='contained' color='error' onClick={handleEventDelete}>Delete</Button>
                            <Button variant='contained' onClick={handleEventUpdate}>Update</Button>
                        </>
                    )}
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default NewEventForm;