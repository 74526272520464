import { Alert, Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../context/AppContext';

const ForgotPassword: FC = () => {
    const [appContext] = useContext(AppContext);
    const [error, setError] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [requesting, setRequesting] = useState<boolean>(false);
    const [emailAddress, setEmailAddress] = useState<string>('');

    const onSubmit = async () => {
        setError('');
        setRequesting(true);

        const formData = new FormData(document.querySelector('#forgot-password-form') as HTMLFormElement);
        const email = formData.get('emailAddress')?.toString();

        if (email) {
            setEmailAddress(email);

            const res = await fetch('/api/auth/requestPasswordChange', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${appContext.auth.token}`
                },
                body: formData
            });

            if (res.ok) {
                setSuccessMessage('Please check your email to reset your password');
                setEmailAddress('');
                setRequesting(false);
            } else {
                setRequesting(false);
                console.error('There was an error requesting a password update', await res.json());

                if (res.status === 400)
                    setError('Please provide a valid email address');
                else if (res.status === 404)
                    setError('User not found');
                else if (res.status === 500)
                    setError('There was an error requesting a password reset');
            }
        } else {
            setError('Please enter a valid email address');
            setRequesting(false);
        }
    }

    return (
        <div style={{ width: '95vw', margin: 'auto' }}>
            {
                !requesting ? (
                    <>
                        <Typography variant='body2' style={{ marginBottom: '10px' }}>
                            Enter your email address to be sent a password reset email.
                        </Typography>

                        {
                            (!!error || !!successMessage) && (
                                <Alert
                                    severity={
                                        !!error && !successMessage ? 'error' :
                                            !!successMessage && !error ? 'success' : 'info'
                                    }
                                    style={{ marginBottom: '10px' }}
                                >
                                    {error || successMessage}
                                </Alert>
                            )
                        }

                        <form
                            id='forgot-password-form'
                            onSubmit={onSubmit}
                            style={{ marginBottom: '15px' }}
                        >
                            <Stack
                                sx={{
                                    width: "100%",
                                    margin: "auto"
                                }}
                                spacing={2}
                            >
                                <TextField
                                    label="Email Address"
                                    type="email"
                                    name="emailAddress"
                                    variant="outlined"
                                    size="small"
                                    required={true}
                                    error={!!error}
                                    defaultValue={emailAddress}
                                />
                                <Button
                                    variant="contained"
                                    style={{ cursor: "pointer" }}
                                    onClick={onSubmit}
                                >
                                    SUBMIT
                                </Button>
                            </Stack>
                        </form>
                        <Link to='/app'>Login</Link>
                        <span> or </span>
                        <Link to='/app/signup'>Signup</Link>
                    </>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )
            }
        </div>
    );
}

export default ForgotPassword;