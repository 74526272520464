import { useCallback, useState } from "react";

const useAsyncError = () => {
    const [_, setError] = useState<Error | null>();

    return useCallback(
        (e: Error) => {
            setError(() => {
                throw e;
            });
        },
        [setError]
    );
};

export default useAsyncError;