import { Link } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { Appearance, loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TipAmountForm, TipCheckoutForm } from "..";
import AppContext from "../../context/AppContext";
import ApiClient from "../../services/ApiClient";
import { ITipInfo } from "../TipAmountForm/TipAmountForm";
import "./TipPage.css";

const stripePublishableKey = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ?
    "pk_test_51JowRfIFEOpkRbKYbwxe7HjeEMKHOEydM7mfvCkj7C7DjrXS4WgHPYY2cJdoiZSGmm3mGJNieQFHPhvFkgoNCPAG00EYdPNeJO" :
    "pk_live_51JowRfIFEOpkRbKYPvM7EIuq230OEAXRTKFeN8WunxnmtXKlbmOjzstvgiyVMNSE4a1K7jXBZXVUgaM6ptoiHzYw00MBSaJlwP";

const stripePromise = loadStripe(stripePublishableKey);

const TipPage: FC = () => {
    const [appContext] = useContext(AppContext);
    const { id } = useParams<{ id: string | undefined }>();
    const [clientSecret, setClientSecret] = useState("");
    const [tipInfo, setTipInfo] = useState<ITipInfo | null>(null);

    useEffect(() => {
        if (tipInfo) {
            const CreatePaymentIntent = async () => {
                let res = await ApiClient.PostAsync('/api/tips/CreatePaymentIntent', {
                    tipAmount: parseFloat(tipInfo.tipAmount.replace(",", "").substring(1)),
                    id: id!
                }, appContext.auth.token);

                if (res.ok)
                    setClientSecret((await res.json()).clientSecret);
            }

            // Create PaymentIntent as soon as the page loads
            CreatePaymentIntent();
        }
    }, [appContext.auth.token, tipInfo, id])

    const appearance: Appearance = {
        theme: 'stripe',
    };

    const options: StripeElementsOptions = {
        clientSecret,
        appearance
    };

    return (
        <>
            {!tipInfo ? <TipAmountForm setTipInfo={setTipInfo} /> : clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <TipCheckoutForm tipInfo={tipInfo} />
                </Elements>
            )}

            <div className='tipsplit-link'>
                <Link href='https://tipsplit.app/' target='_blank'><b>Learn about TipSplit</b></Link>
            </div>
        </>
    );
}

export default TipPage;