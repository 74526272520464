import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { FC, KeyboardEvent, useEffect, useState } from "react";
import { InputError } from "../../models/InputError";
import { getInputValuesByName } from "../../utils/Helpers";

export interface IEmailPassword {
    buttonText: string;
    onSubmit: (email: string, password: string) => (void | Promise<void>);
    enforcePasswordRequirements?: boolean;
    children?: React.ReactNode
}

const EmailPassword: FC<IEmailPassword> = ({ onSubmit, children, buttonText, enforcePasswordRequirements }) => {
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [formState, setFormState] = useState<{ [key: string]: InputError }>({});

    useEffect(() => {
        if (!Object.values(formState).some(input => input.error === true))
            setErrorMessage("");
    }, [formState]);

    const validateForm = (email: string, password: string): [boolean, { [key: string]: InputError }] => {
        let emailAndPasswordErrors: { [key: string]: InputError } = {
            email: {
                helperText: !email ? "Email Address is a required field" : "",
                error: !(!!email)
            }
        }

        if (enforcePasswordRequirements && (password.length < 12 || !(!!password))) {
            emailAndPasswordErrors.password = {
                helperText: 'Your password must be at least 12 characters long.',
                error: true
            }
        } else if (!(!!password)) {
            emailAndPasswordErrors.password = {
                helperText: !password ? "Password is a required field" : "",
                error: true
            }
        } else {
            emailAndPasswordErrors.password = {
                helperText: '',
                error: false
            }
        }

        return [!Object.values(emailAndPasswordErrors).some(inputError => inputError.error === true), emailAndPasswordErrors];
    }

    const handleLoginClick = async (): Promise<void> => {
        const { email, password } = getInputValuesByName(["email", "password"]);
        const [validForm, formErrors] = validateForm(email, password);

        if (validForm)
            await onSubmit(email, password);
        else {
            setErrorMessage("Please fill out all required fields");
            setFormState(formErrors);
        }
    }

    const captureEnterPress = async (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            const { email, password } = getInputValuesByName(["email", "password"]);
            await onSubmit(email, password);
        }
    }

    const resetFormError = (inputName: string): void => {
        formState[inputName] = {
            helperText: "",
            error: false
        }

        setFormState({ ...formState });
    }

    return (
        <Stack
            sx={{
                width: "95vw",
                margin: "auto"
            }}
            spacing={2}
        >
            {errorMessage && (
                <div style={{ width: "95vw", margin: "auto" }}>
                    <Alert severity="error" variant="outlined">{errorMessage}</Alert>
                </div>
            )}
            <TextField
                label="Email Address"
                type="email"
                name="email"
                variant="outlined"
                size="small"
                required={true}
                onKeyDown={e => captureEnterPress(e)}
                error={formState?.email?.error}
                helperText={formState?.email?.helperText}
                onChange={e => e.currentTarget.value && formState?.email?.error && resetFormError(e.currentTarget.name)}
            />
            <TextField
                label="Password"
                type="password"
                name="password"
                variant="outlined"
                size="small"
                required={true}
                onKeyDown={e => captureEnterPress(e)}
                error={formState?.password?.error}
                helperText={formState?.password?.helperText}
                onChange={e => e.currentTarget.value && formState?.password?.error && resetFormError(e.currentTarget.name)}
            />
            {children}
            <Button
                variant="contained"
                style={{ cursor: "pointer" }}
                onClick={handleLoginClick}
            >
                {buttonText}
            </Button>
        </Stack>
    );
}

export default EmailPassword;