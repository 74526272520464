export default class SessionService {
    public static Get = <T>(key: string): T | null => {
        let item: string | null = sessionStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }

    public static Set = <T>(key: string, value: T): void => {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    public static Clear = (): void => {
        sessionStorage.clear();
    }
}
