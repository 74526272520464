import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Stripe from 'stripe';
import AppContext, { AccountType, UpdateAccountDetails } from '../../context/AppContext';
import useQuery from '../../hooks/UseQuery';
import { Business } from '../../models/Business';
import { Employee } from '../../models/Employee';
import ApiClient from '../../services/ApiClient';

const ConfirmAccount: FC = () => {
    const [, dispatch] = useContext(AppContext);
    const navigate = useNavigate();
    let query = useQuery();

    useEffect(() => {
        const accountType: string | null = query.get("type");
        const confirmationToken: string | null = query.get("token");

        if (accountType && confirmationToken) {
            const confirmAccount = async (): Promise<void> => {
                let res = await ApiClient.GetAsync(`/api/auth/confirmAccount/${accountType}`, confirmationToken);

                if (res.ok) {
                    let verifiedUser: { user: Employee | Business, stripeAccount?: Stripe.Account, token: string, expiration: string } = await res.json();

                    dispatch(UpdateAccountDetails({
                        accountType: accountType === "employee" ? AccountType.EMPLOYEE : AccountType.BUSINESS,
                        user: verifiedUser.user,
                        stripeAccount: verifiedUser?.stripeAccount,
                        token: verifiedUser.token,
                        expiration: verifiedUser.expiration
                    }));

                    navigate('/app');
                } else {
                    console.error(`Error confirming ${accountType} -- ${res.status}`);
                }
            }

            confirmAccount();
        }
    }, [dispatch, query, navigate]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
    );
}

export default ConfirmAccount;