import * as signalR from '@microsoft/signalr';

class SignalRService {
    public _hub: signalR.HubConnection;

    constructor(hubUrl: string, token: string, logLevel?: signalR.LogLevel) {
        this._hub = new signalR.HubConnectionBuilder()
            .withUrl(hubUrl, { accessTokenFactory: () => token })
            .configureLogging(logLevel || (process.env.NODE_ENV === "development" ? signalR.LogLevel.Debug : signalR.LogLevel.Critical))
            .build();
    }

    public start = async (callback?: (hub: signalR.HubConnection) => Promise<void>) => {
        await this._hub.start().catch(err => console.error(err));

        if (callback) {
            await callback(this._hub);
        }
    }

    public stop = (): void => {
        this._hub.stop().catch(err => console.error(err));
    }

    public subscribeToEvent = (eventName: string, eventHandler: (...args: any[]) => void): void => {
        this._hub.on(eventName, eventHandler)
    }
}

export default SignalRService;