import { Typography } from '@mui/material';
import { FC, useContext } from "react";
import AppContext from "../../context/AppContext";
import { Employee } from "../../models/Employee";
import ProfileSection, { SectionDetail } from '../ProfileSection/ProfileSection';
import "./EmployeeProfile.css";

const EmployeeDetails: FC = () => {
    const [appContext] = useContext(AppContext);
    const employee = appContext.user as Employee;

    return (
        <div style={{ marginBottom: '75px' }}>
            {/* <Button variant='contained' sx={{ marginBottom: '15px' }}>Update profile picture</Button> */}

            <ProfileSection sectionTitle='Basic details'>
                <SectionDetail label="Name" value={`${employee.firstName} ${employee.lastName}`} />
                <SectionDetail label="Employer" value={employee.business.businessName} />
                {/* <SectionDetail label="Role" value={UserRoles[employee.employeeDetails.role]} /> */}
                <SectionDetail label="Address">
                    <Typography variant='body1'>{employee.address.street}</Typography>
                    <Typography variant='body1'>{employee.address.city} {employee.address.state}, {employee.address.zip}</Typography>
                </SectionDetail>
            </ProfileSection>

            <ProfileSection sectionTitle='Contact info'>
                <SectionDetail label="Email address" value={employee.employeeDetails.emailAddress} />
                <SectionDetail label="Phone number" value={employee.employeeDetails.phoneNumber} />
            </ProfileSection>

            <ProfileSection sectionTitle='Connected Account'>
                <SectionDetail label='Bank' value={employee.bankingInstitution} />
                <SectionDetail label='Account type' value={`${employee.bankAccountType.charAt(0).toUpperCase()}${employee.bankAccountType.slice(1)}`} />
                <SectionDetail label='Account last 4 digits' value={employee.bankAccountLastFourDigits} />
            </ProfileSection>
        </div>
    )
}

export default EmployeeDetails;