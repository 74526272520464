import IosShareIcon from '@mui/icons-material/IosShare';
import { FC, useEffect, useState } from 'react';
import SessionService from '../../services/SessionService';
import './iOSPrompt.css';

const IOSPrompt: FC<{ promptName: string, visibleDuration: number }> = ({ promptName, visibleDuration }) => {
    const [showPrompt, setShowPrompt] = useState<boolean>(false);

    useEffect(() => {
        const isIos = () => {
            return /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
        }

        const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;

        if (!SessionService.Get<boolean>(`${promptName}-prompt`) && isIos() && !isInStandaloneMode()) {
            setShowPrompt(true);
            SessionService.Set<boolean>(`${promptName}-prompt`, true);
            setTimeout(() => setShowPrompt(false), visibleDuration);
        }
    }, [visibleDuration, promptName]);

    return (
        <>
            {
                showPrompt && (
                    <div className='ios-prompt bounce' style={{
                        position: 'fixed',
                        bottom: '1%',
                        right: '1vw'
                    }}>
                        <div className='content'>
                            Install this webapp on your iPhone! Tap the <IosShareIcon /> and then "Add to Home Screen".
                        </div>
                        <div className='arrow' />
                    </div>
                )
            }
        </>
    );
}

export default IOSPrompt;