import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Snackbar from "@mui/material/Snackbar";
import { FC, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { EmailPassword } from "..";
import AppContext, { AccountType, UpdateAccountDetails } from "../../context/AppContext";
import AuthService from "../../services/AuthService";

const Login: FC = (props) => {
    const [appContext, dispatch] = useContext(AppContext);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [selectedForm, setSelectedForm] = useState<string>("employee");
    const [loading, setLoading] = useState<boolean>(false);
    const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state?.passwordChanged) {
            setPasswordChanged(true);
        }
    }, [location.state?.passwordChanged]);

    const handleEmailPasswordFormSubmit = async (email: string, password: string): Promise<void> => {
        await login(email, password);
    }

    const login = async (email: string, password: string) => {
        setLoading(true);
        setErrorMessage("");

        try {
            const verifiedUser = await AuthService.LoginUser({
                email,
                password,
                accountType: selectedForm
            }, appContext.auth.token);

            dispatch(UpdateAccountDetails({
                accountType: selectedForm === "employee" ? AccountType.EMPLOYEE : AccountType.BUSINESS,
                user: verifiedUser.user,
                stripeAccount: verifiedUser?.stripeAccount,
                token: verifiedUser.token,
                expiration: verifiedUser.expiration
            }));

            setLoading(false);

            navigate("/app");
        } catch (ex: any) {
            setErrorMessage(ex.message);
            setLoading(false);
        }
    }

    const handleSnackbarClosed = () => {
        setPasswordChanged(false);
    }

    return (
        <>
            {
                errorMessage && (
                    <div style={{ width: "95vw", margin: "auto", marginBottom: "15px" }}>
                        <Alert severity="error" variant="outlined">{errorMessage}</Alert>
                    </div>
                )}
            {
                !loading ? (
                    <>
                        <div style={{ textAlign: "left", width: "95vw", margin: "auto", marginBottom: "15px" }}>
                            <h3 className="details-header">ACCOUNT TYPE</h3>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="Account type"
                                    defaultValue="employee"
                                    name="account-type-group"
                                    onChange={e => setSelectedForm(e.target.value)}
                                >
                                    <FormControlLabel
                                        value="employee"
                                        control={<Radio />}
                                        label="Employee"
                                    />
                                    <FormControlLabel
                                        value="business"
                                        control={<Radio />}
                                        label="Business"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <EmailPassword buttonText="LOGIN" onSubmit={handleEmailPasswordFormSubmit}>
                            <p>
                                <Link to="/app/signup" style={{ textDecoration: "none" }}>Signup</Link> or <Link to='/app/forgotPassword' style={{ textDecoration: 'none' }}>Forgot password</Link>
                            </p>
                        </EmailPassword>
                    </>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )
            }


            <Snackbar open={passwordChanged} autoHideDuration={4000} onClose={handleSnackbarClosed}>
                <Alert severity="success" sx={{ width: '100%' }}>Password successfully updated!</Alert>
            </Snackbar>
        </>
    );
}

export default Login;