import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Component, ErrorInfo } from 'react';

export default class SubscriptionErrorBoundary extends Component<{ children?: React.ReactNode }, { hasError: boolean }> {
    constructor(props: { children?: React.ReactNode } | Readonly<{}>) {
        super(props);
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error: any) {
        console.log(`Error from getDerivedStateFromError: ${error}`);
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error(error.message);
    }

    render() {
        return (
            <>
                {this.props.children}
                <Dialog
                    open={this.state.hasError}
                    onClose={() => this.setState({ hasError: false })}
                    aria-labelledby="subscription-alert-title"
                    aria-describedby="subscription-alert-description"
                >
                    <DialogTitle id="subscription-alert-title">
                        Payment required
                    </DialogTitle>
                    <DialogContent style={{ paddingBottom: '10px' }}>
                        <DialogContentText id="subscription-alert-description" variant='body2'>
                            Please update your banking information or make a payment to continue using TipSplit.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
                        <Button variant='contained'>Make payment</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

