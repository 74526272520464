import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScrollToTop } from '../../hooks/UseScrollToTop';
import { Footer } from '../HomePage/HomePage';
import './PrivacyPage.css';


const PrivacyPage: FC = () => {
    useScrollToTop();

    const navigate = useNavigate();

    return (
        <>
            <div style={{ textAlign: 'left', width: '95vw', margin: 'auto' }}>
                <ArrowBackIcon onClick={() => navigate(-1)} color='primary' style={{ position: 'absolute' }} />
                <div style={{ textAlign: 'center' }}>
                    <h1 style={{ marginBottom: '0' }}>TipSplit</h1>
                    <h2 style={{ marginTop: '0' }}>Privacy Policy</h2>
                </div>

                <p>TipSplit is committed to protecting the privacy of our users. This privacy policy explains how we collect, use, and disclose information about our users.</p>

                <h2>Information We Collect</h2>
                <p>We collect information from businesses and employees when they create accounts on our platform. This includes name, email address, and bank account information through Plaid. We do not store any bank account details in our system, only tokens that represent bank accounts. We also collect information about events scheduled on our platform, including the date, time, and location of the event.</p>

                <h2>Use of Information</h2>
                <p>We use the information we collect to provide our services to businesses and employees. This includes processing payments and withdrawals through third-party companies, such as Stripe and Dwolla. We may also use information to improve our services and communicate with our users.</p>

                <h2>Disclosure of Information</h2>
                <p>We do not sell or rent any of our users' personal information to third parties. We may disclose information to third-party companies, such as Stripe and Dwolla, to process payments and withdrawals. We may also disclose information if required by law or to protect the rights, property, or safety of TipSplit, our users, or others.</p>

                <h2>Security</h2>
                <p>We take reasonable measures to protect the security of our users' information. We use industry-standard encryption to protect data in transit and at rest. We also restrict access to user information to only those employees who require access to provide our services.</p>

                <h2>Third-Party Links</h2>
                <p>Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites.</p>

                <h2>Children's Privacy</h2>
                <p>Our services are not directed at children under the age of 18. We do not knowingly collect personal information from children under 18. If you believe that we have collected personal information from a child under 18, please contact us immediately.</p>

                <h2>Changes to Privacy Policy</h2>
                <p>We may update this privacy policy from time to time. We will notify users of any material changes to this policy. Your continued use of our services after any changes to this policy constitutes your acceptance of the updated policy.</p>

                <h2>Contact Us</h2>
                <p>If you have any questions or concerns about this privacy policy or our data practices, please contact us at [insert contact information].</p>

            </div>
            <Footer />
        </>
    );
}

export default PrivacyPage;