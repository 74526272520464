import Typography from '@mui/material/Typography';
import { FC } from 'react';

const SiteMaintenance: FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <>
            {
                process.env.NODE_ENV === 'development' ? children : (
                    <div style={{ marginTop: '15px' }}>
                        <div style={{ margin: '25px auto', width: '150px' }}>
                            <img
                                src='/tipjar.svg'
                                alt='TipSplit logo. Tip jar with cash and change in it with the word TipSplit across the front of the TipJar'
                            />
                        </div>
                        <Typography variant='h4' sx={{ textAlign: 'center', display: 'block', marginBottom: '25px' }}>
                            Site under maintenance
                        </Typography>

                        <Typography variant='body1' sx={{ textAlign: 'center', marginBottom: '10px' }}>
                            We are working diligently to make TipSplit better for our customers.
                        </Typography>

                        <Typography variant='body1' sx={{ textAlign: 'center', marginBottom: '25px' }}>
                            Please check back soon for exciting updates!
                        </Typography>
                    </div >
                )
            }
        </>
    );
}

export default SiteMaintenance;
