import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import { FC, useContext, useEffect, useState } from "react";
import AppContext, { DeleteLocation } from "../../context/AppContext";
import { Business, BusinessLocation } from "../../models/Business";
import ApiClient from '../../services/ApiClient';
import TipService from "../../services/TipService";
import EditLocationForm from './EditLocationForm';
import './Locations.css';

const Location: FC<{ location: BusinessLocation }> = ({ location }) => {
    const [appContext, dispatch] = useContext(AppContext);
    const [totalTipsReceived, setTotalTipsReceived] = useState<number>(location.receivedTips?.reduce((total, tip) => total += tip.amount, 0) || 0);
    const [editLocationOpen, setEditLocationOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        setTotalTipsReceived(location.receivedTips?.reduce((total, tip) => total += tip.amount, 0) || 0);
    }, [location.receivedTips]);

    const handleDeleteClose = (): void => {
        setDeleteDialogOpen(false);
    }

    const deleteLocation = async (): Promise<void> => {
        let res = await ApiClient.DeleteAsync(`/api/locations/${location.id}`, appContext.auth.token);

        if (!res.ok) {
            if (res.status === 401) {
                console.error(`${(appContext.user as Business).businessName} does not have access to delete ${location.locationName} (${location.id})`);
            } else if (res.status === 404) {
                console.error(`${location.id} was not found and was not deleted`);
            }
        } else {
            // remove event
            dispatch(DeleteLocation(location.id));
            handleDeleteClose();
        }
    }

    return (
        <>
            <Card
                className="location"
                key={`location-${location.id}`}
                variant="outlined"
                sx={{
                    maxWidth: "95vw",
                    margin: "auto",
                    textAlign: "left",
                    marginBottom: "10px",
                    position: 'relative'
                }}
            >
                <CardContent>
                    <DeleteIcon
                        onClick={() => setDeleteDialogOpen(true)}
                        sx={{
                            position: 'absolute',
                            right: '2%',
                            top: '4%'
                        }}
                    />
                    <EditIcon
                        onClick={() => setEditLocationOpen(true)}
                        sx={{
                            position: 'absolute',
                            right: '10%',
                            top: '4%'
                        }}
                    />
                    <Typography variant="h6" component="div" color="text.secondary">
                        {location.locationName}
                    </Typography>
                    <Typography variant="body2" style={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {location.address.street}, {location.address.city}, {location.address.state} {location.address.zip}
                    </Typography>
                    <Typography variant="body2">
                        Total tips received: {TipService.FormatTip(totalTipsReceived)}
                    </Typography>
                </CardContent>
            </Card>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteClose}
                aria-labelledby='delete-dialog-title'
                aria-describedby='delete-dialog-description'
            >
                <DialogTitle id='delete-dialog-title'>
                    Delete Location?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='delete-dialog-description'>
                        Are you sure you want to delete location {location.locationName}? You will not be able to undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose}>CANCEL</Button>
                    <Button onClick={deleteLocation}>DELETE</Button>
                </DialogActions>
            </Dialog>
            <EditLocationForm
                location={location}
                editLocationOpen={editLocationOpen}
                setEditLocationOpen={setEditLocationOpen}
            />
        </>
    )
}

export default Location;