import { Business } from "../models/Business";
import { Employee } from "../models/Employee";

const getInputValueByName = (name: string, containerId?: string): string => {
    let selector: string = `${containerId ? `#${containerId}` : ""} input[name="${name}"]`.trim();

    return (document.querySelector(selector) as HTMLInputElement).value;
}

const getInputValuesByName = (names: string[], containerId?: string) => {
    return names.reduce((result, name) => {
        result[name] = getInputValueByName(name, containerId);
        return result;
    }, {} as { [key: string]: string })
}

const IsEmployee = (user: Employee | Business): user is Employee => {
    return (user as Employee).firstName !== undefined;
}

const formatPhoneNumber = (phoneNumber: string): string => {
    let cleanedPhoneNumber: string = phoneNumber.replace(/\D/g, '').trim();
    let match = cleanedPhoneNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return `(${match[2]}) ${match[3]}-${match[4]}`;
    }

    return '';
}

const cleanPhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replace(/\D/g, '');
}

export { getInputValueByName, getInputValuesByName, IsEmployee, formatPhoneNumber, cleanPhoneNumber };

