import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import TextsmsIcon from '@mui/icons-material/Textsms';
import Alert from "@mui/material/Alert";
import Avatar from '@mui/material/Avatar';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FC, KeyboardEvent, SyntheticEvent, useContext, useState } from "react";
import AppContext from "../../context/AppContext";
import { Business } from "../../models/Business";
import { EmployeeDetails } from '../../models/Employee';
import ApiClient from "../../services/ApiClient";
import { cleanPhoneNumber, formatPhoneNumber } from "../../utils/Helpers";

const Employees: FC<{ employees: EmployeeDetails[] | undefined }> = ({ employees }) => {
    const [appContext] = useContext(AppContext);
    const [email, setEmail] = useState<string>("");
    const [inviteSent, setInviteSent] = useState<boolean>(false);
    const [sendingInvite, setSendingInvite] = useState<boolean>(false);

    const sendInviteEmail = async (): Promise<void> => {
        setSendingInvite(true);

        let res = await ApiClient.PostAsync('/api/email/sendInvite', {
            email,
            businessName: (appContext.user as Business).businessName
        }, appContext?.auth?.token);

        if (res.ok) {
            // set email sent
            setSendingInvite(false);
            setEmail('');
            setInviteSent(true);
        } else {
            // show errors
        }
    }

    const handleSnackbarClose = (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        setInviteSent(false);
    }

    const captureEnterPress = async (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            await sendInviteEmail();
        }
    }

    return (
        <div className="employees-container" style={{ marginBottom: "75px" }}>
            <div className='send-invite-container'>
                {
                    !sendingInvite ? (
                        <>
                            <TextField
                                label="Email Address"
                                type="email"
                                name="email"
                                variant="outlined"
                                size="small"
                                required={true}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                onKeyDown={e => captureEnterPress(e)}
                                sx={{ flexGrow: 1, marginRight: '10px' }}
                            />
                        </>
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    )
                }
                <Button
                    variant='outlined'
                    size='small'
                    className='send-invite-button'
                    onClick={() => sendInviteEmail()}
                >
                    SEND INVITE
                </Button>
            </div>
            {
                employees?.map(employee => (
                    <EmployeeDetail key={employee.id} employee={employee} />
                ))
            }
            <Snackbar
                open={inviteSent}
                autoHideDuration={2500}
                onClose={handleSnackbarClose}
            >
                <Alert elevation={6} severity='success' variant='filled' sx={{ width: '100%' }}>
                    Invite sent!
                </Alert>
            </Snackbar>
        </div>
    );
}

const EmployeeDetail: FC<{ employee: EmployeeDetails }> = ({ employee }) => {
    const [appContext] = useContext(AppContext);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const handleDeleteClick = (): void => {
        setDeleteDialogOpen(true);
    }

    const handleDeleteClose = (): void => {
        setDeleteDialogOpen(false);
    }

    const deleteEmployee = async (): Promise<void> => {
        let res = await ApiClient.DeleteAsync(`/api/employees/${employee.id}`, appContext.auth.token);

        if (!res.ok) {
            if (res.status === 401)
                console.error(`Business ${appContext.user.id} does not have access to delete employee ${employee.id}.`);
            else if (res.status === 404)
                console.error(`Employee ${employee.id} not found.`);
            else if (res.status === 500)
                console.error('Internal server error.', await res.json());
        } else {
            setDeleteDialogOpen(false);
        }
    }

    const handlePhoneIconClick = (phoneNumber: string): void => {
        window.location.href = `tel:${cleanPhoneNumber(phoneNumber)}`;
    }

    const handleSmsIconClick = (phoneNumber: string): void => {
        window.location.href = (`sms:${cleanPhoneNumber(phoneNumber)}`);
    }

    const handleEmailIconClick = (email: string): void => {
        window.location.href = (`mailto:${email}?subject=New TipSplit message from ${(appContext.user as Business).businessName}`);
    }

    return (
        <>
            <Card
                className="employee"
                key={`location-${employee.id}`}
                variant="outlined"
                sx={{
                    maxWidth: "95vw",
                    margin: "auto",
                    textAlign: "left",
                    marginBottom: "10px",
                    position: 'relative'
                }}
            >
                <DeleteIcon onClick={handleDeleteClick} sx={{ position: 'absolute', right: '3%', top: '3%' }} color='primary' />
                <CardContent>
                    <Typography variant="h6" component="div" color="text.secondary">
                        {employee.firstName} {employee.lastName}
                    </Typography>
                    <Typography variant="body2" style={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {employee.address.street}, {employee.address.city}, {employee.address.state} {employee.address.zip}
                    </Typography>
                    <Typography variant='body2'>
                        {formatPhoneNumber(employee.phoneNumber)}
                    </Typography>
                    <Typography variant="body2">
                        {employee.emailAddress}
                    </Typography>
                    <div style={{ display: 'flex', margin: '15px auto 0', width: '85%' }}>
                        <Avatar onClick={() => handlePhoneIconClick(employee.phoneNumber)}>
                            <PhoneIcon color='primary' />
                        </Avatar>
                        <Avatar onClick={() => handleSmsIconClick(employee.phoneNumber)} sx={{ marginLeft: 'auto' }}>
                            <TextsmsIcon color='primary' />
                        </Avatar>
                        <Avatar onClick={() => handleEmailIconClick(employee.emailAddress)} sx={{ marginLeft: 'auto' }}>
                            <EmailIcon color='primary' />
                        </Avatar>
                    </div>
                </CardContent>
            </Card>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteClose}
                aria-labelledby='delete-dialog-title'
                aria-describedby='delete-dialog-description'
            >
                <DialogTitle id='delete-dialog-title'>
                    Delete Employee?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='delete-dialog-description'>
                        Are you sure that you want to delete your employee {employee.firstName} {employee.lastName}? You will not be able to undo this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose}>CANCEL</Button>
                    <Button onClick={deleteEmployee}>DELETE</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Employees;