import { useQuery } from '@tanstack/react-query';
import { FC, useContext, useEffect, useReducer } from 'react';
import AppContext from '../../context/AppContext';
import { BusinessLocation } from '../../models/Business';
import { EmployeeDetails } from '../../models/Employee';
import { Exception } from '../../models/ExceptionTypes';
import { InputError } from '../../models/InputError';
import { TSEvent } from '../../models/TSEvent';
import ApiClient from '../../services/ApiClient';
import Calendar from './Calendar';
import DaysEvents from './DaysEvents';
import NewEventForm from './NewEventForm';
import './Schedule.css';
import { ScheduleDay, ScheduleReducer, ScheduleState } from './ScheduleReducer';

const NUM_OF_DAYS: number = 42;
const DAYS: string[] = ['SUN', 'MON', 'TUES', 'WED', 'THUR', 'FRI', 'SAT'];

const Schedule: FC<{ employees: EmployeeDetails[] | undefined, locations: BusinessLocation[] | undefined }> = ({ employees, locations }) => {
    const [appContext] = useContext(AppContext);

    let today = new Date();
    today.setHours(12, 0, 0, 0);
    let initialNewEventEndDate = new Date(+today);
    initialNewEventEndDate.setHours(16, 0, 0, 0);

    const [scheduleState, dispatch] = useReducer(ScheduleReducer, {
        businessEvents: [] as TSEvent[],
        calendarDays: [] as ScheduleDay[],
        selectedDay: today,
        selectedMonth: today,
        newEventTitle: '',
        newEventOpen: false,
        newEventEmployees: [] as string[],
        newEventStartDate: today,
        newEventEndDate: initialNewEventEndDate,
        newEventEstimatedTotalGuests: '',
        newEventFormState: {} as { [key: string]: InputError },
        editingEvent: false,
        touchStart: 0,
        touchEnd: 0
    } as ScheduleState);

    useQuery({
        queryKey: ['business-events'],
        queryFn: async () => {
            const res = await ApiClient.GetAsync('/api/business/events', appContext.auth.token);

            if (!res.ok) {
                throw new Exception('There was an error retrieving business events.')
            }

            const businessEvents = await res.json();
            dispatch({ type: 'BUSINESS_EVENTS', businessEvents });

            return businessEvents;
        }
    });

    // Update calendar grid values when selected day
    // or selected month changes & when new events
    // are created 
    useEffect(() => {
        const equalDays = (a: Date, b: Date): boolean => {
            let aCompare: Date = new Date(a);
            let bCompare: Date = new Date(+b);

            aCompare.setHours(12, 0, 0, 0);
            bCompare.setHours(12, 0, 0, 0);

            return +aCompare === +bCompare;
        }

        const buildCalendarDays = () => {
            let calendarDay: Date = new Date(+scheduleState.selectedMonth);
            calendarDay.setDate(1);
            calendarDay.setHours(12, 0, 0, 0);

            // Set first day of calendar to minus
            // day of week the 1st day of the month falls  
            // on to show days of previous month
            calendarDay.setDate(calendarDay.getDate() - calendarDay.getDay());

            let days = [];
            for (let i: number = 0; i < NUM_OF_DAYS; i++) {
                days.push({
                    id: i,
                    selected: +scheduleState.selectedDay === +calendarDay,
                    dayOfMonth: calendarDay.getDate(),
                    day: DAYS[calendarDay.getDay()],
                    date: new Date(+calendarDay),
                    events: scheduleState?.businessEvents?.filter(e => equalDays(e?.startDate, calendarDay))
                });

                calendarDay.setDate(calendarDay.getDate() + 1);
            }

            dispatch({ type: 'CALENDAR_DAYS', calendarDays: days });
        }

        buildCalendarDays();
    }, [scheduleState.selectedDay, scheduleState.selectedMonth, scheduleState.businessEvents]);

    return (
        <div className='schedule'>
            <Calendar scheduleState={scheduleState} dispatch={dispatch} today={today} />
            <DaysEvents scheduleState={scheduleState} dispatch={dispatch} locations={locations} />
            <NewEventForm scheduleState={scheduleState} dispatch={dispatch} employees={employees} locations={locations} />
        </div>
    );
}

export default Schedule;