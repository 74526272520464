import Avatar from "@mui/material/Avatar";
import { FC } from "react";

const ProfilePicture: FC<{ className?: string, src: string, onClick?: React.MouseEventHandler<HTMLDivElement> }> = ({ className, src, onClick }) => {
    return (
        <div className={className} onClick={onClick}>
            <Avatar src={src} sx={{ height: '100%', width: '100%' }} />
        </div>
    )
}

export default ProfilePicture;