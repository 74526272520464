import QrCode2Icon from '@mui/icons-material/QrCode2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, MouseEvent, useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import useAsyncError from "../../hooks/UseAsyncError";
import usePersistentState from "../../hooks/UsePersistentState";
import { BusinessLocation } from '../../models/Business';
import { Employee } from "../../models/Employee";
import { TSEvent } from '../../models/TSEvent';
import { Tip } from "../../models/Tip";
import ApiClient from '../../services/ApiClient';
import SignalRService from "../../services/SignalRService";
import TipService from "../../services/TipService";
import "./EmployeeProfile.css";

const EmployeeEvent: FC<{ event: TSEvent, location: BusinessLocation, activeEvent: boolean, tips: Tip[] }> =
    ({ event, location, activeEvent, tips }) => {
        const [appContext] = useContext(AppContext);
        const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
        const [qrOpen, setQrOpen] = useState<boolean>(false);
        const [eventQRCode, setEventQRCode] = useState<string>("");
        const throwSubscriptionError = useAsyncError();
        const [liveTips, setLiveTips] =
            usePersistentState<{ tips: Tip[], total: number }>(
                `event-${event.id}-live-tips`,
                {
                    tips,
                    total: tips.reduce((total, tip) => total += tip.amount - tip.applicationFee, 0.00)
                }
            );

        useEffect(() => {
            const signalRService = new SignalRService('/hubs/TipHub', appContext.auth.token);

            signalRService.start(async hub => {
                await hub.invoke('addToTipGroup', event.id);
            });

            signalRService.subscribeToEvent('groupUpdate', (message: string) => {
                console.log(message);
            });

            signalRService.subscribeToEvent('tipReceived', (tip: Tip) => {
                setLiveTips(prevState => {
                    return {
                        tips: [...prevState.tips, tip],
                        total: prevState.total += tip.amount - tip.applicationFee
                    };
                });
            });

            return () => {
                signalRService.stop();
            }
        }, []);

        const handleQRIconClick = async (e: MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();

            const res: Response = await ApiClient.GetQRCode(event.id!, appContext.auth.token);

            if (res.ok) {
                setQrOpen(true);
                setEventQRCode(URL.createObjectURL(await res.blob()));
            } else if (res.status === 402) {
                throwSubscriptionError(new Error(`${(appContext.user as Employee).business.businessName} does not have an active subscription. Please contact your business owner to resolve this issue.`));
            } else {
                console.error(await res.json());
            }
        }

        const handleEventClick = (e: React.MouseEvent<HTMLDivElement>) => {
            let eventNotes = e.currentTarget.querySelector(".event-notes") as HTMLDivElement;
            let eventNoteQrIcon = e.currentTarget.querySelector(".qr-icon") as HTMLDivElement;
            let activeIcon = e.currentTarget.querySelector('.active-icon') as HTMLDivElement;

            if (activeIcon) {
                activeIcon.classList.toggle('active-icon-close');
                activeIcon.classList.toggle('active-icon-open');
            }

            eventNotes.classList.toggle("event-notes-hidden");
            eventNotes.classList.toggle("event-notes-visible");

            eventNoteQrIcon.classList.toggle("qr-icon-hidden");
            eventNoteQrIcon.classList.toggle("qr-icon-visible");
        }

        const formatTime = (date: Date | string): string => {
            if (typeof (date) === "string") {
                date = new Date(date);
            }

            return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
        }

        const formatDate = (date: Date | string): number => {
            if (typeof (date) === "string")
                date = new Date(date);

            return date.getDate();
        }

        return (
            <Card
                className="event"
                onClick={e => handleEventClick(e)}
                variant="outlined"
                sx={{
                    maxWidth: "95vw",
                    margin: "auto",
                    textAlign: "left",
                    marginBottom: "10px"
                }}
                key={`event-${event.id}`}
            >
                <CardContent>
                    {
                        activeEvent && <div className='active-icon pulsing active-icon-close' />
                    }
                    <div onClick={e => handleQRIconClick(e)} className="qr-icon qr-icon-hidden">
                        <QrCode2Icon />
                    </div>
                    <Typography variant="body2" className="day-of-month">
                        {formatDate(event.startDate)}
                    </Typography>
                    <Typography variant="h6" component="div" color="text.secondary" className="event-title">
                        {event.eventTitle}
                    </Typography>
                    <Typography variant="body2">
                        {formatTime(event.startDate)} - {formatTime(event.endDate)}
                    </Typography>
                    <Typography variant="body2">
                        {event.eventAddress}
                    </Typography>
                    <div className="event-notes event-notes-hidden">
                        <Typography variant="body1" color="text.secondary" className="title">
                            Location:
                        </Typography>
                        <Typography variant="body2">
                            {location.locationName}
                        </Typography>
                        {event.eventNotes && (
                            <>
                                <Typography variant="body1" color="text.secondary" className="title">
                                    Event notes:
                                </Typography>
                                <Typography variant="body2">
                                    {event.eventNotes}
                                </Typography>
                            </>
                        )}
                    </div>
                    <Dialog onClick={() => setQrOpen(false)} open={qrOpen} fullScreen={fullScreen}>
                        <DialogContent>
                            <Typography variant="h3" color="text.secondary" textAlign="center">
                                {event.eventTitle}
                            </Typography>
                            <div style={{
                                background: `center / contain no-repeat url("${eventQRCode}") `,
                                height: "80%",
                                width: "100%"
                            }}
                            />
                        </DialogContent>
                    </Dialog>
                    {
                        activeEvent && <div className="event-tip-total">{TipService.FormatTip(liveTips.total)}</div>
                    }
                </CardContent>
            </Card>
        )
    }

export default EmployeeEvent;