import { Box, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from '@mui/material/Paper';
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import { FC, useContext, useState } from "react";
import AppContext from "../../context/AppContext";
import { Business } from "../../models/Business";
import Disclaimer from '../Disclaimer/Disclaimer';

const StripePriceLookupKey: { [key: string]: string } = {
    smallCompany: 'SmallCompany',
    mediumCompany: 'MediumCompany',
    largeCompany: 'LargeCompany'
}

const BusinessSubscription: FC = () => {
    const [appContext] = useContext(AppContext);
    const business: Business = appContext.user as Business;
    const [subscriptionLevel, setSubscriptionLevel] = useState<string>(StripePriceLookupKey.smallCompany);
    const [redirecting, setRedirecting] = useState<boolean>(false);

    const handleSubscriptionLevelSelection = (e: React.MouseEvent<HTMLDivElement>, lookupKey: string) => {
        setSubscriptionLevel(lookupKey);
        setRedirecting(true);

        document.querySelector('.company-sizes .selected')?.classList.remove('selected');

        e.currentTarget.classList.add('selected');
    }

    const handleContinueToSubscriptionClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        let formData = new FormData(document.querySelector('#subscription-level-form') as HTMLFormElement);

        let res = await fetch('/api/stripe/createCheckoutSession', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${appContext.auth.token}`
            },
            body: formData
        });

        if (res.ok) {
            let sessionUrl = res.headers.get('Location');

            if (sessionUrl)
                window.location.href = sessionUrl
            else
                console.error('No location header set in response object');
        } else
            console.error(`Unable to create new subscription session for new business -- ${await res.json()}`);

    }

    return (
        <>
            {
                !redirecting ? (
                    <Stack spacing={2} style={{ display: 'flex', width: '95vw', margin: 'auto' }}>
                        <h3 className="details-header">COMPANY SIZE</h3>
                        <div className='company-sizes'>
                            <Paper
                                elevation={6}
                                className='company-size selected'
                                onClick={e => handleSubscriptionLevelSelection(e, StripePriceLookupKey.smallCompany)}
                            >
                                <Typography variant='body1'>Small</Typography>
                                <Typography variant='caption'>1 to 10 Employees</Typography>
                            </Paper>
                            <Paper
                                elevation={6}
                                className='company-size'
                                onClick={e => handleSubscriptionLevelSelection(e, StripePriceLookupKey.mediumCompany)}
                            >
                                <Typography variant='body1'>Medium</Typography>
                                <Typography variant='caption'>11 to 25 Employees</Typography>
                            </Paper>
                            <Paper
                                elevation={6}
                                className='company-size'
                                onClick={e => handleSubscriptionLevelSelection(e, StripePriceLookupKey.largeCompany)}
                            >
                                <Typography variant='body1'>Large</Typography>
                                <Typography variant='caption'>26 to 50 Employees</Typography>
                            </Paper>
                        </div>
                        <Disclaimer>
                            <p>
                                We use Stripe to manage your subscription, collect payments from your guests, and make sure your employees get paid on time. By using Stripe we ensure that all of your information remains safe and doesn't fall in to the right hands. Please select a subscription level above to get started.
                            </p>
                        </Disclaimer>
                        <form id='subscription-level-form'>
                            <input type='hidden' name='business_email' value={business.businessDetails.emailAddress} />
                            <input type='hidden' name='business_id' value={business.id} />
                            <input type='hidden' name='lookup_key' value={subscriptionLevel} />
                            <Button
                                onClick={handleContinueToSubscriptionClick}
                                variant='contained'
                                style={{ marginTop: '0', width: '100%' }}
                            >
                                CONTINUE TO SUBSCRIPTION SETUP
                            </Button>
                        </form>
                    </Stack>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )
            }
        </>
    )
}

export default BusinessSubscription;