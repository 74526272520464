import { getInputValuesByName } from "../../utils/Helpers";

export const getAddressData = (addressContainer?: string) => {
    const addressData = getInputValuesByName(["address", "city", "state", "zipCode"], addressContainer);

    return {
        street: addressData.address,
        city: addressData.city,
        state: addressData.state,
        zip: addressData.zipCode,
        country: "US"
    };
}