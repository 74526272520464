import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext, { AccountType, UpdateAccountDetails } from '../../context/AppContext';
import useQuery from '../../hooks/UseQuery';
import ApiClient from '../../services/ApiClient';

const SubscriptionSuccess: FC = () => {
    const query = useQuery();
    const [appContext, dispatch] = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        const getBusiness = async (sessionId: string) => {
            let res = await ApiClient.GetAsync(`/api/stripe/getStripeBusiness/${sessionId}`, appContext?.auth?.token);

            if (res.ok) {
                let verifiedBusiness = await res.json();

                dispatch(UpdateAccountDetails({
                    accountType: AccountType.BUSINESS,
                    user: verifiedBusiness.user,
                    token: verifiedBusiness.token,
                    expiration: verifiedBusiness.expiration
                }));

                navigate('/app');
            } else {
                console.error(`There was an error retrieving business using session id: ${sessionId}`);
            }
        }

        if ((query.get('success') && query.get('success') === 'true') || (query.get('canceled') && query.get('canceled') === 'true')) {
            let sessionId: string | null = query.get('session_id');

            if (sessionId) {
                getBusiness(sessionId);
            }
        }
    }, [appContext?.auth?.token, appContext?.user?.id, dispatch, query, navigate]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
    );
}

export default SubscriptionSuccess;