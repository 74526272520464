import { Dispatch, useEffect, useState } from "react";
import SessionService from "../services/SessionService";

const usePersistentState = <T>(key: string, initialState: T): [T, Dispatch<React.SetStateAction<T>>] => {
    const [state, setState] = useState<T>(SessionService.Get<T>(key) || initialState);

    useEffect(() => {
        SessionService.Set(key, state);
    }, [key, state]);

    return [state, setState];
}

export default usePersistentState;