export interface BasicUserDetails {
    emailAddress: string;
    password: string;
    phoneNumber: string;
    role: UserRoles;
}

export enum UserRoles {
    Administrator,
    BusinessOwner,
    Employee,
    AuthenticatedUser,
    Guest
}