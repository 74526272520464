import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import AppContext, { AddLocation } from "../../context/AppContext";
import { InputError } from '../../models/InputError';
import ApiClient from '../../services/ApiClient';
import { getInputValueByName } from '../../utils/Helpers';
import { getAddressData } from '../AddressForm/AddressForm';
import StateSelect from '../StateSelect/StateSelect';
import { resetLocationFormError, validateLocationDetails } from './LocationHelpers';

const NewLocationForm: FC<{ newLocationOpen: boolean, setNewLocationOpen: Dispatch<SetStateAction<boolean>> }>
    = ({ newLocationOpen, setNewLocationOpen }) => {
        const [appContext, dispatch] = useContext(AppContext);
        const [locationFormState, setLocationFormState] = useState<{ [key: string]: InputError }>({});
        const [locationFormErrorMessage, setLocationFormErrorMessage] = useState<string>("");
        const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

        const createLocation = async (): Promise<void> => {
            const [validForm, locationFormErrors] = validateLocationDetails();

            if (validForm) {
                const locationName = getInputValueByName("locationName");
                let location = {
                    locationName,
                    address: getAddressData("locationAddress"),
                    businessId: appContext.user.id
                }

                let res = await ApiClient.PostAsync('/api/locations', location, appContext.auth.token);

                if (!res.ok) {
                    setLocationFormErrorMessage('There was an error creating a new location. Please try again later.');
                } else {
                    dispatch(AddLocation(await res.json()));
                    setNewLocationOpen(false);
                }

            } else {
                setLocationFormErrorMessage('Please fill out the required fields');
                setLocationFormState(locationFormErrors);
            }

        }

        return (
            <Dialog
                onClose={() => setNewLocationOpen(false)}
                open={newLocationOpen}
                fullScreen={fullScreen}
            >
                <DialogContent>
                    <Stack spacing={2} style={{ display: 'flex' }}>
                        <h3 className="details-header">CREATE NEW LOCATION</h3>
                        {locationFormErrorMessage && (
                            <div style={{ width: "95vw", margin: "auto", marginTop: "15px" }}>
                                <Alert severity="error" variant="outlined">{locationFormErrorMessage}</Alert>
                            </div>
                        )}
                        <TextField
                            label="Location Name"
                            name="locationName"
                            type="text"
                            variant="outlined"
                            size="medium"
                            required={true}
                            error={locationFormState?.locationName?.error}
                            helperText={locationFormState?.locationName?.helperText}
                            onChange={e => e.currentTarget.value && locationFormState?.locationName?.error && resetLocationFormError(e.currentTarget.name, locationFormState, setLocationFormState)}
                        />
                        <Stack id="locationAddress" spacing={2}>
                            <TextField
                                label="Address"
                                name="address"
                                type="text"
                                variant="outlined"
                                size="medium"
                                required={true}
                                error={locationFormState?.address?.error}
                                helperText={locationFormState?.address?.helperText}
                                onChange={e => e.currentTarget.value && locationFormState?.address?.error && resetLocationFormError(e.currentTarget.name, locationFormState, setLocationFormState)}
                            />
                            <TextField
                                label="City"
                                name="city"
                                type="text"
                                variant="outlined"
                                size="medium"
                                required={true}
                                error={locationFormState?.city?.error}
                                helperText={locationFormState?.city?.helperText}
                                onChange={e => e.currentTarget.value && locationFormState?.city?.error && resetLocationFormError(e.currentTarget.name, locationFormState, setLocationFormState)}
                            />
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <StateSelect errorInfo={locationFormState} updateError={setLocationFormState} />
                                <TextField
                                    label="Zip Code"
                                    name="zipCode"
                                    type="text"
                                    variant="outlined"
                                    size="medium"
                                    required={true}
                                    style={{ width: "58vw" }}
                                    inputProps={{
                                        inputMode: "numeric"
                                    }}
                                    error={locationFormState?.zipCode?.error}
                                    helperText={locationFormState?.zipCode?.helperText}
                                    onChange={e => e.currentTarget.value && locationFormState?.zipCode?.error && resetLocationFormError(e.currentTarget.name, locationFormState, setLocationFormState)}
                                />
                            </div>
                        </Stack>
                    </Stack>
                    <DialogActions sx={{
                        padding: 0,
                        marginTop: 2
                    }}>
                        <Button onClick={() => setNewLocationOpen(false)} variant='contained'>Cancel</Button>
                        <Button onClick={createLocation} variant='contained'>Create</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }

export default NewLocationForm;