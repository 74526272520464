import { CircularProgress, DialogContentText, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, useContext, useState } from "react";
import Stripe from 'stripe';
import AppContext from "../../context/AppContext";
import { Business } from "../../models/Business";
import { Exception } from '../../models/ExceptionTypes';
import ApiClient from '../../services/ApiClient';
import "./BusinessProfile.css";

const updateSubscriptionLevel = async (stripeSubscriptionId: string, priceId: string, businessName: string, token: string) => {
    const res = await ApiClient.PostAsync('/api/stripe/updateSubscriptionType', priceId, token);

    if (!res.ok) {
        throw new Exception(`Error updating subscription ${stripeSubscriptionId} to ${priceId} -- ${await res.json()}`);
    }
}

const UpdateSubscription: FC<{ subscription: any }> = ({ subscription }) => {
    const [appContext] = useContext(AppContext);
    const { stripeSubscriptionId } = (appContext.user as Business);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedPrice, setSelectedPrice] = useState<string>(subscription?.items[0].plan.id);
    const [subscriptionModified, setSubscriptionModified] = useState<{ modified: boolean, status: 'success' | 'error', message: string }>({
        modified: false,
        status: 'success',
        message: ''
    });

    const { data: productsAndPrices, isLoading, isError } = useQuery<{ product: Stripe.Product, price: Stripe.Price }[]>({
        queryKey: ['pricesAndProducts'],
        queryFn: async () => {
            const res = await ApiClient.GetAsync('/api/stripe/productsAndPrices', appContext.auth.token);

            return res.json();
        }
    });

    const updateSubscriptionMutation = useMutation<void, Error, { stripeSubscriptionId: string, priceId: string }>({
        mutationFn: async ({ stripeSubscriptionId, priceId }) => {
            await updateSubscriptionLevel(
                stripeSubscriptionId,
                priceId,
                (appContext.user as Business).businessName,
                appContext.auth.token
            );
        },
        onSuccess: () => {
            setSubscriptionModified({
                modified: true,
                status: 'success',
                message: 'Subscription successfully updated!'
            });
        },
        onError: () => {
            setSubscriptionModified({
                modified: true,
                status: 'error',
                message: 'There was an error updating your subscription level. Please try again later.'
            });
        }
    });

    const handleOnChange = (event: SelectChangeEvent) => {
        setSelectedPrice(event.target.value as string);
    }

    const closeUpdateModal = () => {
        setOpenModal(false);

        setTimeout(() => setSubscriptionModified({ modified: false, status: 'success', message: '' }), 500);
    }

    if (isLoading) return <></>;

    if (isError) return <></>;

    return (
        <>
            <Button
                variant='contained'
                style={{
                    width: '95vw',
                    marginBottom: '15px'
                }}
                onClick={() => setOpenModal(true)}
            >
                UPDATE SUBSCRIPTION LEVEL
            </Button>
            <Dialog
                open={openModal}
                onClose={closeUpdateModal}
                aria-labelledby='update-subscription-title'
                aria-describedby='update-subscription-description'
            >
                {!updateSubscriptionMutation.isPending ? (
                    <>
                        <DialogContent style={{ paddingBottom: '10px' }}>
                            {!subscriptionModified.modified ? (
                                <DialogContentText id='update-subscription-description'>
                                    <FormControl fullWidth>
                                        <InputLabel id='subscription-level-label'>Subscription level</InputLabel>
                                        <Select
                                            labelId='subscription-level-label'
                                            id='subscription-level-select'
                                            value={selectedPrice}
                                            label='Subscription level'
                                            onChange={handleOnChange}
                                        >
                                            {productsAndPrices?.map(c => (
                                                <MenuItem value={c.price.id} key={c.price.id}>{c.product.description}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </DialogContentText>
                            ) : (
                                <DialogContentText id='update-modified-text'>
                                    <Typography variant='subtitle1' textAlign='center'>{subscriptionModified.message}</Typography>
                                </DialogContentText>
                            )}
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center', marginBottom: '10px' }}>
                            {!subscriptionModified.modified ? (
                                <>
                                    <Button
                                        variant='contained'
                                        onClick={() => updateSubscriptionMutation.mutateAsync({ stripeSubscriptionId, priceId: selectedPrice })}
                                    >
                                        UPDATE SUBSCRIPTION
                                    </Button>
                                    <Button
                                        variant='contained'
                                        color='error'
                                        onClick={closeUpdateModal}
                                    >
                                        CANCEL
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    variant='contained'
                                    color={subscriptionModified.status === 'success' ? 'primary' : 'error'}
                                    onClick={closeUpdateModal}
                                >
                                    CLOSE
                                </Button>
                            )}
                        </DialogActions>
                    </>
                ) : (
                    <DialogContent style={{ paddingBottom: '10px', minHeight: '149px', minWidth: '320px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
}

export default UpdateSubscription;