import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { Appearance, loadStripe, PaymentIntent, StripeElementsOptions } from "@stripe/stripe-js";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../context/AppContext";
import ApiClient from "../../services/ApiClient";

const stripePublishableKey = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ?
    "pk_test_51JowRfIFEOpkRbKYbwxe7HjeEMKHOEydM7mfvCkj7C7DjrXS4WgHPYY2cJdoiZSGmm3mGJNieQFHPhvFkgoNCPAG00EYdPNeJO" :
    "pk_live_51JowRfIFEOpkRbKYPvM7EIuq230OEAXRTKFeN8WunxnmtXKlbmOjzstvgiyVMNSE4a1K7jXBZXVUgaM6ptoiHzYw00MBSaJlwP";

const stripePromise = loadStripe(stripePublishableKey);

const PaymentStatus: React.FC = () => {
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        setClientSecret(new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        )!);
    }, []);

    const appearance: Appearance = {
        theme: 'stripe',
    };
    const options: StripeElementsOptions = {
        clientSecret,
        appearance
    };

    return (
        <>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <DisplayPaymentStatus clientSecret={clientSecret} />
                </Elements>
            )}
        </>
    )
}

const DisplayPaymentStatus: React.FC<{ clientSecret: string }> = ({ clientSecret }) => {
    const stripe = useStripe();
    const [appContext] = useContext(AppContext);
    const { id } = useParams<{ id: string }>();
    const [message, setMessage] = useState<string | null | undefined>(null);
    const [emailAddress, setEmailAddress] = useState("");
    const [receiptSent, setReceiptSent] = useState<boolean>(false);
    const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | undefined>(undefined);

    useEffect(() => {
        if (!stripe)
            return;

        if (!clientSecret)
            return;

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent!.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }

            setPaymentIntent(paymentIntent);
        });
    }, [clientSecret, stripe]);

    const handleGetReceiptClick = async () => {
        let res = await ApiClient.PostAsync('/api/tips/SendReceipt', { eventId: id, emailAddress, tipAmount: paymentIntent?.amount }, appContext.auth.token);

        if (res.ok) {
            setReceiptSent(true);
        } else {
            // set error message
        }
    }

    return (
        <>
            {!receiptSent ? (
                <>
                    {message && <div id="payment-message">{message}</div>}

                    {message && message === "Payment succeeded!" ? (
                        <>
                            <p>Want a receipt? Please provide an email address</p>
                            <TextField
                                label="Email Address"
                                type="email"
                                name="email"
                                variant="outlined"
                                size="small"
                                required={true}
                                onChange={e => setEmailAddress(e.target.value)}
                                style={{
                                    width: "95vw",
                                    margin: "auto"
                                }}
                            />
                            <Button
                                variant="contained"
                                style={{ cursor: "pointer" }}
                                onClick={handleGetReceiptClick}
                                sx={{
                                    marginTop: "15px",
                                    width: "95vw"
                                }}
                            >
                                GET RECEIPT
                            </Button>
                        </>
                    ) : message && (<p>We apologize for the inconvenience. Please re-scan the QR code and try again.</p>)}
                </>
            ) : <p>A receipt of this transaction has been emailed to {emailAddress}</p>}
        </>
    )
}

export default PaymentStatus;
export { DisplayPaymentStatus };


